import uuid from "react-uuid";
import Rianxeria from "../../img/latam-agents/riannxeira.jpg";
// import ZonaPago from "../../img/latam-agents/zonapago.jpg";
import Farmacias911 from "../../img/latam-agents/farmacias-911.jpg";
import WesternUnion from "../../img/latam-agents/wu.jpg";
import Tia from "../../img/latam-agents/tia.jpg";
import Medicity from "../../img/latam-agents/medicity.jpg";
import MiComisariato from "../../img/latam-agents/mi-comisariato.jpg";
// import InhouseCNT from "../../img/latam-agents/cnt.jpg";
// import JardínAzuayo from "../../img/latam-agents/azuayo.jpg";
// import COAC29deOctubre from "../../img/latam-agents/coac29.jpg";
// import COOPMEGO from "../../img/latam-agents/coopmego.jpg";
import FarmaciasEconómicas from "../../img/latam-agents/economicas.jpg";
// import Facilito from "../../img/latam-agents/facilito.jpg";
import ERHipermarket from "../../img/latam-agents/hiper-market.jpg";
// import MutualistaPichincha from "../../img/latam-agents/matualista.jpg";
// import COAVMUCHUCRUNA from "../../img/latam-agents/mushuc-runa.jpg";
import DIFCruzAzul from "../../img/latam-agents/cruz-azul.jpg";
import BodegaAurrera from "../../img/latam-agents/bodega-aurrera.jpg";
import SevenEleven from "../../img/latam-agents/7-eleven.jpg";
import SamsClub from "../../img/latam-agents/sams-club.jpg";
import Walmart from "../../img/latam-agents/walmart.jpg";
import FarmaciasBarata from "../../img/latam-agents/farmacias-barata.jpg";
import WalmartExpress from "../../img/latam-agents/walmart-express.jpg";
import FarmaciasRoma from "../../img/latam-agents/farmacias-roma.jpg";
import SuperdelNorte from "../../img/latam-agents/super-norte.jpg";
// import SuperCity from "../../img/latam-agents/super-city.jpg";
import Soriana from "../../img/latam-agents/soriana.jpg";
import CCK from "../../img/latam-agents/circle-k.jpg";
import Extra from "../../img/latam-agents/extra.jpg";
import CityClub from "../../img/latam-agents/city-club.jpg";
import Calimax from "../../img/latam-agents/calimax.jpg";
import Santander from "../../img/latam-agents/santander.png";
import BBVA from "../../img/latam-agents/bbva.jpg";
import CajaCerano from "../../img/latam-agents/caja-cerano.jpg";
import Banorte from "../../img/latam-agents/banorte.jpg";
// import HSBC from "../../img/latam-agents/hsbc.jpg";
import Kiosko from "../../img/latam-agents/kiosko.jpg";
import CajaMorelia from "../../img/latam-agents/caja-morelia.jpg";
import CajaOblatos from "../../img/latam-agents/caja-oblatos.jpg";
import SBMRural from "../../img/latam-agents/smb-rural.jpg";
import Telecom from "../../img/latam-agents/telecom.jpg";
import Via from "../../img/latam-agents/via.jpg";
// import Yza from "../../img/latam-agents/yza.jpg";
// import Afirme from "../../img/latam-agents/afirme.jpg";
import Kasnet from "../../img/latam-agents/kasnet.jpg";
import BCP from "../../img/latam-agents/bcp.jpg";
import Izipay from "../../img/latam-agents/izipay.png";
import Andes from "../../img/latam-agents/andes.jpg";
import CajadelSanta from "../../img/latam-agents/caja-santa.jpg";
import CajaPaita from "../../img/latam-agents/caja-paita.jpg";
import Qapaq from "../../img/latam-agents/qapaq.jpg";
import Raiz from "../../img/latam-agents/raiz.jpg";
import CajaTrujillo from "../../img/latam-agents/caja-trujillo.jpg";
import AgenteCash from "../../img/latam-agents/agente-cash.jpg";
import Edypyme from "../../img/latam-agents/edypyme.jpg";
import CajaSullana from "../../img/latam-agents/caja-sullana.jpg";
import BancoNacional from "../../img/latam-agents/banco-nacional.jpg";
import Payser from "../../img/latam-agents/payser.jpg";
import Hey from "../../img/latam-agents/hey.jpg";
import Bam from "../../img/latam-agents/bam.jpg";
import Continental from "../../img/latam-agents/continental.jpg";
import Machetazo from "../../img/latam-agents/machetazo.jpg";
import Xtras from "../../img/latam-agents/xtras.jpg";
// import Juan from "../../img/latam-agents/juan.jpg";
import Servientrega from "../../img/latam-agents/servientrega.png";
import Pharmacys from "../../img/latam-agents/pharmacys.png";
import Pronet from "../../img/latam-agents/pronet.png";
import JumboMarket from "../../img/latam-agents/jumbo-market.jpg";
import FarmaExpress from "../../img/latam-agents/farma-express.jpg";
import AmPm from "../../img/latam-agents/am-pm.jpg";
import Yape from "../../img/latam-agents/yape.png";
import Interbank from "../../img/latam-agents/interbank.jpg";
import BancoPichincha from "../../img/latam-agents/pichincha.jpg";
import Bancom from "../../img/latam-agents/bancom.png";
import Megapunto from "../../img/latam-agents/megapunto.png";

export const payCashChannels = {
  ECU: [
    {
      data: [
        { logo: Rianxeria, name: "Rianxeria", key: uuid() },
        // { logo: ZonaPago, name: "Zona Pago", key: uuid() },
        { logo: Farmacias911, name: "Farmacias 911", key: uuid() },
        // { logo: WesternUnion, name: "Western Union", key: uuid() },
        { logo: Tia, name: "Tia", key: uuid() },
        { logo: Medicity, name: "Medicity", key: uuid() },
        { logo: MiComisariato, name: "Mi Comisariato", key: uuid() },
        // { logo: InhouseCNT, name: "Inhouse CNT", key: uuid() },
        // { logo: JardínAzuayo, name: "Jardín Azuayo", key: uuid() },
        // { logo: COAC29deOctubre, name: "COAC 29 de Octubre", key: uuid() },
        // { logo: COOPMEGO, name: "COOPMEGO", key: uuid() },
        {
          logo: FarmaciasEconómicas,
          name: "Farmacias Económicas",
          key: uuid(),
        },
        // { logo: Facilito, name: "Facilito", key: uuid() },
        { logo: ERHipermarket, name: "ER Hipermarket", key: uuid() },
        // {
        //   logo: MutualistaPichincha,
        //   name: "Mutualista Pichincha",
        //   key: uuid(),
        // },
        // { logo: COAVMUCHUCRUNA, name: "COAV MUCHUC RUNA", key: uuid() },
        { logo: DIFCruzAzul, name: "DIF Cruz Azul", key: uuid() },
        { logo: Servientrega, name: "Servientrega", key: uuid() },
        { logo: Pharmacys, name: "Servientrega", key: uuid() },
      ],
      key: uuid(),
    },
  ],
  MEX: [
    {
      data: [
        { logo: BodegaAurrera, name: "Bodega Aurrera", key: uuid() },
        { logo: SevenEleven, name: "7-Eleven", key: uuid() },
        { logo: SamsClub, name: "Sam's Club", key: uuid() },
        { logo: Walmart, name: "Walmart", key: uuid() },
        {
          logo: FarmaciasBarata,
          name: "Farmacias la más Barata",
          key: uuid(),
        },
        {
          logo: WalmartExpress,
          name: "Walmart Express",
          key: uuid(),
        },
        { logo: FarmaciasRoma, name: "Farmacias Roma", key: uuid() },
        { logo: SuperdelNorte, name: "Super del Norte", key: uuid() },
        // { logo: SuperCity, name: "Super City", key: uuid() },
        { logo: Soriana, name: "Soriana", key: uuid() },
        { logo: CCK, name: "CCK", key: uuid() },
        { logo: Extra, name: "Extra", key: uuid() },
        { logo: CityClub, name: "City Club", key: uuid() },
        { logo: Calimax, name: "Calimax", key: uuid() },
        { logo: Santander, name: "Santander", key: uuid() },
        { logo: BBVA, name: "BBVA", key: uuid() },
        { logo: CajaCerano, name: "Caja Cerano", key: uuid() },
        { logo: Banorte, name: "Banorte", key: uuid() },
        // { logo: HSBC, name: "HSBC", key: uuid() },
        { logo: Kiosko, name: "Kiosko", key: uuid() },
        { logo: CajaMorelia, name: "Caja Morelia", key: uuid() },
        { logo: CajaOblatos, name: "Caja Oblatos", key: uuid() },
        { logo: SBMRural, name: "SBM Rural", key: uuid() },
        { logo: Telecom, name: "Telecom", key: uuid() },
        { logo: Via, name: "Via de Servicios", key: uuid() },
        // { logo: Yza, name: "Yza + Farmacias", key: uuid() },
        // { logo: Afirme, name: "Banco AFIRME", key: uuid() },
      ],
      key: uuid(),
    },
  ],
  PER: [
    {
      data: [
        { logo: BBVA, name: "BBVA", key: uuid() },
        { logo: BCP, name: "Banco BCP", key: uuid() },
        { logo: Interbank, name: "Interbank", key: uuid() },
        { logo: BancoPichincha, name: "Banco Pichincha", key: uuid() },
        { logo: Bancom, name: "Banco del Comercio", key: uuid() },
        { logo: Kasnet, name: "Kasnet", key: uuid() },
        { logo: WesternUnion, name: "Western Union", key: uuid() },
        { logo: Izipay, name: "Izipay", key: uuid() },
        { logo: Andes, name: "Caja Los Andes", key: uuid() },
        { logo: CajadelSanta, name: "Caja del Santa", key: uuid() },
        { logo: CajaPaita, name: "Caja Paita", key: uuid() },
        { logo: Qapaq, name: "Financiera Qapaq", key: uuid() },
        // { logo: Raiz, name: "Crac - Raiz", key: uuid() },
        { logo: CajaTrujillo, name: "Caja Trujillo", key: uuid() },
        { logo: AgenteCash, name: "Agentes Cash", key: uuid() },
        { logo: Edypyme, name: "Edypyme Alternativa", key: uuid() },
        // { logo: CajaSullana, name: "Caja Sullana", key: uuid() },
        { logo: Yape, name: "Yape", key: uuid() },
        { logo: Megapunto, name: "Megapunto", key: uuid() },
      ],
      key: uuid(),
    },
  ],
  CRI: [
    {
      data: [
        { logo: BancoNacional, name: "Banco Nacional", key: uuid() },
        { logo: Payser, name: "Payser", key: uuid() },
        { logo: Hey, name: "Red de pagos Hey", key: uuid() },
      ],
      key: uuid(),
    },
  ],
  GTM: [
    {
      data: [
        { logo: Bam, name: "Banco Agromercantil", key: uuid() },
        // {
        //   logo: Continental,
        //   name: "Banco G&T Continental",
        //   key: uuid(),
        // },
        { logo: Pronet, name: "Pronet", key: uuid() },
      ],
      key: uuid(),
    },
  ],
  PAN: [
    {
      data: [
        { logo: WesternUnion, name: "Western Union", key: uuid() },
        { logo: Machetazo, name: "El Machetazo", key: uuid() },
        { logo: Xtras, name: "Super Xtras", key: uuid() },
        // { logo: Juan, name: "Juan XXIII", key: uuid() },
        { logo: JumboMarket, name: "Jumbo Market", key: uuid() },
        { logo: FarmaExpress, name: "Farma Express", key: uuid() },
        { logo: AmPm, name: "am:pm", key: uuid() },
      ],
      key: uuid(),
    },
  ],
};
