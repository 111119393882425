import React from "react";
import CheckoutTitle from "./CheckoutTitle";
import PaymentCode from "../../comp/PaymentCode";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import "./CheckoutPayCash.scss";
import { payCashChannels } from "../../../utils/config/paycash";

function CheckoutPayCash(props) {
  const { t } = useTranslation();

  return (
    <div className="checkoutPayCash">
      <CheckoutTitle />
      <div className="checkoutPayCash__mobileCopy">
        <hr />
        <PaymentCode value={props.payData.reference} copy />
        <hr />
      </div>
      <div className="checkoutPayCash__inst">
        <p className="checkoutPayCash__instTitle">{t("clap_cash_title")}</p>
        <ol className="checkoutPayCash__instCont">
          <li>
            <span>{t(props.tradeInfo?.app_info?.region === "GTM" ? "pay_cashgtm_ins1" : "pay_cash_ins1")}</span>
            <span className="checkoutPayCash__instCont__payCode">{props.payData?.reference}</span>
          </li>
          <li>{t("clap_cash_ins3")}</li>
          <li>
            {t("pay_points1")}
            <a
              href="https://www.paycashglobal.com/buscador.php"
              target="_blank"
              rel="noreferrer"
              className="checkoutPayCash__link"
            >
              {t("pay_points2")}
            </a>
            {t("pay_points_search")}
          </li>
        </ol>
      </div>
      <div className="checkoutPayCash__agents">
        {payCashChannels[props.tradeInfo?.app_info?.region]?.[0]?.data?.map((item) => (
          <img src={item?.logo} alt="" />
        ))}
      </div>
      {props.tradeInfo?.app_info?.region === "ECU" && (
        <p className="checkoutPayCash__notice">
          {t("pay_points1")}
          <a
            href="https://checkout.pagsmile.com/public/pagsmile-files/puntos-Ecuador.pdf"
            target="_blank"
            rel="noreferrer"
          >
            {t("pay_points2")}
          </a>
          {t("pay_points3")}
        </p>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPayCash);
