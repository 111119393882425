import React, { useEffect, forwardRef, useImperativeHandle, useState } from "react";
import TextField from "@material-ui/core/TextField";
import { useTranslation } from "react-i18next";
import "./CrediMax.scss";

const CrediMax = ({ payRequest, setIsLoading, form, setForm, isReadyToPay, handleError, style = {} }, ref) => {
  const [sessionId, setSessionId] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    sessionId && payRequest(sessionId);
  }, [sessionId]);

  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
    window.PaymentSession?.configure?.({
      session: sessionStorage.getItem("CrediMax__session"),
      fields: {
        // ATTACH HOSTED FIELDS TO YOUR PAYMENT PAGE FOR A CREDIT CARD
        card: {
          number: "#card-number",
          securityCode: "#security-code",
          expiryMonth: "#expiry-month",
          expiryYear: "#expiry-year",
          nameOnCard: "#cardholder-name",
        },
      },
      //SPECIFY YOUR MITIGATION OPTION HERE
      frameEmbeddingMitigation: ["javascript"],
      callbacks: {
        initialized: function (response) {
          // HANDLE INITIALIZATION RESPONSE
          console.log("// HANDLE INITIALIZATION RESPONSE");
          setIsLoading(false);
        },
        formSessionUpdate: function (response) {
          // HANDLE RESPONSE FOR UPDATE SESSION
          setIsLoading(false);
          if (response.status) {
            if ("ok" == response.status) {
              console.log("Session updated with data: " + JSON.stringify(response));
              setSessionId({
                sessionId: response?.session?.id,
                name: response?.sourceOfFunds?.provided?.card?.nameOnCard,
                cardNumber: response?.sourceOfFunds?.provided?.card?.number,
              });

              //check if the security code was provided by the user
              if (response.sourceOfFunds.provided.card.securityCode) {
              }

              //check if the user entered a Mastercard credit card
              if (response.sourceOfFunds.provided.card.scheme == "MASTERCARD") {
              }
            } else if ("fields_in_error" == response.status) {
              handleError("Session update failed with field errors.");
              if (response.errors.cardNumber) {
                handleError("Card number invalid or missing.");
              }
              if (response.errors.expiryYear) {
                handleError("Expiry year invalid or missing.");
              }
              if (response.errors.expiryMonth) {
                handleError("Expiry month invalid or missing.");
              }
              if (response.errors.securityCode) {
                handleError("Security code invalid.");
              }
            } else if ("request_timeout" == response.status) {
              handleError("Session update failed with request timeout: " + response.errors.message);
            } else if ("system_error" == response.status) {
              handleError("Session update failed with system error: " + response.errors.message);
            }
          } else {
            handleError("Session update failed: " + response);
          }
        },
      },
      interaction: {
        displayControl: {
          formatCard: "EMBOSSED",
          invalidFieldCharacters: "REJECT",
        },
      },
    });

    window.PaymentSession?.onBlur?.(
      ["card.number", "card.nameOnCard", "card.securityCode", "card.expiryYear", "card.expiryMonth"],
      function (selector, role) {
        window.PaymentSession?.validate?.("card", function (allresult) {
          if (allresult.card[role].isValid) {
            console.log("The field is valid", role);
            //document.querySelector(selector).style.borderColor = "green";
            if (role === "number") {
              setForm((prev) => {
                return {
                  ...prev,
                  cardNumber: { ...prev.cardNumber, value: "1111111111111111", valid: true, checked: true },
                };
              });
            } else if (role === "nameOnCard") {
              setForm((prev) => {
                return { ...prev, name: { ...prev.name, value: "John", valid: true, checked: true } };
              });
            } else if (role === "securityCode") {
              setForm((prev) => {
                return { ...prev, cvc: { ...prev.cvc, value: "123", valid: true, checked: true } };
              });
            } else if (role === "expiryYear") {
              setForm((prev) => {
                return { ...prev, expYear: { ...prev.expYear, value: "55", valid: true, checked: true } };
              });
            } else if (role === "expiryMonth") {
              setForm((prev) => {
                return { ...prev, expMonth: { ...prev.expMonth, value: "11", valid: true, checked: true } };
              });
            }
          } else {
            console.log("The field is invalid", role);

            //document.querySelector(selector).style.borderColor = "red";
            if (role === "number") {
              setForm((prev) => {
                return { ...prev, cardNumber: { ...prev.cardNumber, value: "", valid: false, checked: true } };
              });
            } else if (role === "nameOnCard") {
              setForm((prev) => {
                return { ...prev, name: { ...prev.name, value: "", valid: false, checked: true } };
              });
            } else if (role === "securityCode") {
              setForm((prev) => {
                return { ...prev, cvc: { ...prev.cvc, value: "", valid: false, checked: true } };
              });
            } else if (role === "expiryYear") {
              setForm((prev) => {
                return { ...prev, expYear: { ...prev.expYear, value: "", valid: false, checked: true } };
              });
            } else if (role === "expiryMonth") {
              setForm((prev) => {
                return { ...prev, expMonth: { ...prev.expMonth, value: "", valid: false, checked: true } };
              });
            }
          }
        });
      }
    );
  }, []);

  useImperativeHandle(ref, () => ({
    pay() {
      if (isReadyToPay()) {
        window.PaymentSession?.updateSessionFromForm?.("card");
        setIsLoading(true);
      }
    },
  }));

  return (
    <div className="crediMax" style={style}>
      <div className="crediMax__row2">
        <TextField
          id="cardholder-name"
          className="crediMax__texField"
          size="small"
          variant="outlined"
          label={t("cardholder_name")}
          helperText={!form.name.valid && t("invalid_name")}
          error={!form.name.valid}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ readOnly: true, className: "input-field" }}
        />
        <TextField
          id="card-number"
          className="crediMax__texField"
          size="small"
          variant="outlined"
          label={t("card_no")}
          helperText={!form.cardNumber.valid && t("invalid_card_no")}
          error={!form.cardNumber.valid}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ readOnly: true, className: "input-field", style: { minWidth: 200 } }}
        />
      </div>
      <div className="crediMax__row2">
        <TextField
          id="expiry-month"
          className="crediMax__texField"
          size="small"
          variant="outlined"
          label={t("expiry_month")}
          helperText={!form.expMonth.valid && t("invalid_month2")}
          error={!form.expMonth.valid}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ readOnly: true, className: "input-field" }}
        />
        <TextField
          id="expiry-year"
          className="crediMax__texField"
          size="small"
          variant="outlined"
          label={t("expiry_year")}
          helperText={!form.expYear.valid && t("invalid_year2")}
          error={!form.expYear.valid}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ readOnly: true, className: "input-field" }}
        />
      </div>
      <div className="crediMax__row2">
        <TextField
          id="security-code"
          className="crediMax__texField"
          size="small"
          variant="outlined"
          label="CVC"
          helperText={!form.cvc.valid && t("invalid_cvv")}
          error={!form.cvc.valid}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ readOnly: true, className: "input-field" }}
        />
      </div>
    </div>
  );
};

export default forwardRef(CrediMax);
