import req from "./axios";
import { secUrl, getGoogleRecaptchaId } from "../envs/env";
import store from "../redux/store";

export const tokenize = (prepayId, issuer, form, success = () => {}, fail = () => {}) => {
  const { tradeInfo } = store.getState();
  let postUrl = `/api/trade/gen-access-key?prepay_id=${prepayId}`;
  if (tradeInfo?.app_info?.global) postUrl += `&region=${tradeInfo?.app_info?.region}`;
  req
    .post(postUrl)
    .then((res) => {
      if (typeof res.data === "string") {
        req
          .post(`${secUrl}/api/tokenize?prepay_id=${prepayId}&access_key=${res.data}`, {
            card: {
              card_no: form.cardNumber.value.replace(/[^\d]/g, ""),
              issuer: issuer,
              cvv: form.cvc.value,
              valid_thru_year: form.expYear.value,
              valid_thru_month: form.expMonth.value,
              holder: {
                name: form.name.value,
              },
            },
          })
          .then((res) => {
            if (typeof res.data === "string") {
              success(res.data);
            } else {
              fail("/tokenize Error");
            }
          })
          .catch((err) => {
            fail();
          });
      } else {
        fail("/gen-access-key Error");
      }
    })
    .catch((err) => {
      fail(err);
    });
};

export const paypalTokenize = (method, channel, prepayId, success = () => {}, fail = () => {}) => {
  const { tradeInfo } = store.getState();
  let postUrl = `/api/trade/gen-access-key?prepay_id=${prepayId}&method=${method}&channel=${channel}`;
  if (tradeInfo?.app_info?.global) postUrl += `&region=${tradeInfo?.app_info?.region}`;
  req
    .post(postUrl)
    .then((res) => {
      if (typeof res.data === "string")
        return req.post(`${secUrl}/api/get-paypal-client-token?prepay_id=${prepayId}&access_key=${res.data}`);
      else throw new Error("/gen-access-key error!");
    })
    .then((res) => {
      success(res.data);
    })
    .catch((err) => {
      fail(err);
    });
};

export const authorize = (reqData, prepayId, success = () => {}, fail = () => {}) => {
  req
    .post(`/api/trade/user-authorization?prepay_id=${prepayId}`, reqData)
    .then((res) => {
      success(res);
    })
    .catch((err) => {
      fail(err);
    });
};

export const queryKey = (method, prepayId) => {
  const { tradeInfo } = store.getState();
  return new Promise((resolve, reject) => {
    let postUrl = `/api/trade/query-key?prepay_id=${prepayId}&method=${method}`;
    if (tradeInfo?.app_info?.global) postUrl += `&region=${tradeInfo?.app_info?.region}`;
    req
      .post(postUrl)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const googleTokenize = () => {
  return new Promise((resolve, reject) => {
    const grecaptcha = window.grecaptcha;
    try {
      const grecaptchaId = getGoogleRecaptchaId();
      grecaptcha.enterprise.ready(function () {
        try {
          grecaptcha.enterprise
            .execute(grecaptchaId, { action: "purchase" })
            .then(function (token) {
              resolve(token);
            })
            .catch((err) => {
              // console.log("1>>>>>>>>>>>>>>>>>>>>>", err);
              resolve("");
            });
        } catch (err) {
          // console.log("2>>>>>>>>>>>>>>>>>>>>>", err);
          resolve("");
        }
      });
    } catch (err) {
      // console.log("3>>>>>>>>>>>>>>>>>>>>>", err);
      resolve("");
    }
  });
};

export const auth3DS = (prepayId, token) => {
  return new Promise((resolve, reject) => {
    req
      .post(`/api/trade/threeds?prepay_id=${prepayId}`, {
        card_token: token,
        browser: {
          accept_header: "text/html",
          color_depth: [1, 4, 8, 15, 16, 24, 32, 48].includes(window?.screen?.colorDepth)
            ? String(window?.screen?.colorDepth)
            : String(
                [1, 4, 8, 15, 16, 24, 32, 48].reduce((result, num) => {
                  return window?.screen?.colorDepth - num > 0 &&
                    window?.screen?.colorDepth - num < window?.screen?.colorDepth - result
                    ? num
                    : result;
                }, 1)
              ),
          java_enabled: String(!!navigator?.javaEnabled?.()),
          language: navigator?.language,
          screen_height: String(window?.screen?.height),
          screen_width: String(window?.screen?.width),
          timezone: String(new Date().getTimezoneOffset()),
          challenge_window: "5",
          user_agent: navigator?.userAgent,
          javascript_enabled: "true",
        },
      })
      .then((res) => {
        res.data?.data?.redirect?.preconditions?.forEach?.((preconditions, index) => {
          // console.log("preconditions", preconditions?.url);
          if (preconditions?.url) {
            create3dsIframe(preconditions?.parameters, {
              url: preconditions?.url,
              hidden: true,
              name: `pagsmile-3ds-post1.${index}`,
            });
          }
        });

        if (res.data?.data?.redirect?.url) {
          resolve();
          create3dsIframe(res.data?.data?.redirect?.parameters, {
            url: res.data?.data?.redirect?.url,
            hidden: false,
            name: "pagsmile-3ds-post2",
          });
        } else {
          reject("-(submit)-");
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
};

function create3dsIframe(fields, config) {
  const containerId = config?.name + "-container";
  const iFrameId = config?.name + "-iframe";
  const formId = config?.name + "-form";

  if (!document.getElementById(containerId)) {
    // container
    let container = document.createElement("div");
    container.id = containerId;
    if (!config?.hidden) {
      let style = `<style nonce="">
      #${containerId}.pagsmile-checkout {
          display: block;
          position: fixed;
          top: 0;
          left: 0;
          background-color: rgba(0, 0, 0, 0.8);
          width: 100%;
          height: 100%;
          width: 100vw;
          height: 100vh;
          max-width: 100%;
          max-height: 100%;
          min-width: 100%;
          min-height: 100%;
          z-index: 2147483647;
          animation-duration: 0.3s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards !important;
      }
      #pagsmile-checkout-loading {
          position: absolute; 
          top: 50%; 
          left: 50%; 
          transform: translate(-50%, -50%);
          z-index: 10;
      }
      #${containerId}.pagsmile-checkout .pagsmile-checkout-iframe {
          display: block;
          position: absolute;
          background-color: #fff;
          top: 50%;
          left: 50%;
          width: 50%;
          height: 60%;
          transform: translate(-50%, -50%);
      }
      @media only screen and (max-width: 800px) {
        #${containerId}.pagsmile-checkout .pagsmile-checkout-iframe { 
          width: 80%;
        }
      }
      .pagsmile-checkout-close {
          position: absolute;
          right: 16px;
          top: 16px;
          width: 16px;
          height: 16px;
          opacity: 0.6;
          text-decoration: none;
          cursor: pointer;
       }
      .pagsmile-checkout-close:before {
          position: absolute;
          left: 8px;
          content: ' ';
          height: 16px;
          width: 2px;
          transform: rotate(45deg);
          background-color: #fff;
       }
      .pagsmile-checkout-close:after {
          position: absolute;
          left: 8px;
          content: ' ';
          height: 16px;
          width: 2px;
          transform: rotate(-45deg);
          background-color: #fff;
       }
      #${containerId}.pagsmile-checkout .pagsmile-checkout-iframe-full {
          border: 0;
          height: 100%;
          width: 100vw;
      }
  
      @keyframes show-container {
          from {
              opacity: 0;
          }
  
          to {
              opacity: 1;
          }
      }
  
      @keyframes hide-container {
          from {
              opacity: 1;
          }
  
          50% {
              opacity: 1;
          }
  
          to {
              opacity: 0;
          }
      }
      </style>`;
      const close = document.createElement("a");
      close.className = "pagsmile-checkout-close";
      close.addEventListener("click", () => {
        container.remove();
      });
      container.appendChild(close);
      container.className = "pagsmile-checkout";
      container.style = "animation-name: show-container;";
      container.insertAdjacentHTML("afterbegin", style);

      // loding
      const loading = document.createElement("img");
      loading.src = "https://checkout-test.pagsmile.com/public/redirect/loading.gif";
      loading.id = "pagsmile-checkout-loading";
      container.appendChild(loading);
      setTimeout(() => {
        loading.style.display = "none";
      }, 3000);
    } else {
      container.style.display = "none";
    }
    // iframe
    const iFrame = document.createElement("iframe");
    iFrame.id = iFrameId;
    iFrame.name = iFrameId;
    iFrame.src = "about:blank";
    iFrame.className = "pagsmile-checkout-iframe";
    container.appendChild(iFrame);

    // form
    const form = document.createElement("form");
    form.method = "POST";
    form.action = config.url;
    form.id = formId;
    form.setAttribute("target", iFrameId);
    for (let fieldName of fields) {
      let input = document.createElement("input");
      input.name = fieldName.name;
      input.value = fieldName.value;
      input.id = fieldName.name;
      input.setAttribute("type", "hidden");
      form.appendChild(input);
    }
    container.appendChild(form);
    document.body.appendChild(container);
    form.submit();
  } else {
    if (!config?.hidden) document.getElementById(containerId).style.display = "block";
    document.getElementById(formId)?.submit?.();
  }
}
