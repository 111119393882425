import Dialog from "@material-ui/core/Dialog";

function Html3DsSecure({ htmlContent }) {
  return (
    <Dialog open>
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} style={{ padding: "20px" }} />
    </Dialog>
  );
}

export default Html3DsSecure;
