import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NubankIcon from "../../../img/OpenFinance/nu-bank.svg";
import ItauIcon from "../../../img/OpenFinance/itau.svg";
import C6BankIcon from "../../../img/OpenFinance/C6.svg";
import BancoInterIcon from "../../../img/OpenFinance/inter.svg";
import PagBankIcon from "../../../img/OpenFinance/pagbank.svg";
import ActiveIcon from "../../../img/OpenFinance/active.svg";
import InactiveIcon from "../../../img/OpenFinance/inactive.svg";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Correct from "../../../img/correct.jpg";
import { achImegeConfig } from "../../../utils/config/pse-ach";
import { setChannel } from "../../../redux/actions";
import Bancolombia from "../../../img/bancolombia.png";
import Davivienda from "../../../img/davivienda.png";
import Bogota from "../../../img/bogota.png";
import Occidente from "../../../img/occidente.png";

const CssTextField = makeStyles((theme) => ({
  root: {
    backgroundColor: "#40465c",
    color: "#fff",
    //margin: "0 10px",
    borderRadius: "4px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "& .MuiFilledInput-input, .MuiInputLabel-filled": {
      color: "#fff",
    },

    "& .MuiFilledInput-underline:before": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-underline:after": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-underline:hover": {
      borderBottom: "none",
    },
    "& .MuiFilledInput-root": {
      borderRadius: "8px",
    },
  },
  fastOptions: {
    "& .MuiOutlinedInput-input": {
      display: "none",
    },
  },
}));

const fastOptionsConfig = {
  OpenFinance: {
    Iniciador: {
      "C6 Bank": {
        bank_name: "C6 Bank",
        logo: C6BankIcon,
      },
      Nubank: {
        bank_name: "Nubank",
        logo: NubankIcon,
      },
      "Banco Inter PF": {
        bank_name: "Banco Inter PF",
        logo: BancoInterIcon,
      },
      // Itaú: {
      //   bank_name: "Itaú",
      //   logo: ItauIcon,
      // },
      PagBank: {
        bank_name: "PagBank",
        logo: PagBankIcon,
      },
    },
    Mercadopago: {
      "C6 Bank": {
        bank_name: "C6 Bank",
        logo: C6BankIcon,
      },
      Nubank: {
        bank_name: "Nubank",
        logo: NubankIcon,
      },
      "Banco Inter": {
        bank_name: "Banco Inter",
        logo: BancoInterIcon,
      },
      Itaú: {
        bank_name: "Itaú",
        logo: ItauIcon,
      },
      "PagBank PagSeguro": {
        bank_name: "PagBank PagSeguro",
        logo: PagBankIcon,
      },
    },
  },
  PSE: {
    ACH: {
      BANCOLOMBIA: {
        bank_name: "BANCOLOMBIA",
        logo: Bancolombia,
      },
      "BANCO BBVA COLOMBIA S.A.": {
        bank_name: "BANCO BBVA COLOMBIA S.A.",
        logo: achImegeConfig["BANCO BBVA COLOMBIA S.A."],
      },
      "BANCO DAVIVIENDA": {
        bank_name: "BANCO DAVIVIENDA",
        logo: Davivienda,
      },
      "BANCO DE BOGOTA": {
        bank_name: "BANCO DE BOGOTA",
        logo: Bogota,
      },
      "BANCO DE OCCIDENTE": {
        bank_name: "BANCO DE OCCIDENTE",
        logo: Occidente,
      },
    },
  },
};

function BankListPopUp({ form, setForm }) {
  const { t } = useTranslation();
  const { channelList: methodChannels, method, payChannel } = useSelector((state) => state);
  const dispatch = useDispatch();
  // console.log("paycahannel: ", payChannel);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [channelList, setChannelList] = useState([]);
  const classes = CssTextField();
  const fastOptionsLength = 5;
  const [fastOptions, setFastOptions] = useState([]);
  const isOtherOptions = methodChannels[method]?.length > fastOptionsLength;

  const isOtherOptionSelected =
    !!form.bank_list.value?.key && !fastOptions?.some((item) => item?.key === form.bank_list.value?.key);

  const formatFastOptions = (channel, fastOptionsLength) => {
    const config = fastOptionsConfig[channel.method]?.[channel.channel];
    if (config) {
      const formatted =
        methodChannels[channel.method]
          ?.filter((item) => {
            // console.log(
            //   `${item.data?.[0]?.bank_name} in ${JSON.stringify(fastOptionsConfig[channel.channel])} = ${
            //     item.data?.[0]?.bank_name in (fastOptionsConfig[channel.channel] || {})
            //   }`
            // );
            return item.data?.[0]?.bank_name in (config || {});
          })
          ?.map((item) => ({
            ...item,
            data: [{ ...item.data[0], logo: config?.[item.data[0]?.bank_name]?.logo }],
          })) || [];
      return formatted;
    } else if (methodChannels[channel.method]?.length > fastOptionsLength) {
      return methodChannels[channel.method]?.slice(0, fastOptionsLength);
    } else {
      return methodChannels[channel.method];
    }
  };

  useEffect(() => {
    const fastOptions = formatFastOptions({ method: method, channel: payChannel }, fastOptionsLength);
    // console.log("fastOptions: ", fastOptions);
    setFastOptions(fastOptions);
    setForm((prev) => {
      return {
        ...prev,
        bank_list: { ...prev.bank_list, value: fastOptions[0], valid: true, checked: true },
      };
    });
    dispatch(setChannel(fastOptions[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methodChannels?.[method], payChannel]);

  // filter channelList by search
  useEffect(() => {
    if (search) {
      const filteredChannelList = methodChannels?.[method]?.filter((item) => {
        return item?.data?.[0]?.bank_name?.toLowerCase().includes(search.toLowerCase());
      });
      setChannelList(filteredChannelList);
    } else if (method === "OpenFinance" && payChannel === "Iniciador") {
      // filter Itaú which is not available now
      setChannelList(methodChannels?.[method].filter((chnl) => chnl.data?.[0]?.bank_name !== "Itaú"));
    } else {
      // console.log(methodChannels?.[method]);
      setChannelList(methodChannels?.[method]);
    }
  }, [search, methodChannels?.[method]]);

  // useEffect(() => {
  //   setForm((prev) => {
  //     return {
  //       ...prev,
  //       bank_list: { ...prev.bank_list, value: "", checked: false, valid: true },
  //     };
  //   });
  // }, [method]);

  return (
    <div>
      <TextField
        className={`${classes.fastOptions} homeMobile__filling__content__input`}
        variant="outlined"
        label={t("bank_list")}
        fullWidth
        size="medium"
        error={!form.bank_list.valid}
        helperText={!form.bank_list.valid && t("bank_select")}
        InputProps={{
          startAdornment: (
            <div style={{ width: "100%", padding: "8px 0" }}>
              <div style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                {fastOptions?.map((channel) => (
                  <img
                    key={channel.key}
                    style={{
                      height: "36px",
                      width: "36px",
                      cursor: "pointer",
                      borderRadius: "8px",
                      border:
                        channel?.key === form.bank_list.value?.key
                          ? "2px solid var(--pagsmile--color)"
                          : "2px solid #fff",
                    }}
                    src={channel.data?.[0].logo}
                    alt=""
                    onClick={() => {
                      setForm((prev) => {
                        return {
                          ...prev,
                          bank_list: { ...prev.bank_list, value: channel, valid: true, checked: true },
                        };
                      });
                      dispatch(setChannel(channel));
                    }}
                  />
                ))}
              </div>
            </div>
          ),
          endAdornment: (
            <div style={{ display: "flex", alignItems: "center" }}>
              {
                <img
                  style={{
                    height: "36px",
                    width: "36px",
                    cursor: "pointer",
                    marginLeft: "4px",
                    objectFit: "contain",
                    borderRadius: "8px",
                    border: isOtherOptionSelected ? "2px solid var(--pagsmile--color)" : "2px solid #fff",
                  }}
                  src={isOtherOptionSelected ? form.bank_list.value?.data?.[0]?.logo : InactiveIcon}
                  alt=""
                  onClick={() => setOpen(true)}
                />
              }
              {/* <img
                src={Correct}
                alt=""
                style={{
                  display: form.bank_list.valid && form.bank_list.checked ? "block" : "none",
                  marginLeft: "4px",
                }}
              /> */}
            </div>
          ),
        }}
      />

      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        fullWidth
        PaperProps={{
          style: {
            minHeight: "90%",
            maxHeight: "90%",
            minWidth: "90%",
            maxWidth: "90%",
            backgroundColor: "#131820",
          },
        }}
      >
        <div style={{ backgroundColor: "#131820", color: "#fff", position: "relative", padding: "10px 15px" }}>
          <span
            onClick={() => setOpen(false)}
            style={{ position: "absolute", top: "10px", right: "20px", cursor: "pointer" }}
          >
            &#10005;
          </span>
          <h1 style={{ textAlign: "center", fontSize: "20px" }}>{t("select_bank")}</h1>
          <TextField
            label={t("search")}
            variant="filled"
            fullWidth
            className={classes.root}
            onChange={(event) => {
              setSearch(event.target.value);
            }}
          />
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-around",
              gap: "15px",
              marginTop: "20px",
            }}
          >
            {channelList?.map?.((item) => {
              return (
                <div
                  onClick={() => {
                    setForm((prev) => {
                      return {
                        ...prev,
                        bank_list: { ...prev.bank_list, value: item, valid: true, checked: true },
                      };
                    });
                    dispatch(setChannel(item));
                    setOpen(false);
                  }}
                  key={item?.key}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "120px",
                    backgroundColor: "#40465c",
                    flexGrow: "1",
                    borderRadius: "4px",
                    padding: "10px",
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={item?.data?.[0]?.logo || item?.data?.[0]?.detail}
                    alt=""
                    style={{
                      height: "50px",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                      maxWidth: "110px",
                      objectFit: "contain",
                    }}
                  />
                  <p style={{ fontSize: "12px", textAlign: "center", marginBottom: "0" }}>
                    {item?.data?.[0]?.bank_name}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default BankListPopUp;
