import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import req from "../../../utils/axios";
import { useTranslation } from "react-i18next";
import { FormatMoney } from "../../../utils/formats";
import "./CheckoutPSE.scss";
import Warning from "../../comp/Warning";
import { useHistory } from "react-router-dom";
import { returnUrlAsParam } from "../../../utils/queryUrl";
import { Button } from "@material-ui/core";

const getDate = (date) => {
  const d = new Date(date);

  const options = {
    month: "2-digit", // 2-digit representation of the month
    day: "2-digit", // 2-digit representation of the day
    year: "numeric", // 4-digit representation of the year
    hour: "numeric", // 12-hour clock format
    minute: "numeric", // 2-digit representation of the minute
    hour12: true, // Use 12-hour clock format (AM/PM)
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  const formattedDate = formatter.format(d);
  return formattedDate;
};

const isTimeOut = (date) => {
  const creationTime = new Date(date + " GMT-05:00").getTime(); // colombia -5 UTC
  const nowTime = new Date().getTime();
  return nowTime - creationTime > 1500000; // 25 min
};

function CheckoutPSE() {
  const { payData, tradeInfo, prepayId } = useSelector((state) => state);
  const history = useHistory();
  const [status, setStatus] = useState("");
  const [pdf, setPdf] = useState(null);
  const { t } = useTranslation();

  const getStatus = async () => {
    const res = await req.post(`api/trade/pse-check?prepay_id=${prepayId}`);
    if (res.data.code === "10000" && res.data.data) {
      return res.data.data;
    }
  };

  const getPdf = async () => {
    const res = await req.post(`api/trade/pdf?prepay_id=${prepayId}`);
    if (res.data.code === "10000" && res.data.data) {
      return res.data.data;
    }
  };

  useEffect(() => {
    const makeCalls = async () => {
      const status = await getStatus();
      setStatus(status);
      const pdf = await getPdf();
      setPdf(pdf);
    };
    makeCalls();
  }, []);

  useEffect(() => {
    if (
      tradeInfo.payment_response.code !== "10000" ||
      (payData.partner_create_date && isTimeOut(payData.partner_create_date))
    ) {
      history.replace(`/result?prepay_id=${prepayId}${returnUrlAsParam()}`);
    }
  }, [tradeInfo, payData]);

  const statusColor = {
    FALLIDA: "#fa4d56",
    APROBADA: "#42be65",
    RECHAZADA: "#ff832b",
    PENDIENTE: "#f1c21b",
  };

  return (
    <div className="checkout-pse">
      <div className="checkout-pse-section">
        <h3 className="checkout-pse-title">{t("checkout.pse.company_info")}</h3>
        <div className="checkout-pse-row">
          <span>NIT</span>
          <span>9013821109</span>
        </div>
        <div className="checkout-pse-row">
          <span>{t("checkout.pse.business_name")}</span>
          <span>Pagsmile Colombia S.A.S</span>
        </div>
      </div>
      <div className="checkout-pse-section">
        <h3 className="checkout-pse-title">{t("checkout.pse.trx_detail")}</h3>
        <div className="checkout-pse-row">
          <span>{t("checkout.pse.trx_status")}</span>
          <span style={{ color: statusColor[status] }}>{status && t(`checkout.pse.status.${status}`)}</span>
        </div>
        {status === "PENDIENTE" && <Warning>{t(`checkout.pse.alert.pending`)} </Warning>}
        <div className="checkout-pse-row">
          <span>{t("checkout.pse.bank")}</span>
          <span>{payData.bank_name}</span>
        </div>
        <div className="checkout-pse-row">
          <span>{t("checkout.pse.tracking_code")}</span>
          <span>{payData.pay_channel_order}</span>
        </div>
        <div className="checkout-pse-row">
          <span>{t("checkout.pse.reference")}</span>
          <span>{tradeInfo.trade_no?.slice(-17)}</span>
        </div>
        <div className="checkout-pse-row">
          <span>{t("checkout.pse.trx_date")}</span>
          <span>{payData.partner_create_date}</span>
        </div>
        <div className="checkout-pse-row">
          <span>{t("checkout.pse.amount")}</span>
          <FormatMoney type="total" />
        </div>
        <div className="checkout-pse-row">
          <span>{t("checkout.pse.description")}</span>
          <span>{tradeInfo.subject}</span>
        </div>
      </div>
      {pdf && (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            style={{ backgroundColor: "var(--pagsmile--color)", color: "white", cursor: "pointer" }}
            onClick={() => {
              window.open(pdf, "_blank");
            }}
          >
            {t("download") + " PDF"}
          </Button>
        </div>
      )}
    </div>
  );
}

export default CheckoutPSE;
