function getParameterByName(name, url = window.location.href) {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return "";
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " ")) ?? "";
}

const returnUrlAsParam = () => {
  let returnUrl = getParameterByName("return_url");
  return returnUrl ? `&return_url=${encodeURIComponent(returnUrl)}` : "";
};

const backToShop = (url) => {
  const target = getParameterByName("ps_target", url);
  if (target === "top" && window.top) {
    window.top.location.replace(url);
  } else {
    window.location.replace(url);
  }
};

const isExtraFeesVisible = () => {
  return getParameterByName("ps_extra_fees") === "visible";
};

export { getParameterByName as default, returnUrlAsParam, backToShop, isExtraFeesVisible };
