const loadScript = (src, id) => {
  const existingScript = document.getElementById(id);
  if (!existingScript) {
    const script = document.createElement("script");
    script.src = src;
    script.id = id;
    document.head.appendChild(script);
  }
};

export const loadAddScript = (region) => {
  if (["BRA"].includes(region)) {
    loadScript("https://www.googletagmanager.com/gtag/js?id=AW-692048755", "advert-bra");
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "AW-692048755");

    gtag("event", "conversion", {
      send_to: "AW-692048755/5buqCIbVk7UBEPOm_8kC",
      transaction_id: "",
    });
  } else if (["MEX"].includes(region)) {
    loadScript("https://www.googletagmanager.com/gtag/js?id=AW-692048755", "advert-bra");
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "AW-692048755");

    gtag("event", "conversion", {
      send_to: "AW-692048755/7AnICITK5cUBEPOm_8kC",
      transaction_id: "",
    });
  } else if (["PHL"].includes(region)) {
    loadScript("https://www.googletagmanager.com/gtag/js?id=AW-692048755", "advert-bra");
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "AW-692048755");

    gtag("event", "conversion", {
      send_to: "AW-692048755/totxCIeor78ZEPOm_8kC",
      transaction_id: "",
    });
  }
};
