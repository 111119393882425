import React from "react";
import "./ProcessPage.scss";
import { useTranslation } from "react-i18next";
import LottiAnimation from "../../comp/LottiAnimation";
function SuccessPage() {
  const { t } = useTranslation();
  return (
    <div className="processPage">
      <LottiAnimation anime="success" />
      <p>{t("process_page1")}</p>
      <p style={{ fontSize: "14px", fontWeight: 700, color: "black" }}>{t("process_page2")}</p>
      <p>{t("visit_us")}</p>
      <a href="www.pagsmile.com">www.pagsmile.com</a>
    </div>
  );
}

export default SuccessPage;
