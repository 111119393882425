import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { FormatMoney } from "../../../utils/formats";
import { jsonParse } from "../../../utils/jsonParse";
import CodiQr from "../../../img/codi_qr.png";
import Codi from "../../../img/codi.png";
import WechatHK from "../../../img/wechathk.svg";
import Wechat from "../../../img/wechatpay.svg";
import WechatQr from "../../../img/wechat.svg";
import Button from "@material-ui/core/Button";
import "./CheckoutCodi.scss";
var QRCode = require("qrcode.react");

function CheckoutCodi(props) {
  const { t } = useTranslation();
  const downloadQr = () => {
    const canvas = document.querySelector(".checkoutCodi__content__qrContainer > canvas");
    const pngFile = canvas?.toDataURL("image/png");
    const downloadLink = document.createElement("a");
    downloadLink.download = "qrcode";
    downloadLink.href = `${pngFile}`;
    downloadLink?.click();
  };

  const imgObj = {
    "CoDi-STP": {
      logo: Codi,
      qrImg: CodiQr,
      qrCode: JSON.stringify(jsonParse(props.payData?.qr_code)),
    },
    "Wallet-WechatPayHK": {
      logo: WechatHK,
      qrImg: WechatQr,
      qrCode: props.payData?.qr_code,
    },
    "Wallet-WechatPay": {
      logo: Wechat,
      qrImg: WechatQr,
      qrCode: props.payData?.qr_code,
    },
  }[`${props.method}-${props.payChannel}`];

  return (
    <div className="checkoutCodi">
      <div className="checkoutCodi__title">
        <p>{t("wallet_inst1")}</p>
      </div>
      <div className="checkoutCodi__content">
        <div className="checkoutCodi__content__qrContent">
          <p className="checkoutCodi__content__total">
            <FormatMoney type="total" />
          </p>
          {((props.method === "CoDi" && props.payChannel === "STP") ||
            (props.method === "Wallet" && (props.payChannel === "WechatPayHK" || props.payChannel === "WechatPay"))) &&
            props.payData?.qr_code && (
              <div className="checkoutCodi__content__qrContainer">
                <QRCode
                  className="checkoutCodi__content__qr"
                  value={imgObj?.qrCode}
                  size={160}
                  bgColor={"#ffffff"}
                  fgColor={"#000000"}
                  level={"M"}
                  includeMargin={false}
                  renderAs={"canvas"}
                  imageSettings={{
                    src: imgObj?.qrImg,
                    x: null,
                    y: null,
                    height: 24,
                    width: 24,
                    excavate: true,
                  }}
                />
              </div>
            )}
          <Button className="checkoutCodi__content__download" onClick={downloadQr}>
            {t("download")}
          </Button>
        </div>
        <div className="checkoutCodi__content__inst">
          <div className="checkoutCodi__content__inst__titleContainer">
            <p className="checkoutCodi__content__inst__title">{t("wallet_inst2")}</p>
            <img src={imgObj?.logo} alt="" style={{ height: "40px" }} />
          </div>
          <div className="checkoutCodi__content__inst__howToPay">
            <p className="checkoutCodi__content__inst__howToPay__title">{t("wallet_inst3")}</p>
            <div>
              <p>{t("wallet_inst4.1")}</p>
              <p>{t("wallet_inst5")}</p>
              <p>{t("wallet_inst6")}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    method: state.method,
    payChannel: state.payChannel,
    payData: state.payData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutCodi);
