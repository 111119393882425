import React, { useEffect, useState } from "react";
import "./CheckoutPayvalida.scss";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { FormatMoney } from "../../../utils/formats";
import CopyButton from "../../comp/CopyButton";
import Baloto from "../../../img/baloto.png";
import Sured from "../../../img/sured.png";
import Gana from "../../../img/gana.png";
import Bancolombia from "../../../img/bancolombia.png";
import BCR from "../../../img/bcr.png";
import BNCR from "../../../img/bncr.png";

const getTime = (start, offset = 0) => {
  let date;
  if (typeof start === "string") {
    date = new Date(start); // this adds timezone exp: "2024-01-23" => Mon Jan 22 2024 19:00:00 GMT-0500 (Cuba Standard Time)
    date.setMinutes(date.getMinutes() + date.getTimezoneOffset()); // clear time zone
  } else {
    date = new Date(start + offset);
  }

  date = date.toDateString();

  return date?.split(" ") || [];
};

const getLogo = {
  Baloto: Baloto,
  SuRed: Sured,
  GANA: Gana,
  Bancolombia: Bancolombia,
  BCR: BCR,
  BNCR: BNCR,
};

const InfoRow = ({ number, title, content, copy = false }) => {
  return (
    <div className="infoRow">
      <div>
        <div className="infoRow__number">
          <span>{number}</span>
        </div>
        <span className="infoRow__title">{title}</span>
      </div>
      <span className="infoRow__content">
        {content}
        {copy && <CopyButton text={content} minWidth={50} height={32} onlyIcon />}
      </span>
    </div>
  );
};

function CheckoutBaloto(props) {
  const { t } = useTranslation();
  const [dueDate, setDueDate] = useState([]);
  useEffect(() => {
    setDueDate(
      props.method === "SuRed" && props.payData.due_date
        ? getTime(props.payData.due_date)
        : getTime(Number(props.tradeInfo?.create_time), 604800000)
    ); // Colombia; -5 hours UTC, +7 day
  }, []);
  const agreementNo = {
    "Baloto-IGT": 952408,
    "Baloto-PayValida": 950715,
    "SuRed-PayValida": "PAYVALIDA",
    "BCR-PayValida": "PAYVALIDA",
    "BNCR-PayValida": "PAYVALIDA",
    "BNCR-PayCash": "PayCash",
    "GANA-PayValida": "260",
    "Bancolombia-PayValida": "54245",
  };

  return (
    <div className="checkoutBaloto">
      <div className="checkoutBaloto__titleContainer">
        <div className="checkoutBaloto__title">
          <p>{t("payvalida_notice", { key: props.method === "BNCR" ? "Banco Nacional" : props.method })}</p>
          <img src={getLogo[props.method]} alt="" />
        </div>
        <p className="checkoutBaloto__title__text">{t("payvalida_notice2", { key: props.method })}</p>
      </div>
      <div className="checkoutBaloto__payInfo">
        <div className="checkoutBaloto__payInfo__rows">
          <InfoRow
            number={1}
            title={t("agree_code")}
            content={agreementNo[props.method + "-" + props.payChannel] ?? ""}
            copy
          />
          <InfoRow number={2} title={t("ref_no")} content={props.payData?.reference} copy />
          <InfoRow number={3} title={t("amount")} content={<FormatMoney type="total" />} />
          {!["BNCR", "BCR"].includes(props.method) && (
            <InfoRow number={4} title={t("id")} content={props.tradeInfo?.user?.identification?.number} />
          )}
        </div>
        <div className="checkoutBaloto__dateContainer">
          <div className="checkoutBaloto__date">
            <div className="checkoutBaloto__date__title">
              <p>{t("due_date")}</p>
            </div>
            <div className="checkoutBaloto__date__content">
              <p>{dueDate[2]}</p>
              <p>{dueDate[1]}</p>
            </div>
          </div>
        </div>
      </div>
      {props.method === "BNCR" && (
        <div className="checkoutBaloto__bncr">
          <div>
            <p className="checkoutBaloto__bncr__title">{t("cash")}</p>
            <ol>
              <li>{t("bncr_cash1", { key: props.payChannel })}</li>
              <li>{t("bncr_cash2")}</li>
            </ol>
          </div>
          <div>
            <p className="checkoutBaloto__bncr__title">{t("digital_banking")}</p>
            {props.payChannel === "PayCash" && (
              <ol>
                <li>{t("bncr_paycash1")}</li>
                <li>{t("bncr_paycash2")}</li>
                <li>{t("bncr_paycash3")}</li>
              </ol>
            )}
            {props.payChannel === "PayValida" && (
              <ol>
                <li>{t("bncr_bank1")}</li>
                <li>{t("bncr_bank2")}</li>
                <li>{t("bncr_bank3")}</li>
              </ol>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
    tradeInfo: state.tradeInfo,
    payChannel: state.payChannel,
    method: state.method,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBaloto);
