import React from "react";
import CheckoutTitle from "./CheckoutTitle";
import "./LocalpaymentBol.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function LocalpaymentBol() {
  const { t } = useTranslation();
  const { payData } = useSelector((state) => state);
  return (
    <div className="localpaymentBol">
      <CheckoutTitle />
      <div className="localpaymentBol__qr">
        <img src={payData.qr_img} alt="img-qr" />
      </div>
      <div className="localpaymentBol__how">
        <h3>{t("wallet_inst3")}</h3>
        <ol>
          <li className="my-1">{t("checkout.localpayment.BOL.how.1")}</li>
          <li className="my-1">{t("checkout.localpayment.BOL.how.2")}</li>
        </ol>
      </div>
    </div>
  );
}

export default LocalpaymentBol;
