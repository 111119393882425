import React from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import { checkSource } from "../../utils/validations";
import { FormHelperText } from "@material-ui/core";
import "./Source.scss";

function Source({ form, setForm, setFocusOnInput = () => {}, mobile }) {
  const { t } = useTranslation();
  return (
    <div className="originalSource">
      <TextField
        id="inputform-source"
        inputProps={{ autocomplete: "new-password", "ga-data": "350" }}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size="small"
        error={!form.source.valid}
        helperText={!form.source.valid && t("invalid_source")}
        label={t("source")}
        variant="outlined"
        value={form.source.value}
        onChange={(event) => {
          var value = event.target.value;
          if (value?.length > form.source.max) {
            value = value.slice(0, form.source.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              source: { ...prev.source, value: value },
            };
          });
        }}
        onBlur={() => {
          checkSource({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.source.valid && form.source.checked ? "block" : "none",
                }}
              />
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText>{t("source_notice")}</FormHelperText>
    </div>
  );
}

export default Source;
