import React from "react";
import CheckoutTitle from "./CheckoutTitle";
import Warning from "../../comp/Warning";
import InfoIcon from "../../../img/info_icon.svg";
import { useSelector } from "react-redux";
import { FormatMoney } from "../../../utils/formats";
import "./CheckoutMultibanco.scss";

function CheckoutMultibanco() {
  const { payData } = useSelector((state) => state);
  return (
    <div style={{ color: "#465364", fontFamily: "Montserrat-Regular" }} className="CheckoutMultibanco">
      <CheckoutTitle />
      <Warning bg="#F1F8F2" color="#465364" icon={InfoIcon}>
        In your online account or ATM machine choose "Payment and otherservices” and then “Payments of
        services/shopping”.
      </Warning>
      <section style={{ margin: "30px 0" }} className="table">
        <table style={{ width: "100%", borderCollapse: "collapse", border: "1px solid #E7EBF0", fontSize: "15px" }}>
          <tbody>
            <tr>
              <td style={{ border: "1px solid #E7EBF0" }}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
                  <span>Entity (PPRO Financial for PAGSMILE LIMITED*)</span>
                  <span>{payData.supplier_id}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid #E7EBF0" }}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
                  <span>Reference</span>
                  <span>{payData.reference}</span>
                </div>
              </td>
            </tr>
            <tr>
              <td style={{ border: "1px solid #E7EBF0" }}>
                <div style={{ display: "flex", justifyContent: "space-between", padding: "4px 8px" }}>
                  <span>Amount</span>
                  <span>
                    <FormatMoney type="total" />
                  </span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer style={{ fontSize: "12px", padding: "50px 5px" }}>
        * On behalf of PAGSMILE LIMITED, PPRO Financial Ltd is the processor of your Multibanco payment.
      </footer>
    </div>
  );
}

export default CheckoutMultibanco;
