import React, { useState, useEffect } from "react";
import "./CheckoutBankCashCommon.scss";
import BcpLogo from "../../../img/bcp.png";
import BbvaLogo from "../../../img/bbva.png";
import InterbankLogo from "../../../img/interbank.png";
import KasnetLogo from "../../../img/kasnet.png";
import ScotiabankLogo from "../../../img/scotiabank.png";
import TamboLogo from "../../../img/tambo.png";
import RappiBankLogo from "../../../img/rappibank.png";
import CajaHuancayo from "../../../img/latam-agents/caja-huancayo.svg";
import CajaArequipaLogo from "../../../img/caja-arequipa.svg";
import YapeLogo from "../../../img/yape.png";
import { Trans, useTranslation } from "react-i18next";
import { FormatMoney } from "../../../utils/formats";
import { connect, useSelector } from "react-redux";
import { payCashChannels } from "../../../utils/config/paycash";
import CountDown from "../../comp/CountDown";
import CopyButton from "../../comp/CopyButton";
var classNames = require("classnames");

const AgentLogo = ({ name, active }) => {
  const color = active ? "#0666EB" : "#C2C9D6";
  switch (name) {
    case "bank-mobile":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="smartphone-icon"
        >
          <path
            d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
            stroke={color}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path d="M12 18H12.01" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      );
    case "wallet":
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="smartphone-icon"
        >
          <path
            d="M17 2H7C5.89543 2 5 2.89543 5 4V20C5 21.1046 5.89543 22 7 22H17C18.1046 22 19 21.1046 19 20V4C19 2.89543 18.1046 2 17 2Z"
            stroke={color}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path d="M12 18H12.01" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      );
    case "bank-web":
      return (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="monitor-icon"
        >
          <path
            d="M20.125 3H4.125C3.02043 3 2.125 3.89543 2.125 5V15C2.125 16.1046 3.02043 17 4.125 17H20.125C21.2296 17 22.125 16.1046 22.125 15V5C22.125 3.89543 21.2296 3 20.125 3Z"
            stroke={color}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path
            d="M8.125 21H16.125"
            stroke={color}
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          ></path>
          <path d="M12.125 17V21" stroke={color} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </svg>
      );
    case "bank":
      return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" id="home-icon">
          <g clip-path="url(#clip0_368_2663)">
            <path
              d="M12.375 1L1.875 9H22.875L12.375 1Z"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M3.875 21V9H20.875V21"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M7.875 21V9H16.875V21"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M1.875 22H22.875"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_368_2663">
              <rect width="24" height="24" fill="white" transform="translate(0.125)"></rect>
            </clipPath>
          </defs>
        </svg>
      );
    case "agent":
      return (
        <svg
          width="25"
          height="24"
          viewBox="0 0 25 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          id="credit-card-icon"
        >
          <g clip-path="url(#clip0_368_2660)">
            <path
              d="M21.0942 2H4.65578C4.19691 2 3.79693 2.3123 3.68563 2.75746L2.18563 8.75746C2.02785 9.38861 2.50514 10 3.15571 10H3.875V20H21.875V10H22.5942C23.2448 10 23.7222 9.38861 23.5644 8.75746L22.0644 2.75746C21.9531 2.3123 21.5531 2 21.0942 2Z"
              stroke={color}
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></path>
            <path
              d="M22.875 10L21.8231 10C21.2308 10 20.6776 10.296 20.3491 10.7889V10.7889C19.6478 11.8408 18.1022 11.8408 17.4009 10.7889L17.375 10.75C17.1371 10.3932 16.6129 10.3932 16.375 10.75V10.75C15.6614 11.8204 14.0886 11.8204 13.375 10.75V10.75C13.1371 10.3932 12.6129 10.3932 12.375 10.75V10.75C11.6614 11.8204 10.0886 11.8204 9.375 10.75V10.75C9.13714 10.3932 8.61286 10.3932 8.375 10.75L8.34907 10.7889C7.64783 11.8408 6.10217 11.8408 5.40093 10.7889V10.7889C5.07236 10.296 4.51921 10 3.92686 10L3.875 10"
              stroke={color}
              stroke-width="2"
            ></path>
          </g>
          <defs>
            <clipPath id="clip0_368_2660">
              <rect width="24" height="24" fill="white" transform="translate(0.875)"></rect>
            </clipPath>
          </defs>
        </svg>
      );
    default:
      return <></>;
  }
};

const usePayConfig = () => {
  const { t } = useTranslation();
  const { payChannel, tradeInfo, payData } = useSelector((state) => state);
  const region = tradeInfo?.app_info?.region;
  if (payChannel === "Monnet" || payChannel === "KASHIO") {
    return [
      {
        name: "BCP",
        logo: BcpLogo,
        methods: {
          "bank-mobile": [
            'Ingresa a la aplicación móvil del BCP, accede al menú principal y selecciona la opción "Pagar Servicios".',
            'Selecciona la opción "Buscar en la sección".',
            'Busca "KashIO Perú", elige KashIO Perú Soles e ingresa tu código de pago.',
            "Selecciona la deuda a pagar, confirma y ¡Listo!",
          ],
          "bank-web": [
            "Ingresa a tu banca web BCP desde: https://www.viabcp.com",
            'Ve al menú de "Pagos", y selecciona "Pagar un servicio".',
            'Busca "KashIO Perú", elige KashIO Perú Soles e ingresa tu código de pago.',
            "Selecciona la deuda a pagar, confirma y ¡Listo!",
          ],
          bank: [
            "Acércate a una oficina o agencia del BCP e indica que deseas pagar al servicio de recaudación de KashIO.",
            "Elije el tipo de moneda a pagar y proporciona el número de tu DNI, RUC, teléfono o código único, según corresponda.",
            "Elije la deuda que deseas pagar y listo. Recibe el voucher de pago de la operación.",
          ],
          agent: [
            "Acércate a un agente del BCP e indica que deseas pagar al servicio de recaudación de KashIO Perú.",
            "ndica el Código de Agente de KashIO: 15813.",
            "Indica tu código de pago.",
            "Indica monto total, moneda a pagar y ¡Listo!",
            "Recibe el voucher de pago de la operación.",
          ],
        },
      },
      {
        name: "Yape",
        logo: YapeLogo,
        methods: {
          "bank-mobile": [
            "En tu aplicativo Yape, ingresa a Yapear Servicios.",
            'Ubica Kashio en la categoría "Compras por Internet".',
            "Coloca tu código de pago y yapea !.",
          ],
        },
      },
      {
        name: "BBVA",
        logo: BbvaLogo,
        methods: {
          "bank-mobile": [
            "Ingresa a la aplicación móvil del BBVA, accede al menú principal y selecciona tu cuenta bancaria.",
            'Elige la opción “Pagar servicio” y selecciona "Agregar servicio a pagar".',
            'Busca "KashIO Perú", elige KashIO Perú Soles e ingresa tu código de pago.',
            "Selecciona la deuda a pagar, confirma y ¡Listo!",
          ],
          "bank-web": [
            "Ingresa a tu banca web BBVA desde: https://www.bbva.pe",
            'Ve a tu cuenta, pulsa el botón "Quiero" y selecciona la opción "Pagar servicio".',
            'Selecciona la opción "Instituciones y empresas" y pulsa la opción "Nombre".',
            'Busca "KashIO Perú", elige KashIO Perú Soles, pulsa siguiente e ingresa tu código de pago',
            "Selecciona la deuda a pagar, confirma y ¡Listo!!",
          ],
          bank: [
            "Acércate a una oficina o agencia del BBVA e indica que deseas pagar al servicio de recaudación de KashIO.",
            "Elije el tipo de moneda en la que quieres pagar.",
            "Proporciona tu número de DNI, RUC, teléfono o código único según corresponda y elije la deuda que quieres pagar.",
            "Recibe el voucher de pago de la operación.",
          ],
          agent: [
            "Acércate a un agente del BBVA e indica que deseas pagar al servicio de recaudación de KashIO Perú.",
            "Indica el Código de Agente de KashIO: Pago en Soles: 11140",
            "Indica tu código de pago.",
            "Indica monto total, moneda a pagar y ¡Listo!",
            "Recibe el voucher de pago de la operación.",
          ],
        },
      },
      {
        name: "Interbank",
        logo: InterbankLogo,
        methods: {
          "bank-mobile": [
            "Ingresa a la aplicación móvil de Interbank.",
            'Selecciona la opción "Operaciones" y elige la opción "Pago y recargas".',
            'Selecciona "Pago a institución o empresa".',
            'Busca "KashIO Perú", elige KashIO Perú Soles e ingresa tu código de pago.',
            "Selecciona la deuda a pagar, confirma y ¡Listo!",
          ],
          "bank-web": [
            "Ingresa a tu banca web Interbank desde: https://interbank.pe",
            'Selecciona "Mis operaciones" y eligen la opción "Pago o Recarga".',
            'Selecciona "Pago a institución o empresa".',
            'Busca "KashIO Perú", elige KashIO Perú Soles e ingresa tu código de pago.',
            "Selecciona la deuda a pagar, confirma y ¡Listo!",
          ],
          bank: [
            "Acércate a una oficina o agencia del Interbank e indica que deseas pagar al servicio de recaudación de KashIO.",
            "Elige la moneda a pagar y proporciona tu número de DNI, RUC, teléfono o código único según corresponda.",
            "Elije las deudas a pagar y listo.",
            "Recibe el voucher de pago de la operación.",
          ],
          agent: [
            "Acércate a un agente de Interbank e indica que deseas pagar al servicio de recaudación de KashIO Perú.",
            "Indica el Código de Agente de KashIO: Pago en Soles: 0791501",
            "Indica tu código de pago.",
            "Indica monto total, moneda a pagar y ¡Listo!",
            "Recibe el voucher de pago de la operación.",
          ],
        },
      },
      // {
      //   name: "Scotiabank",
      //   logo: ScotiabankLogo,
      //   methods: {
      //     "bank-mobile": [
      //       "Ingresa a la aplicación móvil de Scotiabank.",
      //       'Selecciona la opción "Quiero" y elige la opción "Pagar".',
      //       'Selecciona "Servicios o Instituciones".',
      //       'Busca "KashIO Perú", elige KashIO Perú Soles e ingresa tu código de pago.',
      //       "Selecciona la deuda a pagar, confirma y ¡Listo!",
      //     ],
      //     "bank-web": [
      //       "Ingresa a tu banca web Scotiabank desde: https://mi.scotiabank.com.pe/",
      //       'Elige la opción "Pagar" y selecciona la opción "Servicios o Instituciones".',
      //       'Busca "KashIO Perú", elige KashIO Perú Soles e ingresa tu código de pago.',
      //       "Selecciona la deuda a pagar, confirma y ¡Listo!",
      //     ],
      //     bank: [
      //       "Acércate a una oficina o agencia del Scotiabank e indica que deseas pagar al servicio de recaudación de KASHIO PERU.",
      //       "Proporciona tu número de DNI, RUC, teléfono o código único según corresponda y elije la deuda que quieres pagar.",
      //       "Elije el tipo de moneda y el moto a pagar.",
      //       "Recibe el voucher de pago de la operación.",
      //     ],
      //     agent: [
      //       "Acércate a un agente de Scotiabank e indica que deseas pagar al servicio de recaudación de KashIO Perú o indica nuestro RUC 20602393799.",
      //       "Indica tu código de pago.",
      //       "Indica monto total, moneda a pagar y ¡Listo!",
      //       "Recibe el voucher de pago de la operación.",
      //     ],
      //   },
      // },
      {
        name: "Kasnet",
        logo: KasnetLogo,
        methods: {
          agent: [
            "Acércate a un agente de KASNET e indica que deseas pagar al servicio de recaudación de KashIO Perú.",
            "Indica el Código de Agente de KashIO: 220044 .",
            "Indica tu código de pago.",
            "Indica monto total, moneda a pagar y ¡Listo!",
            "Recibe el voucher de pago de la operación.",
          ],
        },
      },
      // {
      //   name: "Tambo",
      //   logo: TamboLogo,
      //   methods: {
      //     agent: [
      //       'Acércate a una tienda Tambo+ e indica que deseas realizar pagos por agente a "KashIO".',
      //       "Indica tu código de pago.",
      //       "Elige la Deuda a pagar y ¡Listo!",
      //       "Recibe el voucher de pago de la operación.",
      //     ],
      //   },
      // },
      // {
      //   name: "RappiBank",
      //   logo: RappiBankLogo,
      //   methods: {
      //     "bank-mobile": [
      //       'Ingresa a la aplicación móvil del BCP, accede al menú principal y selecciona la opción "Pagar Servicios".',
      //       'Selecciona la opción "Buscar en la sección".',
      //       'Busca "Kashio Perú", elige Kashio Perú Soles e ingresa tu código de pago.',
      //       "Selecciona la deuda a pagar, confirma y ¡Listo!",
      //     ],
      //   },
      // },
      {
        name: "Caja Arequipa",
        logo: CajaArequipaLogo,
        methods: {
          "bank-mobile": [
            'Ingresa a la aplicación móvil de Caja Arequipa y selecciona la opción de "Pagos".',
            'Elige la opción de "Pagos de Servicios e Instituciones".',
            'Escoge opción "Otros" y busca "Kashio".',
            "Ingresa tu código de pago.",
            "¡Confirma el monto a pagar y listo!",
          ],
          "bank-web": [
            'Ingresa al Homebanking de Caja Arequipa y selecciona la opción de "Pagos"',
            'Elige la opción de "Pagos de Servicios e Instituciones".',
            'Escoge la opción "Otros" y busca "Kashio".',
            "Ingresa tu código de pago.",
            "¡Confirma el monto a pagar y listo!",
          ],
          bank: [
            'Indica que deseas realizar "Pago Recaudo" a "Kashio".',
            "Indica tu código de pago.",
            "Confirma el monto de la deuda que deseas pagar.",
            "Realiza el pago y recibe tu comprobante.",
          ],
          agent: [
            'Indica que deseas realizar "Pago Servicios" a "Kashio".',
            "Indica tu código de pago.",
            "Valida importe del pago que deseas realizar.",
            "Realiza el pago y recibe tu comprobante.",
          ],
        },
      },
      {
        name: "Caja Huancayo",
        logo: CajaHuancayo,
        methods: {
          "bank-mobile": [
            'Ingresa a la aplicación móvil de Caja Huancayo y selecciona la opción "Pagos".',
            'Elige la opción "Otros pagos".',
            'Busca "Kashio Perú", elige Kashio Perú Soles.',
            "Ingresa el código de pago.",
            "¡Confirma el monto a pagar y listo!",
          ],
          "bank-web": [
            'Selecciona la opción "PAGO DE SERVICIOS/Pago de servicios". a. Selecciona la cuenta a cargar y el tipo de servicio: Otros. b. Busca en empresa: KASHIO. c. Haz clic en el botón "BUSCAR". d. Selecciona el concepto a pagar, según lo requerido.',
            "Selecciona en Concepto de Pago la moneda correspondiente: SOLES. a. Ingresa el código de pago en Código de clientes en la Empresa. b. Haz clic en el resultado de la búsqueda.",
            "Selecciona la operación y confirma.",
            'Confirma los datos de la operación, luego ingresa el token digital y haz clic en "Confirmar Operación".',
          ],
          bank: [
            "Nombre del servicio de recaudación: KASHIO PERÚ.",
            "Código de pago",
            "Moneda del pago: soles.",
            "Confirma el monto a pagar.",
          ],
        },
      },
    ];
  } else if (payChannel === "PayCash") {
    return payCashChannels[tradeInfo?.app_info?.region]?.[0]?.data?.map?.((channel) => {
      const common = {
        name: channel.key,
        logo: channel.logo,
        methods: {
          agent: [
            <Trans
              i18nKey="checkout.cash_bank.paycash.how_to.common.agent.1"
              values={{
                key: payData?.reference,
              }}
            />,
            t("checkout.cash_bank.paycash.how_to.common.agent.2"),
            <Trans
              i18nKey="checkout.cash_bank.paycash.how_to.common.agent.3"
              components={[
                <a
                  href="https://www.paycashglobal.com/buscador.php"
                  target="_blank"
                  rel="noreferrer"
                  className="underline"
                >
                  here
                </a>,
              ]}
            />,
          ],
        },
      };

      const methodsConfig = {
        GTM: {
          "Banco Agromercantil": {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.GTM.Banco Agromercantil.agent.1"
                values={{ code: 515, key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.GTM.Banco Agromercantil.bank-web.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.GTM.Banco Agromercantil.bank-web.2"
                values={{ code: 515 }}
              />,
              t("checkout.cash_bank.paycash.how_to.GTM.Banco Agromercantil.bank-web.3"),
            ],
          },
          "Banco G&T Continental": {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.GTM.Banco G&T Continental.agent.1"
                values={{ code: 1032, key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.GTM.Banco G&T Continental.bank-web.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.GTM.Banco G&T Continental.bank-web.2"
                values={{ code: 1032 }}
              />,
              t("checkout.cash_bank.paycash.how_to.GTM.Banco G&T Continental.bank-web.3"),
              t("checkout.cash_bank.paycash.how_to.GTM.Banco G&T Continental.bank-web.4"),
            ],
          },
          Pronet: {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.GTM.Pronet.agent.1"
                values={{ key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.GTM.Pronet.bank-web.1"),
              t("checkout.cash_bank.paycash.how_to.GTM.Pronet.bank-web.2"),
              t("checkout.cash_bank.paycash.how_to.GTM.Pronet.bank-web.3"),
              t("checkout.cash_bank.paycash.how_to.GTM.Pronet.bank-web.4"),
              t("checkout.cash_bank.paycash.how_to.GTM.Pronet.bank-web.5"),
            ],
          },
        },
        PER: {
          "Banco BCP": {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Banco BCP.agent.1"
                values={{ code: 24162, digit: 13 }}
              />,
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Banco BCP.agent.2"
                values={{ key: payData?.reference }}
              />,
              common.methods.agent[2],
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.PER.Banco BCP.bank-web.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Banco BCP.bank-web.2"
                values={{
                  digit: 13,
                }}
              />,
              t("checkout.cash_bank.paycash.how_to.PER.Banco BCP.bank-web.3"),
            ],
          },
          Yape: {
            "bank-mobile": [
              t("checkout.cash_bank.paycash.how_to.PER.Yape.bank-mobile.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Yape.bank-mobile.2"
                values={{
                  digit: 13,
                }}
              />,
              t("checkout.cash_bank.paycash.how_to.PER.Yape.bank-mobile.3"),
            ],
          },
          BBVA: {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.BBVA.agent.1"
                values={{ key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.PER.BBVA.bank-web.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.BBVA.bank-web.2"
                values={{
                  key: payData?.reference,
                }}
              />,
              t("checkout.cash_bank.paycash.how_to.PER.BBVA.bank-web.3"),
            ],
          },
          Interbank: {
            agent: [
              <Trans i18nKey="checkout.cash_bank.paycash.how_to.PER.Interbank.agent.1" values={{ code: "0319901" }} />,
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Interbank.agent.2"
                values={{ key: payData?.reference }}
              />,
              common.methods.agent[2],
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.PER.Interbank.bank-web.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Interbank.bank-web.2"
                values={{ key: payData?.reference }}
              />,
              t("checkout.cash_bank.paycash.how_to.PER.Interbank.bank-web.3"),
            ],
            "bank-mobile": [
              t("checkout.cash_bank.paycash.how_to.PER.Interbank.bank-mobile.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Interbank.bank-mobile.2"
                values={{ key: payData?.reference }}
              />,
              t("checkout.cash_bank.paycash.how_to.PER.Interbank.bank-mobile.3"),
            ],
          },
          Izipay: {
            wallet: [
              t("checkout.cash_bank.paycash.how_to.PER.Izipay.wallet.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Izipay.wallet.2"
                values={{ key: payData?.reference }}
              />,
              t("checkout.cash_bank.paycash.how_to.PER.Izipay.wallet.3"),
            ],
          },
          "Banco Pichincha": {
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.PER.Banco Pichincha.bank-web.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Banco Pichincha.bank-web.2"
                values={{ key: payData?.reference }}
              />,
              t("checkout.cash_bank.paycash.how_to.PER.Banco Pichincha.bank-web.3"),
              t("checkout.cash_bank.paycash.how_to.PER.Banco Pichincha.bank-web.4"),
            ],
          },
          "Banco del Comercio": {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Banco del Comercio.agent.1"
                values={{ key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.PER.Banco del Comercio.bank-web.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Banco del Comercio.bank-web.2"
                values={{ key: payData?.reference }}
              />,
              t("checkout.cash_bank.paycash.how_to.PER.Banco del Comercio.bank-web.3"),
            ],
            "bank-mobile": [
              t("checkout.cash_bank.paycash.how_to.PER.Banco del Comercio.bank-mobile.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Banco del Comercio.bank-mobile.2"
                values={{ key: payData?.reference }}
              />,
              t("checkout.cash_bank.paycash.how_to.PER.Banco del Comercio.bank-mobile.3"),
            ],
          },
          Kasnet: {
            agent: [
              <Trans i18nKey="checkout.cash_bank.paycash.how_to.PER.Kasnet.agent.1" values={{ code: "220114" }} />,
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Kasnet.agent.2"
                values={{ key: payData?.reference }}
              />,
              common.methods.agent[2],
            ],
          },
          Megapunto: {
            agent: [
              t("checkout.cash_bank.paycash.how_to.PER.Megapunto.agent.1"),
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.PER.Megapunto.agent.2"
                values={{ key: payData?.reference }}
              />,
              common.methods.agent[2],
            ],
          },
        },
        ECU: {
          "Jardín Azuayo": {
            agent: [...common.methods.agent],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.ECU.Jardín Azuayo.bank-web.1"),
              t("checkout.cash_bank.paycash.how_to.ECU.Jardín Azuayo.bank-web.2"),
              t("checkout.cash_bank.paycash.how_to.ECU.Jardín Azuayo.bank-web.3"),
            ],
          },
        },
        CRI: {
          "Banco Nacional": {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.CRI.Banco Nacional.agent.1"
                values={{ key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.CRI.Banco Nacional.bank-web.1"),
              t("checkout.cash_bank.paycash.how_to.CRI.Banco Nacional.bank-web.2"),
              t("checkout.cash_bank.paycash.how_to.CRI.Banco Nacional.bank-web.3"),
            ],
          },
        },
        MEX: {
          "Bodega Aurrera": {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.MEX.BWS.agent.1"
                values={{ code: 198, key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
          },
          Walmart: {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.MEX.BWS.agent.1"
                values={{ code: 198, key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
          },
          "Walmart Express": {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.MEX.BWS.agent.1"
                values={{ code: 198, key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
          },
          "Sam's Club": {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.MEX.BWS.agent.1"
                values={{ code: 198, key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
          },
          Telecom: {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.MEX.Telecom.agent.1"
                values={{ code: 3724, key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
          },
          Santander: {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.MEX.Santander.agent.1"
                values={{ code: 7292, key: payData?.reference }}
              />,
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.MEX.Santander.bank-web.1"),
              t("checkout.cash_bank.paycash.how_to.MEX.Santander.bank-web.2"),
              t("checkout.cash_bank.paycash.how_to.MEX.Santander.bank-web.3"),
              t("checkout.cash_bank.paycash.how_to.MEX.Santander.bank-web.4"),
            ],
          },
          BBVA: {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.MEX.BBVA.agent.1"
                values={{ code: 1420712, key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.MEX.BBVA.bank-web.1"),
              t("checkout.cash_bank.paycash.how_to.MEX.BBVA.bank-web.2"),
              t("checkout.cash_bank.paycash.how_to.MEX.BBVA.bank-web.3"),
              t("checkout.cash_bank.paycash.how_to.MEX.BBVA.bank-web.4"),
            ],
          },
          Banorte: {
            agent: [
              <Trans
                i18nKey="checkout.cash_bank.paycash.how_to.MEX.Banorte.agent.1"
                values={{ code: 3724, key: payData?.reference }}
              />,
              common.methods.agent[1],
              common.methods.agent[2],
            ],
            "bank-web": [
              t("checkout.cash_bank.paycash.how_to.MEX.Banorte.bank-web.1"),
              t("checkout.cash_bank.paycash.how_to.MEX.Banorte.bank-web.2"),
              t("checkout.cash_bank.paycash.how_to.MEX.Banorte.bank-web.3"),
              t("checkout.cash_bank.paycash.how_to.MEX.Banorte.bank-web.4"),
            ],
          },
        },
      };

      const methodConfig = methodsConfig[region]?.[channel.name];
      if (methodConfig) {
        common.methods = methodConfig;
      }

      return common;
    });
  }
  return [];
};

function CheckoutBankCashCommon(props) {
  const { t } = useTranslation();
  const region = props.tradeInfo?.app_info?.region;
  const payConfig = usePayConfig();
  const firstChannel = payConfig?.[0]?.name;
  const [selectedCannel, setSelectedChannel] = useState(null);
  const [selectedMethod, setSelectedMethod] = useState(null);
  const provider = { PayCash: "PAYCASH", Monnet: "KASHIO PERU SOLES", KASHIO: "KASHIO PERU SOLES" };

  // console.log("selected: ", payConfig.find((item) => item.name === selectedCannel)?.methods?.[selectedMethod]);

  const titlesConfig = {
    PayCash: {
      ECU: {
        agent: t("paycash.agent.title.2"),
      },
      MEX: {
        agent: t("paycash.agent.title.3"),
      },
      PAN: {
        agent: t("paycash.agent.title.4"),
      },
    },
  };

  useEffect(() => {
    setSelectedChannel(firstChannel);
  }, [firstChannel]);

  useEffect(() => {
    if (selectedCannel) {
      const targetMethod = payConfig.find((item) => item.name === selectedCannel)?.methods?.[selectedMethod];
      if (!targetMethod) {
        setSelectedMethod(Object.keys(payConfig.find((item) => item.name === selectedCannel)?.methods || [])?.[0]);
      }
    }
  }, [selectedCannel]);

  return (
    <div className="CheckoutBankCashCommon">
      <div className="CheckoutBankCashCommon__title">
        <div className="CheckoutBankCashCommon__titleLeft">
          <div>
            <p className="text">{t("service_name")}</p>
            <p className="value">{provider[props.payChannel]}</p>
          </div>
          <div>
            <p className="text">{t("pay_code")}</p>
            <p className="value reference">
              {props.payData?.reference}&nbsp;&nbsp;
              <CopyButton text={props.payData?.reference} onlyIcon height={30} minWidth={40} />
            </p>
          </div>
        </div>
        <div className="CheckoutBankCashCommon__titleMiddle">
          <div>
            <p className="text">{t("due_date")}</p>
            {props.payData?.due_date ? (
              <p>
                <CountDown
                  time={new Date(props.payData?.due_date)?.getTime?.() - Date.now()}
                  style={{ color: "#000000;" }}
                  hourOnly
                />
              </p>
            ) : (
              "N/A"
            )}
          </div>
          <div>
            <p className="text">{t("pay_amount")}</p>
            <p className="value">
              <FormatMoney type="total" />
            </p>
          </div>
        </div>
        {/* <div className="CheckoutBankCashCommon__titleRight">Expiry time</div> */}
      </div>
      <div className="CheckoutBankCashCommon__agents">
        <p className="CheckoutBankCashCommon__agentsTitle">1. {t("checkout.cash_bank.choose_place")}</p>
        <div className="CheckoutBankCashCommon__agentsContainer">
          {payConfig?.map?.((item) => (
            <div
              className={classNames("CheckoutBankCashCommon__agent", {
                "logo-active-border": selectedCannel === item?.name,
              })}
              key={item.name}
              onClick={() => {
                setSelectedChannel(item?.name);
              }}
            >
              <img
                className={classNames("CheckoutBankCashCommon__agentsLogo", {
                  "logo-active": selectedCannel === item?.name,
                })}
                src={item.logo}
                alt=""
              />
            </div>
          ))}
        </div>
      </div>
      <div className="CheckoutBankCashCommon__methods">
        <p className="CheckoutBankCashCommon__methodsTitle">2. {t("checkout.cash_bank.choose_method")}</p>
        <div className="CheckoutBankCashCommon__methodList">
          {Object.keys(payConfig.find((item) => item.name === selectedCannel)?.methods || {}).map((item) => (
            <div
              className={classNames("CheckoutBankCashCommon__methodContainer", {
                "logo-active-border": selectedMethod === item,
              })}
              onClick={() => {
                setSelectedMethod(item);
              }}
            >
              <AgentLogo name={item} active={item === selectedMethod} />
              <p>{t(`mon_${item}`)}</p>
            </div>
          ))}
        </div>
        <div className="CheckoutBankCashCommon__methodIns">
          <ol>
            {payConfig
              .find((item) => item.name === selectedCannel)
              ?.methods?.[selectedMethod]?.map?.((item, index) => (
                <li key={index}>{item}</li>
              ))}
          </ol>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    payData: state.payData,
    payChannel: state.payChannel,
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutBankCashCommon);
