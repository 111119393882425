import React from "react";
import { useSelector } from "react-redux";
import Name from "../Name";
import TaxId from "../TaxId";
import MobilePhone from "../MobilePhone";
import Email from "../Email";
import Address from "../Address/Address";
import PostalCode from "../PostalCode";

const partialConfig = {
  "CreditCard-CHANNEL_GROUP_ROUTER": ["name", "cardNumber", "expYear", "expMonth", "cvc"],
  "CreditCard-OpenPay": ["name", "cardNumber", "expYear", "expMonth", "cvc"],
};

function useFormConfig(form, setForm) {
  const { method, payChannel, tradeInfo } = useSelector((state) => state);
  const config = tradeInfo.channel_info_list.find(
    (channel) => channel.method === method && channel.channel === payChannel
  )?.channel_required_params;

  if (
    config &&
    (method === "SPEI" ||
      method === "OXXO" ||
      method === "OXXOPay" ||
      (method === "Cash" && payChannel === "PayCash") ||
      (method === "Cash" && payChannel === "OpenPay") ||
      (method === "Cash" && payChannel === "Banwire") ||
      (method === "CreditCard" && payChannel === "CHANNEL_GROUP_ROUTER") ||
      (method === "CreditCard" && payChannel === "OpenPay"))
  ) {
    const formValues = {
      name: {
        value: "name",
        jsx: <Name form={form} setForm={setForm} />,
      },
      id: { value: "taxId", jsx: <TaxId form={form} setForm={setForm} /> },
      phone: { value: "phone", jsx: <MobilePhone form={form} setForm={setForm} /> },
      email: { value: "email", jsx: <Email form={form} setForm={setForm} /> },
      zipcode: { value: "postalCode", jsx: <PostalCode form={form} setForm={setForm} tradeInfo={tradeInfo} /> },
      address: {
        value: form.isSameAddress
          ? ["bill_country", "bill_state", "bill_city", "bill_street", "bill_no", "bill_postalCode"]
          : [
              "bill_country",
              "bill_state",
              "bill_city",
              "bill_street",
              "bill_no",
              "bill_postalCode",
              "delivery_country",
              "delivery_state",
              "delivery_city",
              "delivery_street",
              "delivery_no",
              "delivery_postalCode",
            ],
        jsx: <Address form={form} setForm={setForm} />,
      },
    };
    const formConfig = [];
    const jsx = [];
    let address = false;
    config
      .sort((a, b) => a?.sort - b?.sort)
      .forEach((item) => {
        if (formValues[item.code]) {
          const value = formValues[item.code].value;
          if (Array.isArray(value)) {
            formConfig.push(...value);
            if (item.code === "address") address = true;
          } else {
            formConfig.push(value);
            jsx.push(formValues[item.code].jsx);
          }
        }
      });

    return {
      formConfig: partialConfig[`${method}-${payChannel}`]
        ? [...partialConfig[`${method}-${payChannel}`], ...formConfig]
        : formConfig,
      jsx: {
        mobile: (
          <>
            {jsx.map((item, index) => (
              <React.Fragment key={index}>{item}</React.Fragment>
            ))}
            {address && formValues.address.jsx}
          </>
        ),
        pc: (
          <>
            <PcComponent array={jsx} />
            {address && formValues.address.jsx}
          </>
        ),
      },
    };
  }

  return null;
}

export default useFormConfig;

const PcComponent = ({ array }) => {
  // Function to chunk the array into pairs
  const chunkArray = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  // Chunk the array into pairs
  const chunks = chunkArray(array, 2);

  return (
    <>
      {chunks.map((chunk, index) => (
        <div className="home--row" key={index}>
          {chunk.map((item, idx) => (
            <React.Fragment key={idx}>{item}</React.Fragment>
          ))}
        </div>
      ))}
    </>
  );
};
