import React, { useState, useEffect } from "react";
import "./Channel.scss";
import Selected from "../../../img/selected_green.png";
import { connect } from "react-redux";
import { setChannel, setPayChannel, setPaymentInfo, changeMethod } from "../../../redux/actions";
import uuid from "react-uuid";
import { useTranslation } from "react-i18next";
var classNames = require("classnames");

function Channel({ data = { data: [], key: "" }, loading = () => {}, subChannel, handleError, ...props }) {
  const { t, i18n } = useTranslation();
  const [moreState, setMoreState] = useState(false);
  const [borderStatus, setBorderStatus] = useState(false);
  useEffect(() => {
    data?.data?.forEach((item) => {
      !item.logo && setBorderStatus(true);
    });
  }, []);

  const handleFail = (err, notice) => {
    handleError(err, notice);
    props.setChannel("");
    props.setPayChannel("");
  };

  const setChannelData = () => {
    props.setChannel(
      subChannel
        ? props.channelList[props.method][subChannel]?.find((item) => {
            return item?.key === data?.key;
          })
        : props.channelList[props.method]?.find((item) => {
            return item?.key === data?.key;
          })
    );
  };

  return (
    <div
      className={`channelContainer ${props.channel?.key === data?.key && "channel--border"}`}
      style={{
        border: borderStatus ? "solid 1px rgb(209, 209, 209)" : "solid 1px white",
      }}
      onClick={() => {
        if (props.method === "Wallet" || props.method === "RecurringWallet") {
          props.changeMethod(props.method, data?.data[0]?.channel, loading, handleFail, setChannelData);
        } else {
          setChannelData();
        }
        setMoreState((prev) => !prev);
      }}
      ga-data={data["ga-data"] || ""}
    >
      {!moreState &&
        data?.data?.map((item, index) => {
          if (index < 6) {
            return (
              <div
                className="channel"
                key={uuid()}
                style={{ backgroundColor: !item?.logo ? "#f1f4f8" : "transparent" }}
                ga-data={data["ga-data"] || ""}
              >
                {item?.logo ? (
                  <img
                    className={classNames("channel__image", {
                      "channel--grayscale": props.channel?.key !== data?.key,
                    })}
                    src={item?.logo}
                    alt=""
                    onLoad={() => {
                      setBorderStatus(true);
                    }}
                    ga-data={data["ga-data"] || ""}
                  />
                ) : (
                  <p className="channel__text" ga-data={data["ga-data"] || ""}>
                    {item?.bank_name}
                  </p>
                )}
              </div>
            );
          }
        })}
      {moreState &&
        data?.data?.map((item) => {
          return (
            <div
              className="channel"
              key={uuid()}
              style={{ backgroundColor: !item?.logo ? "#f1f4f8" : "transparent" }}
              ga-data={data["ga-data"] || ""}
            >
              {item?.logo ? (
                <img
                  className={classNames("channel__image", {
                    "channel--grayscale": props.channel?.key !== data?.key,
                  })}
                  src={item?.logo}
                  alt=""
                  ga-data={data["ga-data"] || ""}
                />
              ) : (
                <p className="channel__text" ga-data={data["ga-data"] || ""}>
                  {item?.bank_name}
                </p>
              )}
            </div>
          );
        })}
      {data?.data?.length > 5 && !moreState && (
        <div className="channel__more">
          <p>....</p>
        </div>
      )}
      {props.channel?.key === data?.key && <img className="channel__selected" src={Selected} alt="" />}
      {props.promotions?.[props.method]?.[data?.data[0]?.channel] && (
        <img
          className="channel__promotion"
          src={
            props.promotions?.[props.method]?.discountNotice[i18n.language] ||
            props.promotions?.[props.method]?.discountNotice.en
          }
          alt=""
        />
      )}
    </div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    channel: state.channel,
    method: state.method,
    channelList: state.channelList,
    prepayId: state.prepayId,
    tradeInfo: state.tradeInfo,
    payChannel: state.payChannel,
    promotions: state.promotions,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setChannel: (item) => dispatch(setChannel(item)),
    setPayChannel: (item) => dispatch(setPayChannel(item)),
    setPaymentInfo: (item) => dispatch(setPaymentInfo(item)),
    changeMethod: (method, channel, setIsLoading, handleError, setChannelData) =>
      dispatch(changeMethod(method, channel, setIsLoading, handleError, setChannelData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Channel);
