import Dialog from "@material-ui/core/Dialog";

function IframeHtml3DSecure({ htmlContent }) {
  return (
    <Dialog open fullScreen>
      <iframe srcDoc={htmlContent} frameborder="0" title="auth" height="100%" width="100%" />
    </Dialog>
  );
}

export default IframeHtml3DSecure;
