import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { privacyEn, privcayPt } from "./Privacy Policy/privacy";
import { termsOfServiceEn, termsOfServicePt, termsOfServiceEs } from "./Terms of Use/terms";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    // lng: "es",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          ...privacyEn,
          ...termsOfServiceEn,
          en: "English",
          es: "Español",
          pt: "Português",
          ru: "Pусский",
          ar: "عربي",
          zh: "中文",
          "zh-TW": "繁體中文",
          jp: "日本語",
          ko: "한국어",
          tr: "Türkçe",
          sbp: "Faster Payments System (SBP)",
          select_bank: "Select your bank",
          search: "Search",
          crypto_1: "Exact amount to be paid:",
          crypto_2: "Please send to address:",
          crypto_3: "or scan the QR code:",
          retail_outlet: "Retail Outlets",
          store_list: "Select a store",
          store_select: "Select a store",
          virtual_account: "Virtual Account",
          other_fees: "Other fees",
          kiosk: "Kiosk",
          bank_transfer: "Bank Transfer",
          warning: {
            crypto: {
              ETH: "Only send ETH on the ETH (ERC20) network to this address.",
              USDC: "Only send USDC on the ETH (ERC20) network to this address.",
              USDT: "Only send USDT on the ETH (ERC20) network to this address.",
              USDT_TRC20:
                "Please send USDT via the TRON Network ONLY. TRX deposit is NOT accepted. Using other networks, or top up in TRX will result in a loss of your funds.",
            },
          },
          checkout: {
            kiosk: {
              title: {
                1: "You can pay at any of these places",
                2: "How to pay?",
              },
              service: "Service",
              code: "Payment code",
              due_date: "Due date",
              amount: "Amount",
              how_to: {
                1: "Ask for Madfouaat Mutanouea Accept مدفوعات متنوعة اكسبت and provide your reference number {{reference}}",
                2: "Pay and receive your receipt",
              },
            },
            cash_bank: {
              service: "Service",
              pay_code: "Payment code",
              pay_amount: "Amount",
              choose_place: "Click on your payment of choice and follow the instructions below",
              choose_method: "Please follow the instructions listed below and make your payment",
              methods: {
                wallet: "Wallet",
                bank: "Bank",
                agent: "Agent",
                "bank-mobile": "Mobile",
                "bank-web": "PC",
              },
              paycash: {
                how_to: {
                  common: {
                    agent: {
                      1: "Go to your nearest cash payment point and mention to the cashier that you are going to make a PAYCASH payment with the code <strong>{{key}}</strong>",
                      2: "Pay and receive your receipt",
                      3: "You can click <0>here</0> to search nearest payment point.",
                    },
                  },
                  GTM: {
                    "Banco Agromercantil": {
                      agent: {
                        1: "Dirígete al punto de pago de efectivo mas cercano, mencione al cajero que vas a hacer un pago PayCash al convenio (transacción) <strong>{{code}}</strong> con el código <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Ingresar a la banca electrónica",
                        2: "BAM Virtual Personas - Pagar- Servicios - Nuevo Servicio - PayCash o convenio (transacción) <strong>{{code}}</strong>",
                        3: "Ingresa referencia y monto del pago.",
                      },
                    },
                    "Banco G&T Continental": {
                      agent: {
                        1: "Dirígete al punto de pago de efectivo mas cercano, mencione al cajero que vas a hacer un pago PayCash al convenio <strong>{{code}}</strong> con el código <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Ingresar a la banca electrónica",
                        2: "Menú - servicios PayCash o convenio <strong>{{code}}</strong>",
                        3: "Número de cuenta de debito",
                        4: "Ingresa referencia y monto del pago.",
                      },
                    },
                    Pronet: {
                      agent: {
                        1: "Dirígete al punto de pago de efectivo mas cercano, mencione al cajero que vas a hacer un pago PayCash a través de AKISI (Pronet) - Grupo de Servicios Varios con el código <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Utiliza tu usuario y contraseña en tu app AKISI",
                        2: "Ingresa en opción Servicios - Grupo de Servicios Varios - PayCash. Coloca la referencia y escribe el Alias, da clic en Guardar",
                        3: "Ingresa nuevamente a Grupo de Servicios Varios y selecciona  el pago PayCash que creaste en el paso anterior",
                        4: "En Datos, ingresa el monto a pagar y da clic en Pagar",
                        5: "Confirma el pago dando clic en Realizar transferencia",
                      },
                    },
                  },
                  PER: {
                    "Banco BCP": {
                      agent: {
                        1: "Go to your nearest BCP Agent and mention to the cashier that you are going to make a PAYCASH payment to code <strong>{{code}}</strong> and provide the <strong>last {{digit}} digits</strong> of your payment code.",
                        2: "Provide the code <strong>{{key}}</strong> and amount then complete the payment.",
                      },
                      "bank-web": {
                        1: "Enter the Services payment section and search for PayCash",
                        2: "Enter the <strong>last {{digit}} digits</strong> of the reference number and confirm the payment",
                        3: "Enter the client token and wait for confirmation of the operation",
                      },
                    },
                    Yape: {
                      "bank-mobile": {
                        1: "Enter the Services payment section and search for PayCash by name",
                        2: "Enter the <strong>last {{digit}} digits</strong> of the Payment Code and confirm the payment",
                        3: "Wait for status confirmation of the operation",
                      },
                    },
                    BBVA: {
                      agent: {
                        1: "Go to your nearest BBVA Agent and mention to the cashier that you are going to make a PAYCASH payment with the code <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Enter the Services payment section and search for PayCash",
                        2: "Enter the Payment Code <strong>{{key}}</strong> and confirm the payment",
                        3: "Confirm via Client Token and wait for status confirmation",
                      },
                    },
                    Izipay: {
                      wallet: {
                        1: "Enter the Services payment section and search for PayCash Service",
                        2: "Enter the Payment Code <strong>{{key}}</strong> and confirm the payment amount",
                        3: "Wait for status confirmation of the operation",
                      },
                    },
                    Interbank: {
                      agent: {
                        1: "Go to your nearest Interbank Agent and mention to the cashier that you are going to make a PAYCASH payment to code <strong>{{code}}</strong>",
                        2: "Provide the code <strong>{{key}}</strong> and amount then complete the payment",
                      },
                      "bank-web": {
                        1: 'Enter the "Paga o Recarga", select "Pago de Servicios" and search for PayCash',
                        2: "Enter the Payment Code <strong>{{key}}</strong> and confirm the payment amount",
                        3: "Wait for the status confirmation",
                      },
                      "bank-mobile": {
                        1: 'Enter the "Operaciones / Servicios" section and search for PayCash',
                        2: "Enter the Payment Code <strong>{{key}}</strong> and confirm the payment",
                        3: "Wait for status confirmation",
                      },
                    },
                    "Banco Pichincha": {
                      "bank-web": {
                        1: 'Enter the option "Operaciones", choose the section "Pagos" and search for "PayCash" under "Instituciones y Empresas"',
                        2: 'Enter the Payment Code <strong>{{key}}</strong> and click "search"',
                        3: "Confirm the amount and finish the payment by authorizing the operation via Token",
                        4: "Wait and make sure you get the Payment Confirmation",
                      },
                    },
                    "Banco del Comercio": {
                      agent: {
                        1: "Go to your nearest Banco del Comercio Branch and mention to the cashier that you are going to make a PAYCASH payment with the code <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: 'Enter "Pago de servicios", select "Sociedades"  and search for PayCash Service',
                        2: "Enter the Payment Code <strong>{{key}}</strong> and confirm the payment amount",
                        3: "Wait for the status confirmation",
                      },
                      "bank-mobile": {
                        1: 'Enter "Pago de servicios", select "Sociedades"  and search for PayCash Service',
                        2: "Enter the Payment Code <strong>{{key}}</strong> and confirm the payment amount",
                        3: "Wait for the status confirmation",
                      },
                    },
                    Kasnet: {
                      agent: {
                        1: "Go to your nearest Kasnet payment point and mention to the cashier that you are going to make a PAYCASH payment to convenio <strong>{{code}}</strong>",
                        2: "Provide the code <strong>{{key}}</strong> and amount then complete the payment",
                      },
                    },
                    Megapunto: {
                      agent: {
                        1: "Go to your nearest Megapunto payment point and mention to the cashier that you are going to make a PAYCASH payment through the App - Services - Paycash",
                        2: "Provide the code <strong>{{key}}</strong> and amount then complete the payment",
                      },
                    },
                  },
                  ECU: {
                    "Jardín Azuayo": {
                      "bank-web": {
                        1: "Select Services, Collections",
                        2: "Payment of basic services, taxes and catalog 2. Other collections PayCash",
                        3: "Enter reference and payment amount",
                      },
                    },
                  },
                  CRI: {
                    "Banco Nacional": {
                      agent: {
                        1: "Go to the nearest BN Servicios point and ask the cashier to make a PayCash payment with the code <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Enter the Internet Banking of Banco Nacional with your identification and password",
                        2: "Perform search by name: Paycash and enter a 16-digit reference",
                        3: "Payment is confirmed, select the payment method and wait for confirmation",
                      },
                    },
                  },
                  MEX: {
                    BWS: {
                      agent: {
                        1: "Dirígete a tu punto de pago de efectivo más cercano y menciónale al cajero que vas hacer un pago PAYCASH al servicio <strong>{{code}}</strong> con el código <strong>{{key}}</strong>",
                      },
                    },
                    Telecom: {
                      agent: {
                        1: "Dirígete a tu punto de pago de efectivo Telecomm más cercano y menciónale al cajero que vas hacer un pago PAYCASH al Convenio <strong>{{code}}</strong> con el código <strong>{{key}}</strong>",
                      },
                    },
                    Santander: {
                      agent: {
                        1: "Dirígete a la ventanilla de Banco Santander más cercana y menciónale al cajero que vas hacer un pago PAYCASH al convenio <strong>{{code}}</strong> con el código  <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Ingresar a la banca electrónica",
                        2: "Cuentas - operaciones más usadas",
                        3: "Servicios - PayCash",
                        4: "Capturar REFERENCIA e Importe",
                      },
                    },
                    BBVA: {
                      agent: {
                        1: "Dirígete a la ventanilla del BBVA más cercana y menciónale al cajero que vas hacer un pago PAYCASH al convenio <strong>{{code}}</strong> con el código <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Ingresar a la banca electrónica",
                        2: "Saldo - Pagar Servicio - Nuevo Servicio",
                        3: "Ingresar CIE1420712 y selecciona Red Efectiva",
                        4: "Capturar REFERENCIA e Importe",
                      },
                    },
                    Banorte: {
                      agent: {
                        1: "Dirígete a la ventanilla de Banorte más cercana y menciónale al cajero que vas hacer un pago PAYCASH al convenio <strong>{{code}}</strong> con el código <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Ingresar a la banca electrónica",
                        2: "Pagos y transferencias - Servicios/Impuestos",
                        3: "Selecciona PayCash o Convenio 3724",
                        4: "Capturar REFERENCIA e Importe",
                      },
                    },
                  },
                },
              },
            },
            pse: {
              company_info: "Company Information",
              business_name: "Business Name",
              trx_detail: "Transaction Details",
              trx_status: "Transaction Status",
              trx_date: "Transaction creation date",
              bank: "Bank",
              tracking_code: "Unique tracking code",
              reference: "Payment Reference (ticketID)",
              amount: "Payment Amount",
              description: "Payment Description",
              status: {
                FALLIDA: "FAILED",
                APROBADA: "APPROVED",
                RECHAZADA: "REJECTED",
                PENDIENTE: "PENDING",
              },
              alert: {
                pending:
                  "Please, verify if the debit was processed by the bank. If you require more information about your transaction, please contact our customer support email at support.latam@pagsmile.com",
              },
              error: {
                FAIL_EXCEEDEDLIMIT:
                  "The transaction amount exceeds the limits set in PSE for the company. Please contact our customer support email at support.latam@pagsmile.com for assistance",
                default:
                  "The transaction cannot be created. Please try again later or contact our customer support email at support.latam@pagsmile.com",
              },
            },
            localpayment: {
              BOL: {
                how: {
                  1: "Open your favorite banking app or digital wallet and scan the displayed QR code.",
                  2: "Complete the payment within the app and wait for the payment confirmation on the main page.",
                },
              },
            },
          },
          paycash: {
            agent: {
              title: {
                1: "You can make your payment at any of these official payment centers.",
                2: "You can make your payment at any of these commerce affiliated with Western Union.",
                3: "You can make your payment at any of these affiliated commerce.",
                4: "You can make your payment at any of these Western Union affiliated businesses or at Western Union agencies.",
              },
            },
            istructions: {
              title: {
                1: "Please follow the instructions listed below and make your payment.",
              },
            },
          },
          kushki: {
            otp: {
              title: "MOBILE VERIFICATION",
              content:
                "Please enter the 3-digit security code corresponding to the amount that we just charged to your card.",
              payment: "Payment with Kushki",
              notice: "This charge will be voided automatically",
            },
          },
          fawry: {
            cash: {
              content:
                "Use below reference number to make a payment at any FawryPay point of sale retail stores. Or pay through FawryPay app by entering reference number.",
            },
          },
          subscriptions: {
            title: "You're about to subscribe",
            with_trial:
              "The subscription's duration is <strong>{{subscription_period}}</strong>. <strong>{{trial_amount}}</strong> will be billed for the <strong>{{trial_period}}</strong> trial period, then <strong>{{interval_amount}}</strong> every <strong>{{interval_period}}</strong>.",
            without_trial:
              "The subscription's duration is <strong>{{subscription_period}}</strong>. <strong>{{interval_amount}}</strong> will be billed for every <strong>{{interval_period}}</strong>.",
          },
          times: {
            day_one: "{{count}} day",
            day_other: "{{count}} days",
            week: "{{count}} week",
            week_other: "{{count}} weeks",
            month_one: "{{count}} month",
            month_other: "{{count}} months",
            year_one: "{{count}} year",
            year_other: "{{count}} years",
          },
          fname_ph: "First Name on card",
          lname_ph: "Last Name on card",
          trustly_notice: "Your account will be credited within 1-3 business days.",
          bank_code: "Bank code:",
          ecp_notice1: "This purchase will appear in your card's statement as",
          ecp_notice2: '"SMILE.ONE PORTUGAL"',
          first_name: "First Name",
          last_name: "Last Name",
          help_contact: "For help, contact: ",
          izipay_paycash1: "Entry - Payment of services",
          izipay_paycash2: "Search for PayCash",
          izipay_paycash3: "PayCash Service",
          izipay_paycash4: "Enter payment code and amount",
          izipay_paycash5: "The application confirms the status of the operation",
          faq: "Frequently Asked Questions",
          efecty_faq1q: "What is Efecty?",
          efecty_faq1a:
            "Efecty is a company based in Colombia that offers a variety of payment methods including money orders, payments, recharges and collections nationwide. Customers can pay online and pay later at any of the 8000 service points in the country.",
          efecty_faq2q: "How can I find a place to pay in cash?",
          efecty_faq2a: "Go to https://mapapap.efecty.com.co/pagos-personas and search your address",
          efecty_faq3q: "How to know if my order was succesful?",
          efecty_faq3a:
            "The purchases are approved immediately and you will receive an email confirming your payment. If you have doubts with the purchase you are making, call us at the customer service lines.",
          khipu_faq1q: "What is Khipu?",
          khipu_faq1a:
            "Khipu is a way to receive payments with transfer that works on any device, responsively, and any browser.",
          khipu_faq2q: "How does Kihpu work?",
          khipu_faq2a:
            "Simply input your Khipu information (card number and password) and complete the transaction on your bank's website or app.",
          khipu_faq3q: "How to know if my order was succesful?",
          khipu_faq3a:
            "The purchases are approved immediately and you will receive an email confirming your payment. If you have doubts with the purchase you are making, call us at the customer service lines.",
          baloto_faq1q: "How to pay with VIA Baloto?",
          baloto_faq1a:
            "Tell the info to the cashier in the VIA Baloto point of your preference and check if everything is the same as in the receipt. This voucher is only valid for this order. If you make two payments, they will be cancelled.",
          sured_faq1q: "How to pay with Su Red?",
          sured_faq1a:
            "Tell the info to the cashier in the Su Red point of your preference and check if everything is the same as in the receipt. This voucher is only valid for this order. If you make two payments, they will be cancelled.",
          gana_faq1q: "How to pay with Gana?",
          gana_faq1a:
            "Tell the info to the cashier in the Gana point of your preference and check if everything is the same as in the receipt. This voucher is only valid for this order. If you make two payments, they will be cancelled.",
          help_chat: "Help chat",
          okay: "Okay!",
          select_notice: "Select a payment method/channel",
          instructions: "Instructions",
          videos: "Videos",
          pictures: "Pictures",
          text: "Text",
          online_inst1: "Log on to online banking as usual.",
          online_inst2: "Enter your 6 digit password.",
          online_inst3: "Select Payment and Services and companies category. Then enter in the search “SafetyPay”.",
          online_inst4:
            "Selecting this option will request the transaction number and the amount that was provided by the merchant.",
          online_inst5: "Confirm the transaction by entering your electronic key (token) and you are done!",
          payment_method: "Payment Method",
          online_banking: "Online Banking",
          cash_payments: "Cash Payments",
          bank_agent: "Choose Bank/Agent",
          filling_details: "Filling Details",
          fill_info: "Fill your info",
          details: "Details",
          name: "Name",
          email: "Email",
          TIN: "TIN (Tax ID):",
          phone: "Mobile Phone Number",
          time_remain: "Time remaining:",
          trx_id: "Transaction ID:",
          hide: "Hide",
          show: "Show",
          product_description: "Product Description:",
          order_value: "Order Value",
          fee_102: "Bank fee",
          notice_102: "",
          fee_101: "Tax",
          notice_101: "Note that based on Law No. 27,430, a {{key}} VAT will be added to the value of this purchase.",
          fee_100: "Other fees",
          notice_100: "",
          fee_104: "Country",
          notice_104:
            "According to Law 27541 and Decree 99/2019, it can include a percentage of 8% or 30% on the value of the transaction in pesos, depending on the type of goods / services purchased.",
          fee_105: "Profit",
          notice_105:
            "Note that based on General Resolution RG 4815/2020, 35% will be added for the purchase of foreign currency and 55% for other operations covered by the PAIS Tax.",
          discount: "Discount",
          total: "Total",
          confirm: "Confirm",
          return: "Return",
          privacy_policy_error:
            "You must read and accept the Terms of Use and Privacy Policy to complete your purchase.",
          privacy_policy1: "I have read and agree to the",
          privacy_policy2: " Terms of Use",
          privacy_policy3: " and",
          privacy_policy4: " Privacy Policy",
          privacy_policy5: " of Pagsmile",
          invalid_name: "Invalid name",
          invalid_TIN: "Invalid ID",
          invalid_email: "Invalid email",
          invalid_phone: "Invalid phone number",
          payment_inst_top:
            "Your order has been registered. We have registered your order and waiting for payment to start the delivery procedure. We're almost done!",
          payment_inst: "Payment Instructions:",
          payment_ticket: "Payment Ticket:",
          payment_code: "Payment Code",
          attention: "ATTENTION: Please check the updated information below.",
          cash: "Cash",
          pay: "Pay:",
          pay2: "Pay",
          processing: "Processing",
          processing_notice: "The transaction is in process, it's going to be quick. Thank you for using Pagsmile!",
          refund_in_process: "The refund is in process, it's going to be quick. Thank you for using Pagsmile!",
          success: "Payment successful",
          success_result: "Success",
          success_notice:
            "You have paid the order successfully. Please return to seller page! Thank you for using Pagsmile.",
          cancelled: "Cancelled",
          order_timeout:
            "The time limit for your payment has ended, please return back to seller page to make a new order.",
          failed: "Failed",
          copied: "Copied Successfully",
          copy: "Copy",
          visit_us: "To know more about us, visit",
          fail_notice: "It is possible that an information is wrong, try again or reach us",
          fail_query: "Do you want to try again?",
          credit_debit_card: "Credit/Debit Card",
          credit_card: "Credit Card",
          debit_card: "Debit Card",
          oxxo: "OXXO Cash Payment",
          wallet: "Wallets",
          mon_wallet: "Wallets",
          yes: "Yes, I do",
          help_me: "Help me",
          order: "Order",
          back_shop: "Back to shopping",
          cardholder_name: "Cardholder’s full name",
          card_no: "Card Number",
          invalid_card_no: "Invalid card no",
          exp_year: "Exp. Year",
          exp_date: "Expiration Date (MM/YY)",
          invalid_exp_date: "Invalid expiration date",
          invalid_year: "Select a year",
          exp_month: "Exp. Month",
          month: "month",
          invalid_month: "Select a month ",
          state: "State",
          invalid_state: "Select a state",
          city: "City",
          invalid_city: "Select a city",
          postal_code: "Postal Code",
          invalid_postal_code: "Invalid postal code",
          street: "Street",
          invalid_street: "Invalid street",
          number: "No",
          invalid_number: "Invalid number",
          source: "Website or name of the APP where you purchased the product",
          invalid_source: "Invalid",
          source_notice: "In case of incorrect filling may be failure to pay the order",
          todito_notice: "Input your Todito Cash information below:",
          "checkout_inst1.0": "Dear <0></0>, pay <1></1> to finish your purchase.",
          "checkout_inst1.1": "Dear ",
          "checkout_inst1.2": "user,",
          "checkout_inst1.3": " pay ",
          "checkout_inst1.4": " to finish your purchase.",
          "checkout_inst2.1": "Scan the QR code to go to ",
          "checkout_inst2.1.1": "Click the button to go to ",
          "checkout_inst2.2": " App and complete the payment.",
          go_to: "Go to App",
          checkout_inst3: "1. Go to the closest store",
          checkout_inst4: "2. Tell the cashier you have a Pagsmile payment and show this number: ",
          share: "Share",
          save_pdf: "Save PDF",
          checkout_inst5: "Open your bank app and input the info below",
          checkout_inst6: "Input this reference number: ",
          register_info: "Info to register account",
          installments: "Installments",
          invalid_cvv: "Invalid CVV",
          invalid_PIN: "Invalid PIN",
          invalid_NIP: "Invalid NIP",
          paid_major: "Major part paid",
          paid_minor: "Minor part paid",
          refund_verifying: "Verifying refund",
          refund_processing: "Processing refund",
          risk_control: "Controlling risks",
          refunded: "Refunded",
          partial_refund: "Partial refunded",
          chargeback: "Chargeback",
          chargeback_reversed: "Chargeback Reversed",
          dispute: "Dispute",
          dispute_reversed: "Dispute reversed",
          timeout: "Transaction timed Out",
          payment_refuse: "Payment refused",
          payment_fail: "Payment failed",
          refund_reverse: "Refund reversed",
          refund_refuse: "Refund refused",
          pay_close: "Closed",
          fail_sec: "For the security of your transaction, this order is closed. Please submit it again.",
          invalid_cep: "Invalid CEP",
          boleto_inst1: "Boleto generated!",
          boleto_inst2: "You can pay in banks, lotéricas, Correios, supermarkets or online.",
          boleto_inst3:
            "We've already sent you an email with the details and you can also save a file now by clicking on the 'Save PDF' button below.",
          deposit_upload_inst1: "Nice to have you back!",
          deposit_upload_inst2:
            "We're almost there, just fill in this short form and upload the proof of payment to finish :)",
          "deposit_upload_inst3.1": "Please fill in ",
          "deposit_upload_inst3.2": "your",
          "deposit_upload_inst3.3": " bank account details.",
          upload: "Upload",
          back: "Back",
          wallet_inst1: "Scan and pay",
          wallet_inst2: "You can scan and pay using:",
          wallet_inst3: "How to pay?",
          wallet_inst4: "1. Open your wallet/bank app on your device",
          "wallet_inst4.1": "1. Open your bank app on your device",
          wallet_inst5: "2. Tap on the “scan” feature",
          wallet_inst6: "3. Scan payment QR and complete the payment",
          wallet_inst7: "Reference number can also be used to pay.",
          wallet_mob_ins1: "Click and pay",
          wallet_mob_ins2: "You can click and pay using:",
          wallet_mob_ins3: "1. Click below button to go to wallet/bank APP on your phone",
          wallet_mob_ins4: "2. Proceed to complete the payment",
          pago_method: "Mobile banking, QR (Yape, Plin), Agents and Warehouses",
          pago_agent_title: "Agents",
          pago_agent1: "1. Indicate that you are going to make a payment to the company PAGOEFECTIVO.",
          pago_agent2: "2. Indicate that you will make the payment to PAGOEFECTIVO Soles.",
          "pago_agent3.1": "3. Indicate the CIP Code: ",
          "pago_agent3.2": " and the amount to pay.",
          pago_bank_title: "by Bank Agencies",
          pago_bank1: "1. Indicate that you are going to make a payment to the company PAGOEFECTIVO.",
          pago_bank2: "2. Indicate that you will make the payment to PAGOEFECTIVO Soles",
          "pago_bank3.1": "3. Indicate the CIP Code: ",
          "pago_bank3.2": " and the amount to pay.",
          pago_online_title: "Online Banking",
          pago_online1: "1. Select the Payment for Services option.",
          pago_online2: "2. Search and select the institution / company CASH PAYMENT.",
          pago_online3: "3. Select CASH PAYMENT Soles.",
          "pago_online4.1": "4. Enter your CIP code: ",
          "pago_online4.2": " and follow the steps.",
          clap_cash_title: "How to pay?",
          pay_cash_ins1:
            "Go to your nearest cash payment point and mention to the cashier that you are going to make a PAYCASH payment with the code ",
          pay_cashgtm_ins1:
            "Go to the nearest cash payment point, mention to the cashier that you are going to make a payment to the PayCash agreement {{key}} with the code ",
          clap_cash_ins1: "Go to your nearest Klap EFECTIVO point and request to pay with the code ",
          clap_cash_ins2: "Tell the person at the merchant that the options on the machine are 2-8-1",
          clap_cash_ins3: "Pay and receive your receipt",
          klap_store1: "Use",
          klap_store2: " this link ",
          klap_store3: "to search for your nearest Klap store",
          dexp1: "Payment is made in two steps:",
          dexp2: "1. Make a transfer, deposit or PIX",
          dexp3: "2. Send proof of payment",
          dexp4: "Bank name:",
          dexp5: "Agency",
          dexp6: "Current account:",
          dexp7: "Card holder's name:",
          dexp8: "PIX key:",
          dexp9: "Type of payment",
          dexp10: "Invalid payment type",
          dexp11: "The correct format is 4 to 6 digits, there may be '-' in the middle",
          dexp12:
            "The correct format is 5 to 12 digits, there should be a '-' in the middle, and the last digit is a number or x(X).",
          dexp13: "Upload proof of payment",
          dexp14: "Please upload a file",
          dexp15: "Transfer at the same bank",
          dexp16: "TED inter banks",
          dexp17: "Cash deposit",
          dexp18: "Envelope deposit",
          dexp19: "Send the proof",
          dexp20: "Full name",
          dexp21: "File uploaded successfully. Please wait for the processing and confirmation of your payment.",
          account: "Account",
          bank: "Bank",
          drag_notice: "You can drag and drop or click the box",
          fail_try_agin: "Something went wrong, please try again later",
          fail_change_method: "Something went wrong. Please use another payment method.",
          fail_upload_again: "Something went wrong. Please try to upload again",
          fail_upload: "Upload failed",
          timed_out: "Transaction request timed out!",
          no_channel: "No channel, please contact merchant",
          subscription: "You're subscribing",
          subscription_billed: "billed",
          subscription_every: "every",
          subscription_trial: "for the trial-period",
          subscription_then: ", then",
          trial_period: "trial-period",
          and: "and",
          day: "day",
          days: "days",
          week: "week",
          weeks: "weeks",
          months: "months",
          year: "year",
          years: "years",
          ref_no: "Reference no: ",
          agree_code: "Agreement No: ",
          id: "ID: ",
          amount: "Amount: ",
          due_date: "Due date",
          pay_points1: "You can click",
          pay_points2: " here ",
          pay_points3: "to see all payment points.",
          pay_points_search: " to search nearest payment point.",
          payvalida_notice: "Receipt {{key}} generated!",
          payvalida_notice2:
            "To make your payment, provide the following information at the {{key}} point of your choice.",
          select_country: "Please select a country first!",
          country: "Country",
          invalid_country: "Select a country",
          state_city: "State/City",
          invalid_state_city: "Invalid State/City",
          refresh: "Refresh",
          refresh_notice:
            "The page will be refreshed automatically in 10 seconds, otherwise please click on the button below.",
          download: "Download",
          network_err: "Network error, please try again.",
          code_err: "The SMS verification code is incorrect, please re-enter",
          pix_notice:
            "Please avoid using PayPal to pay your PIX order for now. Choosing to do so will greatly increase the approval time of your order.",
          address_billing: "Billing address:",
          address_delivery: "Delivery address:",
          address_same: "Same as billing address",
          process_page1: "Rest easy, you'll receive a confirmation in your email while we process your payment.",
          process_page2: "You can close this page now.",
          barcode_step1: "Go to the nearest payment point to pay with the pay code ",
          "barcode_step1.1":
            "Go to the nearest {{key}} payment point and tell the cashier you want to make a payment to PAYCASH with the code ",
          payment_point: "payment point",
          bank_list: "Bank List",
          bank_select: "Select a bank",
          agent: "Agency",
          agent_invalid: "Invalid agency",
          agent_select: "Select an agent",
          account_number: "Account Number",
          account_invalid: "Invalid account number",
          checking_account: "Checking account",
          saving_account: "Saving account",
          master_account: "Master account",
          pb_ins: "Enter the Player's Bank app, tap Pix, choose Pay QR Code and scan the code below.",
          pb_mob_ins: "Tap the button below and finalize the payment in the APP.",
          lp_ins1: "The Payment can be done by bank transfer or deposit.",
          lp_ins2: "Expiration date: ",
          lp_ins3: "Reference number: ",
          lp_ins4: "Account Information:",
          lp_ins5: "Bank name",
          account_type: "Account type",
          account_type_err: "Select an account type",
          lp_ins7: "Account number",
          lp_ins8: "Beneficiary name",
          lp_ins9: "Document type",
          lp_ins10: "Document ID",
          lp_ins11: "The reference number must be exactly the same.",
          trsf1: "Open your financial institutions app",
          trsf2: "Go to: Services > Transactions > Transfiya",
          trsf3: "Select the account you want to pay with",
          trsf4: "Pay to this account: ",
          trsf5: "Remember to transfer the exact amount of ",
          trsf6: ", otherwise the transaction will fail",
          expiry_month: "Expiry month",
          invalid_month2: "Invalid month",
          expiry_year: "Expiry year",
          invalid_year2: "Invalid year",
          spei_key: "This key can only be used once",
          spei_clabe: "Interbank's CLABE",
          spei_bank: "Destination bank",
          spei_recipient: "Recipient",
          blank: "",
          pb_qr: "Player's Bank QR Code",
          pb_card: "Player's Bank Card",
          pci_notice: "All transactions are safely guaranteed and compliant with the protocol PCI DSS.",
          "payment.redirect.notice1": "You will be redirected to the merchant's page in {{key}} seconds.",
          "payment.redirect.notice2": "Click ",
          "payment.redirect.notice3": "here",
          "payment.redirect.notice4": " to cancel.",
          service_name: "Service",
          pay_amount: "Amount",
          pay_code: "Payment code",
          "mon_bank-mobile": "Mobile",
          "mon_bank-web": "Computer",
          mon_bank: "Bank",
          mon_agent: "Store",
          mon_chooseBank: "1. Choose where you want to pay",
          mon_chooseMethod: "2. Choose payment method",
          address: "Address",
          invalid_address: "Invalid address",
          pay_or_fail: "Remember to make the payment within 15 minutes, otherwise the transaction will fail",
          ben_info: "Beneficiary information",
          bank_acc_no: "Bank account number",
          bank_acc_no_err: "Invalid bank account number",
          sec_code: "Security code",
          sec_code_err: "Invalid security code",
          cash_ins1: "Enter the payment section and search for Paycash by name",
          cash_ins2: "Enter the last {{key}} digits of the reference number and confirm the payment",
          cash_ins3: "Enter the client token and wait for confirmation of the operation",
          cash_ins4: "Enter the {{key}} digits of the reference number and confirm the payment",
          fill_all: "Please fill in all required fields",
          digital_bank1: "Select Services, Collections",
          digital_bank2: "Payment of basic services, taxes and catalog 2. Other collections PayCash",
          digital_bank3: "Enter reference and payment amount",
          bncr_paycash0:
            "Go to the nearest BN Servicios point and ask the cashier to make a PayCash payment with the code ",
          bncr_paycash1: "Enter the Internet Banking of Banco Nacional with your identification and password",
          bncr_paycash2: "Perform search by name: Paycash and enter a 16-digit reference",
          bncr_paycash3: "Payment is confirmed, select the payment method and wait for confirmation",
          digital_banking: "Digital banking",
          bncr_cash1:
            "Go to the nearest BN Servicios point and ask the cashier to make a {{key}} payment with the reference number provided above",
          bncr_cash2: "Pay and receive your voucher",
          bncr_bank1: "Enter the Internet Banking of Banco Nacional with your identification and password",
          bncr_bank2: "Perform search by name: Payválida and enter the reference number",
          bncr_bank3: "Reference will be confirmed, select the payment method and wait for confirmation",
          crypto: "Cryptocurrencies",
          choose_token: "Choose tokens",
          20000: "Service currently unavailable",
          40000: "Transaction failed",
          40002: "Transaction failed",
          40012: "Order does not exist",
          40013: "Order has ended",
          40014: "Order information is inconsistent",
          40015: "Maximum amount (including fees) exceeded",
          40101: "Order timeout",
          40201: "Failed to upload",
          40301: "Bank is busy",
          "SYS-0001":
            "Your payment has been declined. Choose another payment method. We recommend payment methods in cash.",
          "SYS-0002": "Time out",
          "SYS-0003": "Invalid transaction amount",
          "SYS-0004": "Payer must provide a valid email",
          "CC-0001": "Invalid operators users involved",
          "CC-0002": "Invalid card token",
          "CC-0003": "Pending contingency",
          "CC-0004": "Pending review manual",
          "CC-0005": "Rejected, bad filled card number",
          "CC-0006": "Rejected, bad filled date",
          "CC-0007": "Rejected, bad filled other",
          "CC-0008": "Rejected, bad filled security code",
          "CC-0009": "Rejected, in blacklist",
          "CC-0010": "Rejected, call for authorize",
          "CC-0011": "Rejected, card disabled",
          "CC-0012": "Rejected, card error",
          "CC-0013": "Rejected, duplicated payment",
          "CC-0014": "Rejected, high risk",
          "CC-0015": "Rejected, insufficient amount",
          "CC-0016": "Rejected, invalid installments",
          "CC-0017": "Rejected, max attempts",
          "CC-0018": "Rejected, other reason",
          70001: "We are unable to process your payment.",
          80004: "User is blacklisted",
          "70001-10001": "The CPF entered is invalid, enter another one",
          "70001-10002": "This transaction cannot be processed. Contact Customer Support",
          "70001-10003": "This transaction cannot be processed. Contact Customer Support",
          "70001-10004": "This transaction cannot be processed. Contact Customer Support",
          "70001-20001": "Transaction limit exceeded",
          "70001-20003": "Single transaction limit exceeded",
          "70001-99999": "This transaction cannot be processed. Contact Customer Support",
        },
      },
      es: {
        translation: {
          ...privcayPt,
          ...termsOfServiceEs,
          en: "English",
          es: "Español",
          pt: "Português",
          ru: "Pусский",
          ar: "عربي",
          zh: "中文",
          "zh-TW": "繁體中文",
          jp: "日本語",
          ko: "한국어",
          tr: "Türkçe",
          bank_transfer: "Transferencia bancaria",
          warning: {
            crypto: {
              ETH: "Envía solo ETH en la red ETH (ERC20) a esta dirección.",
              USDC: "Envía solo USDC en la red ETH (ERC20) a esta dirección.",
              USDT: "Envía solo USDT en la red ETH (ERC20) a esta dirección.",
              USDT_TRC20:
                "Envíe USDT únicamente a través de la Red TRON. El depósito de TRX NO es aceptado. Utilizar otras redes o recargar en TRX resultará en la pérdida de sus fondos.",
            },
          },
          select_bank: "Selecciona tu banco",
          search: "Buscar",
          crypto_1: "Monto exacto a pagar: ",
          crypto_2: "Por favor envíe a la dirección: ",
          crypto_3: "o escanee el código QR: ",
          other_fees: "Otros cargos",
          checkout: {
            cash_bank: {
              service: "Nombre del servicio",
              pay_code: "Codigo de pago",
              pay_amount: "Importe a pagar",
              choose_place: "Haga clic en el pago de su elección y siga las instrucciones abajo",
              choose_method: "Sigue las instrucciones listadas abajo y realiza tu pago",
              methods: {
                wallet: "Billetera Digital",
                bank: "Ventanilla",
                agent: "Agentes",
                "bank-mobile": "Banca Móvil",
                "bank-web": "Banca Web",
              },
              paycash: {
                how_to: {
                  common: {
                    agent: {
                      1: "Dirígete a tu punto de pago de efectivo más cercano y menciónale al cajero que vas hacer un pago PAYCASH con el código <strong>{{key}}</strong>",
                      2: "Paga y recibe tu comprobante",
                      3: "Puede hacer clic <0>aquí</0> para buscar el punto de pago más cercano.",
                    },
                  },
                  PER: {
                    "Banco BCP": {
                      agent: {
                        1: "Dirígete a tu Agente BCP más cercano y menciónale al cajero que vas hacer un pago PAYCASH con código <strong>{{code}}</strong> y brinda <strong> los últimos {{digit}} dígitos</strong> del código de pago. ",
                        2: "Brinda el código <strong>{{key}}</strong> y el monto para terminar el pago. ",
                      },
                      "bank-web": {
                        1: "Ingresa a sección de pagos y busca por nombre a Paycash",
                        2: "Ingresa <strong>los últimos {{digit}} dígitos</strong> del número de referencia y confirma el pago",
                        3: "Ingresa el token cliente y espera la confirmación de la operación",
                      },
                    },
                    Yape: {
                      "bank-mobile": {
                        1: "Ingresa a la opción pago de servicios y busca por nombre a PayCash",
                        2: "Ingresa <strong>los últimos {{digit}} dígitos</strong> del código de pago para confirmar el mismo",
                        3: "Espera la confirmación de la operación",
                      },
                    },
                    BBVA: {
                      agent: {
                        1: "Dirígete a tu Agente BBVA más cercano y menciónale al cajero que vas hacer un pago PAYCASH con el código <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Ingresar a la sección de pago de servicios y buscar a PayCash",
                        2: "Ingrese el código de pago <strong>{{key}}</strong>",
                        3: "Confirme el monto y finalice la operación al autorizar via Token",
                      },
                    },
                    Interbank: {
                      agent: {
                        1: "Dirígete a tu Agente Interbank más cercano y menciónale al cajero que vas hacer un pago PAYCASH con código <strong>{{code}}</strong>",
                        2: "Brinda el código <strong>{{key}}</strong> y el monto para terminar el pago.",
                      },
                      "bank-web": {
                        1: 'Ingresar a la sección "Paga o Recarga", selecciona "Pago de servicios" y busca PayCash',
                        2: "Ingrese el código de pago <strong>{{key}}</strong> y monto respectivo",
                        3: "La banca confirma el estatus de la operación",
                      },
                      "bank-mobile": {
                        1: "Ingresar a la sección Operaciones / Servicios y buscar PayCash",
                        2: "Ingrese el código de pago <strong>{{key}}</strong> y el monto",
                        3: "La banca confirma el estatus de la operación",
                      },
                    },
                    Izipay: {
                      wallet: {
                        1: "Ingresa a la opción pago de servicios y busca Servicio PayCash",
                        2: "Ingresa el código de pago <strong>{{key}}</strong> y el monto",
                        3: "La aplicación confirma el estus de la operación",
                      },
                    },
                    "Banco Pichincha": {
                      "bank-web": {
                        1: 'Ingrese en "Operaciones", y dentro de la opción "Pagos" busque "PayCash" dentro de la sección "Instituciones y Empresas"',
                        2: "Ingrese el código de pago <strong>{{key}}</strong> y selecciona buscar",
                        3: "Confirme el monto y finalice la operación al autorizar via Token",
                        4: "La banca confirma el estatus de la transacción",
                      },
                    },
                    "Banco del Comercio": {
                      agent: {
                        1: "Dirígete a tu Agente Bancario de Banco del Comercio más cercano y menciónale al cajero que vas hacer un pago PAYCASH con el código <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Ingresa a Pago de servicios, selecciona Sociedades y busca Empresa PayCash y servicio PayCash",
                        2: "Ingrese el código de pago <strong>{{key}}</strong> y monto respectivo",
                        3: "La aplicación confirma el estatus de la operación",
                      },
                      "bank-mobile": {
                        1: "Ingresa a Pago de servicios, selecciona Sociedades y busca Empresa PayCash y servicio PayCash",
                        2: "Ingrese el código de pago <strong>{{key}}</strong> y monto respectivo",
                        3: "La aplicación confirma el estatus de la operación",
                      },
                    },
                    Kasnet: {
                      agent: {
                        1: "Dirígete a tu punto de pago Kasnet más cercano y menciónale al cajero que vas hacer un pago PAYCASH al convenio <strong>{{code}}</strong>",
                        2: "Brinda el código <strong>{{key}}</strong> y el monto para terminar el pago",
                      },
                    },
                    Megapunto: {
                      agent: {
                        1: "Dirígete al Punto de Pago Megapunto más cercano y menciónale al cajero que vas hacer un pago PAYCASH mediante la App Megapunto - Servicios - Paycash",
                        2: "Brinda el código <strong>{{key}}</strong> y el monto para terminar el pago",
                      },
                    },
                  },
                  ECU: {
                    "Jardín Azuayo": {
                      "bank-web": {
                        1: "Selecciona Servicios, Recaudaciones",
                        2: "Pago de servicios básicos, impuestos y catálogo 2. Otras recaudaciones PayCash",
                        3: "Ingresa referencia y monto del pago",
                      },
                    },
                  },
                  CRI: {
                    "Banco Nacional": {
                      agent: {
                        1: "Dirígete al punto de pago BN Servicios más cercano y solicita al cajero realizar un pago PayCash con el código <strong>{{key}}</strong>",
                      },
                      "bank-web": {
                        1: "Ingresa al Internet Banking del Banco Nacional con su identificación y contraseña",
                        2: "Realiza búsqueda por nombre: Paycash e ingresa la referencia de 16 dígitos",
                        3: "Se confirma la orden, selecciona la forma de pago y espera la confirmación",
                      },
                    },
                  },
                },
              },
            },
            pse: {
              company_info: "Datos empresa",
              business_name: "Razón social",
              trx_detail: "Datos de transacción",
              trx_status: "Estado de la transacción",
              trx_date: "Fecha de creación de la transacción",
              bank: "Banco",
              tracking_code: "Código único de seguimiento (CUS)",
              reference: "Referencia de pago (ticketID)",
              amount: "Valor de pago",
              description: "Descripción del pago",
              status: {
                FALLIDA: "FALLIDA",
                APROBADA: "APROBADA",
                RECHAZADA: "RECHAZADA",
                PENDIENTE: "PENDIENTE",
              },
              alert: {
                pending:
                  "Por favor verificar si el débito fue realizado en el banco Si requiere más información sobre su transacción comuníquese a nuestro correo electrónico destinado para la atentión al cliente support.latam@pagsmile.com",
              },
              error: {
                FAIL_EXCEEDEDLIMIT:
                  "El monto de la transacción excede los límites establecidos en PSE para la empresa, por favor, comuníquese a nuestro correo electrónico destinado para la atentión al cliente support.latam@pagsmile.com",
                default:
                  "No se puede crear la transacción, por favor, intente más tarde o comuníquese a nuestro correo electrónico destinado para la atentión al cliente support.latam@pagsmile.com",
              },
            },
            localpayment: {
              BOL: {
                how: {
                  1: "Abre tu aplicación bancaria o billetera digital favorita y escanea el código QR desplegado.",
                  2: "Completa el pago dentro de la aplicación y espera por la confirmación del pago en la página principal.",
                },
              },
            },
          },
          paycash: {
            agent: {
              title: {
                1: "Puedes realizar tu pago en cualquiera de estos puntos oficiales.",
                2: "Puedes realizar tu pago en cualquiera de estos comercios afiliados a Western Union.",
                3: "Puedes realizar tu pago en cualquiera de estos comercios afiliados.",
                4: "Puedes realizar tu pago en cualquiera de estos comercios afiliados a Western Union o en agecias Western Union.",
              },
            },
            istructions: {
              title: {
                1: "Sigue las instrucciones listadas abajo y realiza tu pago.",
              },
            },
          },
          kushki: {
            otp: {
              title: "VERIFICACIÓN MANUAL",
              content: "Ingrese código de 3 dígitos de seguridad del monto que le cargamos a su tarjeta.",
              payment: "Pago con Kushki",
              notice: "Este pago será anulado automáticamente",
            },
          },
          subscriptions: {
            title: "Estás a punto de suscribirte.",
            with_trial:
              "La duración de la suscripción es <strong>{{subscription_period}}</strong>. Se facturará <strong>{{trial_amount}}</strong> por el período de prueba de <strong>{{trial_period}}</strong>, luego <strong>{{interval_amount}}</strong> cada <strong>{{interval_period}}</strong>.",
            without_trial:
              "La duración de la suscripción es <strong>{{subscription_period}}</strong>. Se facturará <strong>{{interval_amount}}</strong> cada <strong>{{interval_period}}</strong>.",
          },
          times: {
            day_one: "{{count}} día",
            day_other: "{{count}} días",
            week: "{{count}} semana",
            week_other: "{{count}} semanas",
            month_one: "{{count}} mes",
            month_other: "{{count}} meses",
            year_one: "{{count}} año",
            year_other: "{{count}} años",
          },
          bank_code: "Codigo bancario:",
          faq: "Preguntas Frecuentes",
          izipay_paycash1: "Ingresa - Pago de servicios",
          izipay_paycash2: "Buscar PayCash",
          izipay_paycash3: "Servicio PayCash",
          izipay_paycash4: "Ingresa codigo de pago y monto",
          izipay_paycash5: "La aplicación confirma el estatus de la operación",
          efecty_faq1q: "¿Qué es Efecty?",
          efecty_faq1a:
            "Efecty es una empresa con sede en Colombia que ofrece una variedad de métodos de pago que incluyen giros postales, pagos, recargas y cobros en todo el país. Los clientes pueden pagar en línea y pagar más tarde en cualquiera de los 8000 puntos de servicio en el país.",
          efecty_faq2q: "¿Cómo puedo encontrar un sitio para pagar en efectivo?",
          efecty_faq2a: "Ve a https://mapapap.efecty.com.co/pagos-personas y busca tu dirección",
          efecty_faq3q: "¿Cómo saber si mi pedido fue exitoso?",
          efecty_faq3a:
            "Las compras se aprueban inmediatamente y recibirá un correo electrónico confirmando su pago.  Si tienes dudas con la compra que estas realizando llámanos en las líneas de atendimento al cliente.",
          khipu_faq1q: "¿Qué es Khipu?",
          khipu_faq1a:
            "Khipu es una manera de recibir pagos con transferencia que funciona en cualquier dispositivo, de manera responsiva, y cualquier navegador.",
          khipu_faq2q: "¿Cómo funciona?",
          khipu_faq2a:
            "Simplemente ingrese su información de Khipu (número de tarjeta y contraseña) y complete la transacción en el sitio web o la aplicación de su banco.",
          khipu_faq3q: "¿Cómo saber si mi pedido fue exitoso?",
          khipu_faq3a:
            "Las compras se aprueban inmediatamente y recibirá un correo electrónico confirmando su pago.  Si tienes dudas con la compra que estas realizando llámanos en las líneas de atendimento al cliente.",
          baloto_faq1q: "¿Cómo pagar con VIA Baloto?",
          baloto_faq1a:
            "Menciona los datos al cajero en el punto VIA Baloto de tu preferencia y verifica que la información coincida con el recibo. Este cupón es válido para este pedido. Si realizas dos pagos, se cancelarán.",
          sured_faq1q: "¿Cómo pagar con Su Red?",
          sured_faq1a:
            "Menciona los datos al cajero en el punto Su Red de tu preferencia y verifica que la información coincida con el recibo. Este cupón es válido para este pedido. Si realizas dos pagos, se cancelarán.",
          gana_faq1q: "¿Cómo pagar con Gana?",
          gana_faq1a:
            "Menciona los datos al cajero en el punto Gana de tu preferencia y verifica que la información coincida con el recibo. Este cupón es válido para este pedido. Si realizas dos pagos, se cancelarán.",
          help_chat: "Chat de ayuda",
          okay: "Okay!",
          select_notice: "Escoger banco o agente",
          instructions: "Instruciones",
          videos: "Videos",
          pictures: "Fotos",
          text: "Texto",
          online_inst1: "Log on to online banking as usual.",
          online_inst2: "Enter your 6 digit password.",
          online_inst3: "Select Payment and Services and companies category. Then enter in the search “SafetyPay”.",
          online_inst4:
            "Selecting this option will request the transaction number and the amount that was provided by the merchant.",
          online_inst5: "Confirm the transaction by entering your electronic key (token) and you are done!",
          payment_method: "Método de pago",
          online_banking: "Banco en línea",
          cash_payments: "Pago en efectivo",
          bank_agent: "Escoger banco o agente",
          filling_details: "Detalles personales",
          fill_info: "Completa tu información",
          details: "Detalles",
          name: "Nombre",
          email: "Correo electrónico",
          TIN: "TIN (Tax ID)",
          cash: "Dinero",
          phone: "Número de teléfono móvil",
          time_remain: "Tiempo restante:",
          trx_id: "ID de la transacción:",
          hide: "Ocultar",
          show: "Mostrar",
          confirm: "Confirmar",
          return: "Return",
          attention: "ATENCIÓN: Por favor, consulta la siguiente información.",
          product_description: "Descripción del producto",
          order_value: "Valor del pedido",
          fee_102: "Comisión Bancaria",
          notice_102: "",
          fee_101: "Impuestos",
          notice_101: "Note que en base a la Ley N 27.430 se agregará un {{key}} de IVA al valor de esta compra.",
          fee_100: "Otras Tarifas",
          notice_100: "",
          fee_104: "Pais",
          notice_104:
            "Según la ley 27541 y el Decreto 99/2019, puede incluir un porcentaje de 8% o 30% sobre el valor de la transacción en pesos, dependiendo del tipo de bienes / servicios adquiridos.",
          fee_105: "Ganancias",
          notice_105:
            "Note que en base a la General Resolution RG 4815/2020 se agregará un 35% de Ganancias al valor de esta compra o un 55% si la operación es alcanzada por el Impuesto PAIS.",
          discount: "Descuento",
          total: "Total",
          privacy_policy_error:
            "Debe leer y aceptar los Términos de uso y la Política de privacidad para completar su compra.",
          privacy_policy1: "He leído y acepto los",
          privacy_policy2: " Términos de Uso",
          privacy_policy3: " y la",
          privacy_policy4: " Política de Privacidad",
          privacy_policy5: " de Pagsmile.",
          invalid_name: "Nombre inválido",
          invalid_TIN: "ID inválido",
          invalid_email: "Correo electrónico inválido",
          invalid_phone: "Número de teléfono inválido",
          payment_inst: "Instrucciones de pago:",
          payment_inst_top:
            "Registramos su pedido y estamos esperando el pago para iniciar la entrega. Ya casi terminamos!",
          payment_ticket: "Tíquete de pago",
          payment_code: "Código de pago",
          pay: "Pagar:",
          pay2: "Pagar",
          processing: "Procesando",
          processing_notice: "La transacción está en proceso, solo se queda un rato. ¡Gracias por usar Pagsmile!",
          refund_in_process: "El reembolso está en proceso, solo se queda un rato. ¡Gracias por usar Pagsmile!",
          success: "Pago exitoso",
          success_result: "éxito",
          success_notice:
            "Has pagado el pedido correctamente. Si quieras, regrese a la página del vendedor. ¡Gracias por usar Pagsmile!",
          cancelled: "Cancelado",
          order_timeout:
            "El límite de tiempo para su pago ha finalizado, vuelva a la página del vendedor para realizar un nuevo pedido.",
          failed: "Falla",
          copied: "Copiado con éxito",
          copy: "Copiar",
          visit_us: "Para saber mas sobre nosotros, visite",
          fail_notice:
            "Es posible que alguna información este incorrecta, intenta nuevamente o comunícate con nosotros",
          fail_query: "Quieres intentar de nuevo?",
          yes: "Sí, quiero",
          help_me: "Ayúdame",
          order: "Pedido",
          back_shop: "Volver a las compras",
          credit_card: "Tarjeta de crédito",
          credit_debit_card: "Tarjeta de crédito/débito",
          debit_card: "Tarjeta de débito",
          oxxo: "Pago en efectivo OXXO",
          wallet: "Billetera Digital",
          mon_wallet: "Billetera Digital",
          cardholder_name: "Nombre completo del titular de la tarjeta",
          card_no: "Número de tarjeta",
          invalid_card_no: "Numero de tarjeta invalido",
          exp_year: "Año de venc.",
          exp_date: "Fecha de caducidad (MM/YY)",
          invalid_exp_date: "Fecha de caducidad invalido",
          invalid_year: "Seleccione un año",
          exp_month: "Mes de venc.",
          month: "mes",
          invalid_month: "Selecciona un mes",
          state: "Estado",
          invalid_state: "Selecciona un Estado",
          city: "Ciudad",
          invalid_city: "Seleccionar una ciudad",
          postal_code: "Código postal",
          invalid_postal_code: "Código postal invalido",
          street: "Calle",
          invalid_street: "Calle invalido",
          number: "Número de Calle",
          invalid_number: "Número invalido",
          source: "Sitio web o nombre de la APP donde compró el producto",
          invalid_source: "Invalido",
          source_notice: "Si la información ingresada es incorrecta, el pago de su pedido podría fallar",
          todito_notice: "Ingrese su información de Todito Cash a continuación:",
          "checkout_inst1.0": "Estimado <0></0>, pague <1></1> para finalizar su compra.",
          "checkout_inst1.1": "Estimado ",
          "checkout_inst1.2": "usuario,",
          "checkout_inst1.3": " pague ",
          "checkout_inst1.4": " para finalizar su compra.",
          "checkout_inst2.1": "Escanee el código QR para ir a la ",
          "checkout_inst2.1.1": "Haga clic en el botón para ir a la ",
          "checkout_inst2.2": " APP y complete el pago.",
          go_to: "Ir a App",
          checkout_inst3: "1. Ve a la tienda más cercana",
          checkout_inst4: "2. Dígale al cajero que tiene un pago de Pagsmile y muestre este número: ",
          share: "Compartir",
          save_pdf: "Guardar PDF",
          checkout_inst5: "Abra su aplicación bancaria e ingrese la información a continuación",
          checkout_inst6: "Ingrese este número de referencia: ",
          register_info: "Información para registrar la cuenta",
          installments: "Pago a plazos",
          invalid_cvv: "CVV invalido",
          invalid_PIN: "PIN invalido",
          invalid_NIP: "NIP invalido",
          paid_major: "La mayor parte pagada",
          paid_minor: "Parte menor pagada",
          refund_verifying: "Verificando reembolso",
          refund_processing: "Procesando reembolso",
          risk_control: "Control de riesgos",
          refunded: "Reembolsado",
          partial_refund: "Parcialmente reembolsado",
          chargeback: "Contracargo",
          chargeback_reversed: "Contracargo invertido",
          dispute: "Disputa",
          dispute_reversed: "Disputa revertida",
          timeout: "Transacción agotada",
          payment_refuse: "Pago rechazado",
          payment_fail: "Pago fallido",
          refund_reverse: "Reembolso revertido",
          refund_refuse: "Reembolso rechazado",
          pay_close: "Cerrado",
          fail_sec: "Para la seguridad de su transacción, el pedido fue cerrado. Por favor, vuelva a enviarlo",
          invalid_cep: "CEP invalido",
          boleto_inst1: "¡Boleto generado!",
          boleto_inst2: "Puedes pagar en bancos, lotéricas, correos, supermercados o en línea",
          boleto_inst3:
            "Ya hemos enviado un correo electrónico con los detalles. Para descargar el archivo haga clic en el botón 'Guardar PDF'",
          deposit_upload_inst1: "¡Encantado de tenerte de vuelta!",
          deposit_upload_inst2:
            "Ya casi finalizamos, completa este breve formulario y cargue el comprobante de pago para terminar :)",
          "deposit_upload_inst3.1": "Ingrese ",
          "deposit_upload_inst3.2": "sus",
          "deposit_upload_inst3.3": " datos bancarios.",
          upload: "Subir",
          back: "Volver",
          wallet_inst1: "Escanear y pagar", // Spanish
          wallet_inst2: "Puedes escanear y pagar usando:", // Spanish
          wallet_inst3: "¿Cómo pagar?", // Spanish
          wallet_inst4: "1. Abre tu aplicación de cartera/banco en tu dispositivo", // Spanish
          "wallet_inst4.1": "1. Abre tu aplicación bancaria en tu dispositivo", // Spanish
          wallet_inst5: "2. Toca la función de “escanear”", // Spanish
          wallet_inst6: "3. Escanea el código QR de pago y completa el pago", // Spanish
          wallet_inst7: "También se puede usar el número de referencia para pagar.", // Spanish
          wallet_mob_ins1: "Haz clic y paga", // Spanish
          wallet_mob_ins2: "Puedes hacer clic y pagar usando:", // Spanish
          wallet_mob_ins3: "1. Haz clic en el botón de abajo para ir a la APP de cartera/banco en tu teléfono", // Spanish
          wallet_mob_ins4: "2. Procede a completar el pago", // Spanish
          pago_method: "Banca móvil, QR (Yape, Plin), Agentes y Bodegas",
          pago_agent_title: "Agentes",
          pago_agent1: "1. Indica que vas realizar un pago a la empresa PAGOEFECTIVO.",
          pago_agent2: "2. Indica que realizarás el pago a PAGOEFECTIVO Soles.",
          "pago_agent3.1": "3. Indica el Código CIP: ",
          "pago_agent3.2": " y el importe a pagar.",
          pago_bank_title: "por Agencias Bancarias",
          pago_bank1: "1. Indica que vas realizar un pago a la empresa PAGOEFECTIVO.",
          pago_bank2: "2. Indica que realizarás el pago a PAGOEFECTIVO Soles",
          "pago_bank3.1": "3. Indica el Código CIP: ",
          "pago_bank3.2": " y el importe a pagar.",
          pago_online_title: "Pago vía Banca Móvil y por Internet",
          pago_online1: "1. Selecciona la opción Pago de Servicios.",
          pago_online2: "2. Busca y selecciona la institución/empresa PAGOEFECTIVO.",
          pago_online3: "3. Selecciona PAGOEFECTIVO Soles.",
          "pago_online4.1": "4. Ingresa tu código CIP: ",
          "pago_online4.2": " y sigue los pasos.",
          clap_cash_title: "¿Cómo pagar?",
          pay_cash_ins1:
            "Dirígete a tu punto de pago de efectivo más cercano y menciónale al cajero que vas hacer un pago PAYCASH con el código ",
          pay_cashgtm_ins1:
            "Dirígete al punto de pago de efectivo mas cercano, mencione al cajero que vas a hacer un pago PayCash al convenio {{key}} con el código ",
          clap_cash_ins1: "Dirígete a tu punto Klap EFECTIVO más cercano y solicita pagar con el código ",
          clap_cash_ins2: "Indícale a la persona del comercio que las opciones en la máquina son 2-8-1",
          clap_cash_ins3: "Paga y recibe tu comprobante",
          klap_store1: "Use",
          klap_store2: " este link ",
          klap_store3: "para buscar su tienda Klap más cercana",
          dexp1: "El pago se realiza en dos pasos:",
          dexp2: "1. Haz una transferencia, depósito o PIX",
          dexp3: "2. Ingrese el comprobante de pago",
          dexp4: "Nombre del banco:",
          dexp5: "Agencia",
          dexp6: "Cuenta corriente:",
          dexp7: "Titular:",
          dexp8: "Chave PIX:",
          dexp9: "Tipo de pago",
          dexp10: "Tipo de pago no válido",
          dexp11: "Longitud de 4 a 6 digitos, puede haber - en el medio",
          dexp12:
            "El formato correcto es de 5 a 12 dígitos. Comienza con un número, debe haber un - en el medio y el último dígito es un número o una x(X).",
          dexp13: "Subir comprobante de pago",
          dexp14: "Sube un archivo porfa",
          dexp15: "Transferencia entre el mismo banco",
          dexp16: "TED entre bancos",
          dexp17: "Depósito en efectivo",
          dexp18: "Depósito de sobres",
          dexp19: "Enviar prueba",
          dexp20: "Nombre completo",
          dexp21: "Documento cargado exitosamente. Espere el procesamiento y la confirmación de su pago.",
          account: "Cuenta",
          bank: "Banco",
          drag_notice: "Puedes arrastrar y soltar o hacer clic en el cuadro",
          fail_try_agin: "Algo salió mal. Vuelve a intentarlo más tarde.",
          fail_change_method: "Algo salió mal. Utilice otro método de pago.",
          fail_upload_again: "Algo salió mal. Intente cargar de nuevo",
          fail_upload: "Subida fallida",
          timed_out: "¡Se agotó el tiempo de espera de la solicitud de transacción!",
          no_channel: "No canal, contacte el comercio",
          subscription: "Te estás suscribiendo",
          subscription_billed: "se facturará",
          subscription_every: "cada",
          subscription_trial: "por período de prueba",
          subscription_then: ", después",
          trial_period: "periodo de prueba ",
          and: "y",
          day: "día",
          days: "días",
          week: "semana",
          weeks: "semanas",
          months: "meses",
          year: "año",
          years: "años",
          ref_no: "No de referencia: ",
          agree_code: "Convenio: ",
          id: "Cedula de identidad: ",
          amount: "Total a pagar: ",
          due_date: "Fecha de vencimiento",
          pay_points1: "Puede hacer clic",
          pay_points2: " aquí ",
          pay_points3: "para ver todos los puntos de pago.",
          pay_points_search: " para buscar el punto de pago más cercano.",
          payvalida_notice: "Recibo {{key}} generado!",
          payvalida_notice2: "Para realizar tu pago informa los siguientes dato en el punto {{key}} de tu preferencia:",
          select_country: "¡Por favor seleccione un país primero!",
          country: "País",
          invalid_country: "Seleccione un país",
          state_city: "Estado/Ciudad",
          invalid_state_city: "Estado/Ciudad inválido",
          refresh: "Recargar",
          refresh_notice:
            "La página se actualizará automáticamente en 10 segundos, de lo contrario, haz clic en el botón de abajo.",
          download: "Descargar",
          network_err: "Error de red, por favor, inténtelo de nuevo.",
          pix_notice:
            "Por favor evite usar PayPal para pagar su pedido de PIX por ahora. Elegir hacerlo aumentará en gran medida el tiempo de aprobación de su pedido.",
          address_billing: "Dirección de envio:",
          address_delivery: "Dirección de entrega:",
          address_same: "Igual a la dirección de facturación",
          process_page1:
            "Listo, recibirás una confirmación de pago en tu correo electrónico mientras procesamos tu pago.",
          process_page2: "Puede cerrar esta página ahora.",
          barcode_step1:
            "Dirígete a tu punto de pago de efectivo más cercano y menciónale al cajero que vas hacer un pago con el código ",
          "barcode_step1.1":
            "Dirígete a tu punto de {{key}} más cercano y menciónale al cajero que vas hacer un pago PAYCASH con el código ",
          payment_point: "punto de pago de efectivo",
          bank_list: "Lista de bancos",
          bank_select: "Seleccione un banco",
          agent: "Agencia",
          agent_invalid: "Agencia inválida",
          agent_select: "Seleccionar un agente",
          account_number: "Número de cuenta",
          account_invalid: "Número de cuenta inválido",
          checking_account: "Cuenta corriente",
          saving_account: "Cuenta de ahorros",
          master_account: "Cuenta administradora",
          pb_ins:
            "Ingrese a la aplicación Player's Bank, toque Pix, elija Pay QR Code y escanee el código a continuación.",
          pb_mob_ins: "Toca el botón de abajo y finaliza el pago en el APP.",
          lp_ins1: "El pago se puede realizar mediante transferencia o depósito bancario.",
          lp_ins2: "Fecha de caducidad: ",
          lp_ins3: "Número de referencia: ",
          lp_ins4: "Información de la cuenta:",
          lp_ins5: "Nombre del banco",
          account_type: "Tipo de cuenta",
          account_type_err: "Seleccione un tipo de cuenta",
          lp_ins7: "Número de cuenta",
          lp_ins8: "Nombre del Beneficiario",
          lp_ins9: "Tipo de Documento",
          lp_ins10: "Identificación del documento",
          lp_ins11: "El número de referencia debe ser exactamente el mismo.",
          trsf1: "Abra la aplicación de su institución financiera",
          trsf2: "Vaya a: Servicios > Transacciones > Transfiya",
          trsf3: "Seleccione la cuenta con la que desea pagar",
          trsf4: "Paga a esta cuenta: ",
          trsf5: "Recuerda transferir el monto exacto de ",
          trsf6: ", de lo contrario la transacción fallará",
          spei_key: "Esta clave solo se puede usar una vez",
          spei_clabe: "CLABE interbancária",
          spei_bank: "Banco de destino",
          spei_recipient: "Beneficiário",
          pb_qr: "Código QR Player's Bank",
          pb_card: "Tarjeta Player's Bank",
          pci_notice: "Todas las transacciones están garantizadas de forma segura y cumplen con el protocolo PCI DSS.",
          "payment.redirect.notice1": "Usted será redireccionado a la página del comercio en {{key}} segundos.",
          "payment.redirect.notice2": "Haga click ",
          "payment.redirect.notice3": "aquí para",
          "payment.redirect.notice4": " cancelar.",
          service_name: "Nombre del servicio",
          pay_amount: "Importe a pagar",
          pay_code: "Codigo de pago",
          "mon_bank-mobile": "Banca Móvil",
          "mon_bank-web": "Banca Web",
          mon_bank: "Ventanilla",
          mon_agent: "Agentes",
          mon_chooseBank: "1. Escoge el banco o agente",
          mon_chooseMethod: "2. Escoge el método de pago",
          address: "Dirección",
          invalid_address: "Dirección inválida",
          pay_or_fail: "Recuerda realizar el pago en 15 min, de lo contrario la transacción fallará",
          ben_info: "Información de beneficiario",
          bank_acc_no: "Número de cuenta bancaria",
          bank_acc_no_err: "Número de cuenta bancaria inválido",
          sec_code: "Código de seguridad",
          sec_code_err: "Código de seguridad inválido",
          cash_ins1: "Ingresa a sección de pagos y busca por nombre a Paycash",
          cash_ins2: "Ingresa los últimos {{key}} dígitos del número de referencia y confirma el pago",
          cash_ins3: "Ingresa el token cliente y espera la confirmación de la operación",
          cash_ins4: "Ingresa los {{key}} dígitos del número de referencia y confirma el pago",
          fill_all: "Llene todas las informaciones",
          digital_bank1: "Selecciona Servicios, Recaudaciones",
          digital_bank2: "Pago de servicios básicos, impuestos y catálogo 2. Otras recaudaciones PayCash",
          digital_bank3: "Ingresa referencia y monto del pago",
          bncr_paycash0:
            "Dirígete al punto de pago BN Servicios más cercano y solicita al cajero realizar un pago PayCash con el código ",
          bncr_paycash1: "Ingresa al Internet Banking del Banco Nacional con su identificación y contraseña",
          bncr_paycash2: "Realiza búsqueda por nombre: Paycash e ingresa la referencia de 16 dígitos",
          bncr_paycash3: "Se confirma la orden, selecciona la forma de pago y espera la confirmación",
          digital_banking: "Banca Digital",
          bncr_cash1:
            "Dirígete al punto de pago BN Servicios más cercano y solicita al cajero realizar un pago {{key}} con el No. de referencia brindado arriba",
          bncr_cash2: "Paga y recibe tu comprobante",
          bncr_bank1: "Ingresa al Internet Banking del Banco Nacional con su identificación y contraseña",
          bncr_bank2: "Realiza búsqueda por nombre: Payválida e ingresa el no. de referencia",
          bncr_bank3: "Se confirma la orden, selecciona la forma de pago y espera la confirmación",
          crypto: "Criptomonedas",
          choose_token: "Selecionar tokens",
          20000: "Servicio actualmente no disponible",
          40000: "Transacción falló",
          40002: "Transacción falló",
          40012: "El orden no existe",
          40013: "Orden finalizada",
          40014: "La información del pedido es inconsistente",
          40015: "Se superó el valor máximo (impuestos incluidos)",
          40101: "Tiempo limite de la orden",
          40201: "Falló al cargar",
          40301: "El banco está ocupado",
          "SYS-0001": "Su pago fue rechazado. Elija otro método de pago. Recomendamos métodos de pago en efectivo.",
          "SYS-0002": "Para la seguridad de su transacción, el pedido está cerrado, vuelva a enviarlo",
          "SYS-0003": "Elija el valor más alto",
          "SYS-0004": "Proporcione la dirección de correo electrónico válida.",
          "CC-0001": "Pago denegado. El nombre y el CPF no coinciden. Ingrese la información correcta.",
          "CC-0002": "Token de tarjeta no válido",
          "CC-0003":
            "Estamos procesando el pago. Dentro de 2 días hábiles te informaremos por correo electrónico del resultado.",
          "CC-0004":
            "Estamos procesando el pago. Dentro de 2 días hábiles te informaremos por correo electrónico si ha sido aprobado o si necesitamos más información.",
          "CC-0005": "Verifique el número de tarjeta.",
          "CC-0006": "Verifique la fecha de vencimiento",
          "CC-0007": "Verifique los datos.",
          "CC-0008": "Verifique el código de seguridad.",
          "CC-0009": "No pudimos procesar su pago.",
          "CC-0010": "Debe autorizar a la Tarjeta a pagar el monto a Pagsmile.",
          "CC-0011": "Tarjeta de llamada para activar su tarjeta. El teléfono está en el reverso de su tarjeta.",
          "CC-0012": "No pudimos procesar su pago.",
          "CC-0013":
            "Ya realizó un pago con esta cantidad. Si necesita pagar nuevamente, use otra tarjeta u otra forma de pago",
          "CC-0014": "Su pago ha sido rechazado. Elija otro método de pago. Recomendamos métodos de pago en efectivo.",
          "CC-0015": "La tarjeta tiene saldo insuficiente.",
          "CC-0016": "La Tarjeta no procesa pagos en cuotas",
          "CC-0017": "Ha alcanzado el límite de intentos permitido. Elija otra tarjeta u otra forma de pago.",
          "CC-0018": "La tarjeta no procesó su pago.",
          70001: "No podemos procesar su pago.",
          80004: "El usuario está en la lista negra",
          "70001-10001": "El CPF ingresado no es válido, ingrese otro",
          "70001-10002": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
          "70001-10003": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
          "70001-10004": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
          "70001-20001": "Superado el límite de transacciones",
          "70001-20003": "Se superó el límite de transacción única",
          "70001-99999": "Esta operación no se puede procesar. Póngase en contacto con atención al cliente",
        },
      },
      pt: {
        translation: {
          ...termsOfServicePt,
          ...privcayPt,
          en: "English",
          es: "Español",
          pt: "Português",
          ru: "Pусский",
          ar: "عربي",
          zh: "中文",
          "zh-TW": "繁體中文",
          jp: "日本語",
          ko: "한국어",
          tr: "Türkçe",
          bank_transfer: "Transferência bancária",
          warning: {
            crypto: {
              ETH: "Envie apenas ETH na rede ETH (ERC20) para este endereço.",
              USDC: "Envie apenas USDC na rede ETH (ERC20) para este endereço.",
              USDT: "Envie apenas USDT na rede ETH (ERC20) para este endereço.",
              USDT_TRC20:
                "Envie USDT apenas pela Rede TRON. Depósitos em TRX NÃO são aceitos. Usar outras redes ou recarregar em TRX resultará na perda dos seus fundos.",
            },
          },
          subscriptions: {
            title: "Você está prestes a se inscrever.",
            with_trial:
              "A duração da assinatura é <strong>{{subscription_period}}</strong>. Será cobrado um valor de <strong>{{trial_amount}}</strong> pelo período de teste de <strong>{{trial_period}}</strong>, depois <strong>{{interval_amount}}</strong> a cada <strong>{{interval_period}}</strong>.",
            without_trial:
              "A duração da assinatura é <strong>{{subscription_period}}</strong>. Será cobrado um valor de <strong>{{interval_amount}}</strong> a cada <strong>{{interval_period}}</strong>.",
          },
          times: {
            day_one: "{{count}} dia",
            day_other: "{{count}} dias",
            week: "{{count}} semana",
            week_other: "{{count}} semanas",
            month_one: "{{count}} mês",
            month_other: "{{count}} meses",
            year_one: "{{count}} ano",
            year_other: "{{count}} anos",
          },
          other_fees: "Outras taxas",
          select_bank: "Selecione seu banco",
          search: "Pesquisar",
          crypto_1: "Valor exato a pagar: ",
          crypto_2: "Por favor, envie para o endereço: ",
          crypto_3: "ou escaneie o código QR: ",
          bank_code: "Código do banco:",
          faq: "Perguntas Frequentes",
          efecty_faq1q: "O que é Efecty?",
          efecty_faq1a:
            "Efecty é uma empresa com sede na Colômbia que oferece uma variedade de métodos de pagamento, incluindo pedidos de dinheiro, pagamentos, recargas e cobranças em todo o país. Os clientes podem pagar online e pagar mais tarde em qualquer um dos 8000 pontos de atendimento no país.",
          efecty_faq2q: "Como posso encontrar um lugar para pagar em dinheiro?",
          efecty_faq2a: "Vá para https://mapapap.efecty.com.co/pagos-personas e pesquise seu endereço",
          efecty_faq3q: "Como saber se meu pedido foi bem sucedido?",
          efecty_faq3a:
            "As compras são aprovadas imediatamente e você vai receber um email confirmando seu pagamento.  Se você tiver dúvidas sobre a compra que está fazendo, ligue para nós nas linhas de atendimento ao cliente.",
          khipu_faq1q: "O que é Khipu?",
          khipu_faq1a:
            "Khipu é uma maneira de receber pagamentos com transferência que funciona em qualquer dispositivo, responsavelmente, e em qualquer navegador.",
          khipu_faq2q: "Como funciona?",
          khipu_faq2a:
            "Basta inserir suas informações khipu (número do cartão e senha) e concluir a transação no site ou aplicativo do seu banco.",
          khipu_faq3q: "Como saber se meu pedido foi bem sucedido?",
          khipu_faq3a:
            "As compras são aprovadas imediatamente e você vai receber um email confirmando seu pagamento.  Se você tiver dúvidas sobre a compra que está fazendo, ligue para nós nas linhas de atendimento ao cliente.",
          baloto_faq1q: "¿Cómo pagar con VIA Baloto?",
          baloto_faq1a:
            "Dígale los datos al cajero en el punto VIA Baloto de su preferencia y verifique si todo está igual que en el recibo. Este cupón solo es válido para este pedido. Si realiza dos pagos, se cancelarán.",
          sured_faq1q: "¿Cómo pagar con Su Red?",
          sured_faq1a:
            "Dígale los datos al cajero en el punto Su Red de su preferencia y verifique si todo está igual que en el recibo. Este cupón solo es válido para este pedido. Si realiza dos pagos, se cancelarán.",
          gana_faq1q: "¿Cómo pagar con Gana?",
          gana_faq1a:
            "Dígale los datos al cajero en el punto Gana de su preferencia y verifique si todo está igual que en el recibo. Este cupón solo es válido para este pedido. Si realiza dos pagos, se cancelarán.",
          help_chat: "Chat de ajuda",
          okay: "Certo!",
          select_notice: "Selecionar método de pagamento",
          instructions: "Instruções",
          videos: "Videos",
          pictures: "Fotos",
          text: "Texto",
          online_inst1: "Log on to online banking as usual.",
          online_inst2: "Enter your 6 digit password.",
          online_inst3: "Select Payment and Services and companies category. Then enter in the search “SafetyPay”.",
          online_inst4:
            "Selecting this option will request the transaction number and the amount that was provided by the merchant.",
          online_inst5: "Confirm the transaction by entering your electronic key (token) and you are done!",
          payment_method: "Método de pagamento",
          online_banking: "Online Banking",
          cash_payments: "Pagamento em dinheiro",
          bank_agent: "Escolha seu banco ou agente",
          filling_details: "Detalhes pessoais",
          fill_info: "Preencha as informações",
          details: "Detalhes",
          name: "Nome",
          email: "Email",
          TIN: "CPF",
          phone: "Número de celular",
          time_remain: "Tempo restante:",
          trx_id: "ID da transação",
          hide: "Esconder",
          cash: "Dinheiro",
          show: "Mostrar",
          product_description: "Descrição do produto",
          order_value: "Valor do pedido",
          fee_102: "Taxa Bancária",
          notice_102: "",
          fee_101: "Impostos",
          notice_101: "Note que com base na Lei nº 27.430, ao valor desta compra será acrescido um IVA de {{key}}.",
          fee_100: "Outras taxas",
          notice_100: "",
          fee_104: "Lucro",
          notice_104:
            "De acordo com a Lei 27.541 e o Decreto 99/2019, pode incluir um percentual de 8% ou 30% sobre o valor da transação em pesos, dependendo do tipo de bens/serviços adquiridos.",
          fee_105: "Ganancias",
          notice_105:
            "Observe que com base na Resolução Geral RG 4815/2020, serão acrescidos 35% para a compra de moeda estrangeira e 55% para as demais operações abrangidas pelo PAIS.",
          discount: "Desconto",
          confirm: "Confirmar",
          return: "Return",
          attention: "ATENÇÃO: Por favor veja as informações abaixo.",
          total: "Total",
          privacy_policy_error:
            "Você deve ler e aceitar os Termos de Uso e a Política de Privacidade para concluir sua compra.",
          privacy_policy1: "Li e concordo com os",
          privacy_policy2: " Termos de Uso",
          privacy_policy3: " e",
          privacy_policy4: " Política de Privacidade",
          privacy_policy5: " do Pagsmile.",
          invalid_name: "Nome inválido",
          invalid_TIN: "ID inválido",
          invalid_email: "Email inválido",
          invalid_phone: "Número de telefone inválido",
          payment_inst: "Instruções de pagamento:",
          payment_inst_top:
            "Registramos seu pedido e estamos aguardando o pagamento para começar a entrega. Só falta um pouquinho!",
          payment_ticket: "Tíquete de Pagamento",
          payment_code: "Código de pagamento",
          pay: "Pagar:",
          pay2: "Pagar",
          processing: "Processando",
          processing_notice: "A transação está em andamento, é rapidinho. Obrigado por usar Pagsmile!",
          refund_in_process: "O reembolso está em andamento, é rapidinho. Obrigado por usar Pagsmile!",
          success: "Pagamento feito com sucesso",
          success_result: "Sucesso",
          success_notice:
            "Você pagou o pedido com sucesso. Se quiser, pode voltar para a página do vendedor. Obrigado por usar Pagsmile!",
          cancelled: "Cancelado",
          order_timeout: "O prazo para o seu pagamento terminou, volte à página do vendedor para fazer um novo pedido.",
          failed: "Falhou",
          copied: "Copiado com sucesso",
          copy: "Copiar",
          visit_us: "Para saber mais sobre nós, visite",
          fail_notice: "É possível que alguma informação esteja errada, tente novamente ou fale com a gente",
          fail_query: "Quer tentar de novo?",
          yes: "Sim, quero",
          help_me: "Ajuda",
          order: "Pedido",
          back_shop: "De volta às compras",
          credit_debit_card: "Cartão de Crédito/Débito",
          credit_card: "Cartão de Crédito",
          debit_card: "Cartão de Débito",
          oxxo: "Pago en efectivo OXXO",
          wallet: "Carteira Digital",
          mon_wallet: "Carteira Digital",
          cardholder_name: "Nome completo do titular do cartão",
          card_no: "Número do cartão",
          invalid_card_no: "Número do cartão inválido",
          exp_year: "Ano de valid.",
          exp_date: "Data de validade (MM/YY)",
          invalid_exp_date: "Data de validade inválido",
          invalid_year: "Selecione um ano",
          exp_month: "Mês de valid.",
          month: "mês",
          invalid_month: "Selecione um mês",
          state: "Estado",
          invalid_state: "Selecione um Estado",
          city: "Cidade",
          invalid_city: "Selecione uma cidade",
          postal_code: "Código postal",
          invalid_postal_code: "CEP inválido",
          street: "Rua",
          invalid_street: "Rua inválida",
          number: "Número",
          invalid_number: "Número inválido",
          source: "Site ou nome do APP onde você comprou o produto",
          invalid_source: "Inválido",
          source_notice: "Se as informações inseridas estiverem incorretas, o pagamento do seu pedido pode falhar",
          todito_notice: "Insira suas informações do Todito Cash abaixo:",
          "checkout_inst1.0": "Caro <0></0>, pague <1></1> para finalizar sua compra.",
          "checkout_inst1.1": "Caro ",
          "checkout_inst1.2": "usuário,",
          "checkout_inst1.3": " pague ",
          "checkout_inst1.4": " para finalizar sua compra.",
          "checkout_inst2.1": "Leia o código QR para ir para",
          "checkout_inst2.1.1": "Clique no botão para ir para",
          "checkout_inst2.2": "APP e conclua o pagamento.",
          go_to: "Vá para App",
          checkout_inst3: "1. Vá para a loja mais próxima",
          checkout_inst4: "2. Informe ao caixa que você tem um pagamento do Pagsmile e mostre este número: ",
          share: "Compartilhar",
          save_pdf: "Salvar PDF",
          checkout_inst5: "Abra o aplicativo do seu banco e insira as informações abaixo",
          checkout_inst6: "Insira este número de referência:",
          register_info: "Informações para registrar a conta",
          installments: "Parcelamento",
          invalid_cvv: "CVV invalido",
          invalid_PIN: "PIN invalido",
          invalid_NIP: "NIP invalido",
          paid_major: "Maior parte paga",
          paid_minor: "Parte secundária paga",
          refund_verifying: "Verificando reembolso",
          refund_processing: "Processando reembolso",
          risk_control: "Controle de riscos",
          refunded: "Reembolsado",
          partial_refund: "Parcialmente reembolsado",
          chargeback: "Estorno",
          chargeback_reversed: "Estorno revertido",
          dispute: "Disputa",
          dispute_reversed: "Disputa revertida",
          timeout: "Tempo limite da transação esgotado",
          payment_refuse: "Pagamento recusado",
          payment_fail: "Pagamento falhou",
          refund_reverse: "Reembolso revertido",
          refund_refuse: "Reembolso recusado",
          pay_close: "Fechado",
          fail_sec: "Para a segurança da sua transação, o pedido foi encerrado. Por favor tente novamente",
          invalid_cep: "CEP invalido",
          boleto_inst1: "Boleto gerado!",
          boleto_inst2: "Você pode pagar em bancos, lotéricas, Correios, supermercados ou online.",
          boleto_inst3:
            "Já enviamos um e-mail com os detalhes e você também pode salvar um arquivo clicando no botão 'Salvar PDF' abaixo.",
          deposit_upload_inst1: "É bom ter você de volta!",
          deposit_upload_inst2:
            "Estamos quase lá, basta preencher este pequeno formulário e fazer upload do comprovante de pagamento para finalizar :)",
          "deposit_upload_inst3.1": "Por favor, insira os ",
          "deposit_upload_inst3.2": "seus",
          "deposit_upload_inst3.3": " dados bancários.",
          upload: "Carregar",
          back: "Voltar",
          wallet_inst1: "Escanear e pagar", // Portuguese
          wallet_inst2: "Você pode escanear e pagar usando:", // Portuguese
          wallet_inst3: "Como pagar?", // Portuguese
          wallet_inst4: "1. Abra seu aplicativo de carteira/banco em seu dispositivo", // Portuguese
          "wallet_inst4.1": "1. Abra seu aplicativo bancário em seu dispositivo", // Portuguese
          wallet_inst5: "2. Toque no recurso “escanear”", // Portuguese
          wallet_inst6: "3. Escaneie o QR de pagamento e complete o pagamento", // Portuguese
          wallet_inst7: "O número de referência também pode ser usado para pagar.", // Portuguese
          wallet_mob_ins1: "Clique e pague", // Portuguese
          wallet_mob_ins2: "Você pode clicar e pagar usando:", // Portuguese
          wallet_mob_ins3: "1. Clique no botão abaixo para ir para o APP de carteira/banco no seu telefone", // Portuguese
          wallet_mob_ins4: "2. Prossiga para completar o pagamento", // Portuguese
          pago_method: "Banco Móvel, QR (Yape, Plin), Agentes e Armazéns",
          pago_agent_title: "Agentes",
          pago_agent1: "1. Indique que vai efetuar um pagamento à empresa PAGOEFECTIVO.",
          pago_agent2: "2. Indique que fará o pagamento a PAGOEFECTIVO Soles.",
          "pago_agent3.1": "3. Indique o Código CIP: ",
          "pago_agent3.2": " e o valor a pagar.",
          pago_bank_title: "por Agências Bancárias",
          pago_bank1: "1. Indique que vai efetuar um pagamento à empresa PAGOEFECTIVO.",
          pago_bank2: "2. Indique que você fará o pagamento para PAGOEFECTIVO Soles",
          "pago_bank3.1": "3. Indique o Código CIP: ",
          "pago_bank3.2": " e o valor a pagar.",
          pago_online_title: "Transferência Online",
          pago_online1: "1. Selecione a opção Pagamento por Serviços.",
          pago_online2: "2. Pesquise e selecione a instituição / empresa PAGAMENTO EM DINHEIRO.",
          pago_online3: "3. Selecione Solas de PAGAMENTO EM DINHEIRO.",
          "pago_online4.1": "4. Digite seu código CIP: ",
          "pago_online4.2": " e siga as etapas.",
          clap_cash_title: "Como pagar?",
          pay_cash_ins1:
            "Vá ao local que você escolheu e diga ao caixa que quer fazer um pagamento para PAYCASH com o código ",
          pay_cashgtm_ins1:
            "Vá ao ponto de pagamento à vista mais próximo, mencione ao caixa que vai efetuar o pagamento PayCash do convênio {{key}} com o código ",
          clap_cash_ins1: "Vá até o ponto Klap EFECTIVO mais próximo e solicite o pagamento com o código ",
          clap_cash_ins2: "Diga ao comerciante que as opções da máquina são 2-8-1",
          clap_cash_ins3: "Pague e peça seu recibo",
          klap_store1: "Use",
          klap_store2: " este link ",
          klap_store3: "para procurar a loja Klap mais próxima",
          dexp1: "O pagamento é feito em dois passos:",
          dexp2: "1. Faça uma transferência, depósito ou PIX",
          dexp3: "2. Insira o comprovante de pagamento",
          dexp4: "Nome do banco:",
          dexp5: "Agência",
          dexp6: "Conta corrente:",
          dexp7: "Titular:",
          dexp8: "Chave PIX:",
          dexp9: "Tipo de Pagamento",
          dexp10: "Tipo de pagamento inválido",
          dexp11: "O formato correto tem de 4 a 6 dígitos (pode haver - no meio)",
          dexp12:
            "O formato correto tem 5 a 12 dígitos. Começa com um número, deve haver um - no meio, e o último dígito é um número ou x(X)",
          dexp13: "Carregar comprovante de pagamento",
          dexp14: "Por favor carregue um arquivo",
          dexp15: "Transferência no mesmo banco",
          dexp16: "TED entre bancos",
          dexp17: "Depósito em caixa",
          dexp18: "Depósito em envelope",
          dexp19: "Enviar comprovante",
          dexp20: "Nome completo",
          dexp21: "Arquivo enviado com sucesso. Aguarde o processamento e a confirmação do seu pagamento.",
          account: "Conta",
          bank: "Banco",
          drag_notice: "Você pode arrastar e soltar ou clicar na caixa",
          fail_try_agin: "Algo deu errado, tente novamente mais tarde",
          fail_change_method: "Algo deu errado. Use outro método de pagamento.",
          fail_upload_again: "Algo deu errado. Por favor, tente fazer o upload novamente",
          fail_upload: "Falha no upload",
          timed_out: "A solicitação de transação expirou!",
          no_channel: "Sem canal, entre em contato com a loja",
          subscription: "Você está assinando",
          subscription_billed: "sendo cobrado",
          subscription_every: "cada",
          subscription_trial: "pelo período de teste",
          subscription_then: ", depois",
          trial_period: "período de teste",
          and: "e",
          day: "dia",
          days: "dias",
          week: "semana",
          weeks: "semanas",
          months: "meses",
          year: "ano",
          years: "anos",
          ref_no: "No de referencia: ",
          agree_code: "Convenio: ",
          id: "Cedula de identidad: ",
          amount: "Total a pagar: ",
          due_date: "Fecha de vencimiento",
          pay_points1: "Você pode clicar",
          pay_points2: " aqui ",
          pay_points3: "para ver todos os pontos de pagamento.",
          pay_points_search: " para pesquisar o ponto de pagamento mais próximo.",
          payvalida_notice: "Recibo {{key}} generado!",
          payvalida_notice2: "Para realizar tu pago informa los siguientes dato en el punto {{key}} de tu preferencia:",
          select_country: "Selecione um país primeiro!",
          country: "País",
          invalid_country: "Selecione um pais",
          state_city: "Estado/Cidade",
          invalid_state_city: "Estado/Cidade inválido",
          refresh: "Recarregar",
          refresh_notice:
            "A página será atualizada automaticamente em 10 segundos, caso contrário, clique no botão abaixo.",
          download: "Download",
          network_err: "Erro de rede, por favor, tente novamente.",
          pix_notice:
            "Por favor evite usar o PayPal para efetuar o pagamento de seu pedido PIX. Escolhendo por usá-lo irá aumentar considerávelmente o tempo de aprovação do seu pedido.",
          address_billing: "Endereço de cobrança:",
          address_delivery: "Endereço de entrega:",
          address_same: "O mesmo endereço de cobrança",
          process_page1:
            "Pronto, você vai receber uma confirmação de pagamento no seu e-mail enquanto processamos sua compra.",
          process_page2: "Você pode fechar esta página agora.",
          barcode_step1: "Vá até o ponto pagamento em dinheiro mais próximo e solicite o pagamento com o código ",
          "barcode_step1.1":
            "Vá até o ponto de pagamento {{key}} mais próximo e informe ao caixa que deseja fazer um pagamento ao PAYCASH com o código ",
          payment_point: "ponto pagamento em dinheiro",
          bank_list: "Lista de banco",
          bank_select: "Selecione um banco",
          agent: "Agência",
          agent_invalid: "Agência inválida",
          agent_select: "Selecione um agente",
          account_number: "Número da conta",
          account_invalid: "Número da conta inválido",
          checking_account: "Cuenta corriente",
          saving_account: "Cuenta de ahorros",
          master_account: "Cuenta administradora",
          pb_ins: "Entre no app Player's Bank, toque no Pix, escolha Pagar QR Code e escaneie o código abaixo.",
          pb_mob_ins: "Toque no botão abaixo e finalize o pagamento pelo banco que joga junto.",
          lp_ins1: "O Pagamento pode ser feito por transferência bancária ou depósito.",
          lp_ins2: "Data de validade: ",
          lp_ins3: "Número de referência:",
          lp_ins4: "Informação da conta:",
          lp_ins5: "Nome do banco",
          account_type: "Tipo de conta",
          account_type_err: "Selecione um tipo de conta",
          lp_ins7: "Número da conta",
          lp_ins8: "Nome do beneficiário",
          lp_ins9: "Tipo de documento",
          lp_ins10: "ID do documento",
          lp_ins11: "O número de referência deve ser exatamente o mesmo.",
          trsf1: "Abra seu aplicativo de instituições financeiras",
          trsf2: "Vá para: Serviços > Transações > Transfiya",
          trsf3: "Selecione a conta com a qual deseja pagar",
          trsf4: "Pague nesta conta: ",
          trsf5: "Lembre-se de transferir o valor exato de ",
          trsf6: ", caso contrário a transação falhará",
          spei_key: "Esta chave só pode ser usada uma vez",
          spei_clabe: "CLABE interbancária",
          spei_bank: "Banco destino",
          spei_recipient: "Beneficiário",
          pb_qr: "QR Code Player's Bank",
          pb_card: "Cartão Player's Bank",
          pci_notice: "Todas as transações são garantidas com segurança e em conformidade com o protocolo PCI DSS.",
          "payment.redirect.notice1": "Você será redirecionado para a página do lojista em {{key}} segundos.",
          "payment.redirect.notice2": "Clique ",
          "payment.redirect.notice3": "aqui para",
          "payment.redirect.notice4": " cancelar.",
          service_name: "Serviço",
          pay_amount: "Valor",
          pay_code: "Código de pagamento",
          "mon_bank-mobile": "Celular",
          "mon_bank-web": "Computador",
          mon_bank: "Banco",
          mon_agent: "Loja",
          mon_chooseBank: "1. Escolha o lugar que quer pagar",
          mon_chooseMethod: "2. Escolha a forma de pagamento",
          address: "Endereço",
          invalid_address: "Endereço inválido",
          pay_or_fail: "Lembre-se de fazer o pagamento em 15 minutos, caso contrário, a transação falhará",
          ben_info: "Informação do recebedor",
          bank_acc_no: "Número da conta bancária",
          bank_acc_no_err: "Número da conta bancária inválido",
          sec_code: "Código de segurança",
          sec_code_err: "Código de segurança inválido",
          cash_ins1: "Entre na seção de pagamento e procure por Paycash pelo nome",
          cash_ins2: "Insira os últimos {{key}} dígitos do número de referência e confirme o pagamento",
          cash_ins3: "Digite o token do cliente e aguarde a confirmação da operação",
          cash_ins4: "Insira os {{key}} dígitos do número de referência e confirme o pagamento",
          fill_all: "Preencha todos os campos",
          digital_bank1: "Selecione Serviços, Coleções",
          digital_bank2: "Pagamento de serviços básicos, impostos e catálogo 2. Outras cobranças PayCash",
          digital_bank3: "Insira a referência e o valor do pagamento",
          bncr_paycash0:
            "Vá ao ponto de pagamento do BN Servicios mais próximo e peça ao caixa para efetuar o pagamento em PayCash com o código ",
          bncr_paycash1: "Entre no Internet Banking do Banco Nacional com sua identificação e senha",
          bncr_paycash2: "Faça a busca pelo nome: Paycash e digite número de referência de 16 dígitos",
          bncr_paycash3: "Pagamento confirmado, selecione a forma de pagamento e aguarde a confirmação",
          digital_banking: "Banco digital",
          bncr_cash1:
            "Vá ao ponto de pagamento do BN Servicios mais próximo e solicite ao caixa que efetue um pagamento {{key}} com o número de referência fornecido acima",
          bncr_cash2: "Pague e receba seu voucher",
          bncr_bank1: "Entre no Internet Banking do Banco Nacional com sua identificação e senha",
          bncr_bank2: "Realize a busca pelo nome: Payválida e digite o número de referência",
          bncr_bank3: "O número de referência será confirmado, selecione a forma de pagamento e aguarde a confirmação",
          crypto: "Criptomoedas",
          choose_token: "Escolher tokens",
          20000: "Serviço atualmente indisponível",
          40000: "A transação falhou",
          40002: "A transação falhou",
          40012: "Pedido não existe",
          40013: "Pedido terminou",
          40014: "As informações do pedido são inconsistentes",
          40015: "Valor máximo (incluindo taxas) excedido",
          40101: "Tempo limite do pedido",
          40201: "Falha ao carregar",
          40301: "Banco esta ocupado",
          "SYS-0001":
            "Seu pagamento foi recusada. Escolha outra forma de pagamento. Recomendamos meios de pagamento em dinheiro.",
          "SYS-0002": "Para a segurança da sua transação, a ordem está encerrada, por favor reenvie-o.",
          "SYS-0003": "Por favor escolha o valor mais alto.",
          "SYS-0004": "Por favor forneça o email válido.",
          "CC-0001": "Pagamento negado. Nome e CPF não são correspondentes. Por favor insira a informação correta.",
          "CC-0002": "Token de cartão inválido",
          "CC-0003": "Estamos processando o pagamento. Em até 2 dias úteis informaremos por e-mail o resultado.",
          "CC-0004":
            "Estamos processando o pagamento. Em até 2 dias úteis informaremos por e-mail se foi aprovado ou se precisamos de mais informações.",
          "CC-0005": "Confira o número do cartão.",
          "CC-0006": "Confira a data de validade.",
          "CC-0007": "Confira os dados.",
          "CC-0008": "Confira o código de segurança.",
          "CC-0009": "Não conseguimos processar seu pagamento.",
          "CC-0010": "Você deve autorizar ao Cartão o pagamento do valor ao Pagsmile.",
          "CC-0011": "Ligue para o Cartão para ativar seu cartão. O telefone está no verso do seu cartão.",
          "CC-0012": "Não conseguimos processar seu pagamento.",
          "CC-0013":
            "Você já efetuou um pagamento com esse valor. Caso precise pagar novamente, utilize outro cartão ou outra forma de pagamento.",
          "CC-0014":
            "Seu pagamento foi recusado. Escolha outra forma de pagamento. Recomendamos meios de pagamento em dinheiro.",
          "CC-0015": "O Cartão possui saldo insuficiente.",
          "CC-0016": "O Cartão não processa pagamentos parcelados.",
          "CC-0017": "Você atingiu o limite de tentativas permitido. Escolha outro cartão ou outra forma de pagamento.",
          "CC-0018": "O Cartão não processou seu pagamento.",
          70001: "Não conseguimos processar seu pagamento.",
          80004: "O usuário está na lista negra",
          "70001-10001": "O CPF digitado é inválido, digite outro",
          "70001-10002": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
          "70001-10003": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
          "70001-10004": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
          "70001-20001": "Limite de transação excedido",
          "70001-20003": "Limite de transação única excedido",
          "70001-99999": "Esta transação não pode ser processada. Entre em contato com o suporte ao cliente",
        },
      },
      ru: {
        translation: {
          en: "English",
          es: "Español",
          pt: "Português",
          ru: "Pусский",
          ar: "عربي",
          zh: "中文",
          "zh-TW": "繁體中文",
          jp: "日本語",
          ko: "한국어",
          tr: "Türkçe",
          bank_transfer: "Банковский перевод",
          wallet_inst1: "Сканировать и оплатить", // Russian
          wallet_inst2: "Вы можете сканировать и оплатить с помощью:", // Russian
          wallet_inst3: "Как оплатить?", // Russian
          wallet_inst4: "1. Откройте приложение кошелька/банка на вашем устройстве", // Russian
          "wallet_inst4.1": "1. Откройте банковское приложение на вашем устройстве", // Russian
          wallet_inst5: "2. Нажмите на функцию «сканировать»", // Russian
          wallet_inst6: "3. Отсканируйте QR-код оплаты и завершите оплату", // Russian
          wallet_inst7: "Для оплаты также можно использовать номер ссылки.", // Russian
          wallet_mob_ins1: "Нажмите и оплатите", // Russian
          wallet_mob_ins2: "Вы можете нажать и оплатить с помощью:", // Russian
          wallet_mob_ins3: "1. Нажмите на кнопку ниже, чтобы перейти в приложение кошелька/банка на вашем телефоне", // Russian
          wallet_mob_ins4: "2. Перейдите к завершению оплаты", // Russian
          warning: {
            crypto: {
              ETH: "Отправляйте только ETH в сети ETH (ERC20) на этот адрес.",
              USDC: "Отправляйте только USDC в сети ETH (ERC20) на этот адрес.",
              USDT: "Отправляйте только USDT в сети ETH (ERC20) на этот адрес.",
              USDT_TRC20:
                "Пожалуйста, отправляйте USDT только через сеть TRON. Вклады в TRX НЕ принимаются. Использование других сетей или пополнение счета в TRX приведет к потере ваших средств.",
            },
          },
          subscriptions: {
            title: "Вы собираетесь подписаться.",
            with_trial:
              "Продолжительность подписки <strong>{{subscription_period}}</strong>. За пробный период <strong>{{trial_period}}</strong> будет списано <strong>{{trial_amount}}</strong>, затем <strong>{{interval_amount}}</strong> каждые <strong>{{interval_period}}</strong>.",
            without_trial:
              "Продолжительность подписки <strong>{{subscription_period}}</strong>. Будет списано <strong>{{interval_amount}}</strong> каждые <strong>{{interval_period}}</strong>.",
          },
          times: {
            day_one: "{{count}} день",
            day_other: "{{count}} дней",
            week: "{{count}} неделя",
            week_other: "{{count}} недель",
            month_one: "{{count}} месяц",
            month_other: "{{count}} месяцев",
            year_one: "{{count}} год",
            year_other: "{{count}} лет",
          },
          sbp: "Система быстрых платежей (СБП)",
          other_fees: "другие сборы",
          payment_method: "Способ оплаты",
          filling_details: "Детали",
          name: "Имя",
          invalid_name: "Некорректное имя",
          email: "Электронная почта",
          invalid_email: "Неверный адрес электронной почты",
          phone: "Номер телефона",
          invalid_phone: "Неверный номер телефона",
          time_remain: "Оставшееся время:",
          trx_id: "Номер транзакции:",
          product_description: "Описание продукта:",
          order_value: "Стоимость заказа:",
          total: "Всего",
          hide: "Скрыть",
          processing_notice: "Транзакция в процессе, подождите немного. Спасибо за использование Pagsmile!",
          refund_in_process: "Возврат средств в процессе, подождите немного. Спасибо за использование Pagsmile!",
          success: "Платеж прошел успешно",
          fail_query: "Хотите попробовать еще раз?",
          yes: "Да",
          help_me: "Помощь",
          back_shop: "Назад к покупкам",
          fee_102: "банковская комиссия",
          fee_101: "Налоговый сбор",
          fee_100: "Другие сборы",
          discount: "Скидка",
          details: "Детали",
          confirm: "Подтверждать",
          privacy_policy1: "Я ознакомился и согласен с",
          privacy_policy2: " условиями использования",
          privacy_policy3: " и",
          privacy_policy4: " политикой конфиденциальности",
          privacy_policy5: " Pagsmile",
          pci_notice: "Все транзакции надежно гарантированы и соответствуют протоколу PCI DSS.",
          "payment.redirect.notice1": "Вы будете перенаправлены на страницу продавца через {{key}} секунд.",
          "payment.redirect.notice2": "нажмите ",
          "payment.redirect.notice3": "здесь,",
          "payment.redirect.notice4": " чтобы отменить.",
          refresh: "Oбновите",
          refresh_notice:
            "Страница будет обновлена автоматически через 10 секунд, в противном случае, пожалуйста, нажмите на кнопку ниже.",
          network_err: "Ошибка сети, попробуйте еще раз.",
          fail_try_agin: "Что-то пошло не так. Пожалуйста, повторите попытку позже.",
          fail_change_method: "Что-то пошло не так. Пожалуйста, используйте другой способ оплаты.",
          timed_out: "Время запроса на транзакцию истекло!",
          no_channel: "Нет канала, пожалуйста, свяжитесь с продавцом",
          bank_agent: "Выберите Банк/Агента",
          order_timeout: "Срок платежа истек, пожалуйста, вернитесь на страницу продавца, чтобы сделать новый заказ",
          crypto: "Криптовалюты",
          "70001-10001": "Введенный CPF недействителен, введите другой",
          "70001-10002": "Эта транзакция не может быть обработана. Связаться со службой поддержки",
          "70001-10003": "Эта транзакция не может быть обработана. Связаться со службой поддержки",
          "70001-10004": "Эта транзакция не может быть обработана. Связаться со службой поддержки",
          "70001-20001": "Превышен лимит транзакции",
          "70001-20003": "Превышен лимит одной транзакции",
          "70001-99999": "Эта транзакция не может быть обработана. Связаться со службой поддержки",
          20000: "Сейчас услуга недоступна",
          40000: "Транзакция не удалась",
          40002: "Транзакция не удалась",
          40012: "Заказ не существует",
          40013: "Заказ завершен",
          40014: "Информация о заказе несовместима",
          40015: "Превышена максимальная сумма (включая комиссии)",
          40101: "Тайм-аут заказа",
          40201: "Не удалось загрузить",
          40301: "Банк занят",
          70001: "Мы не можем обработать ваш платеж.",
          80004: "Пользователь занесен в черный список",
        },
      },
      ar: {
        translation: {
          en: "English",
          es: "Español",
          pt: "Português",
          ru: "Pусский",
          ar: "عربي",
          zh: "中文",
          "zh-TW": "繁體中文",
          jp: "日本語",
          ko: "한국어",
          tr: "Türkçe",
          bank_transfer: "التحويل البنكي",
          other_fees: "رسوم أخرى",
          kiosk: "أكشاك الدفع",
          warning: {
            crypto: {
              ETH: "أرسل ETH فقط على شبكة ETH (ERC20) إلى هذا العنوان.",
              USDC: "أرسل USDC فقط على شبكة ETH (ERC20) إلى هذا العنوان.",
              USDT: "أرسل USDT فقط على شبكة ETH (ERC20) إلى هذا العنوان.",
              USDT_TRC20:
                "يرجى إرسال العملات الرقمية USDT عبر شبكة ترون فقط. لا يتم قبول إيداع TRX. استخدام شبكات أخرى أو تعبئة الرصيد بـ TRX سيؤدي إلى خسارة أموالك.",
            },
          },
          fawry: {
            cash: {
              content:
                "استخدم الرقم المرجعي أدناه لإجراء الدفع في أي من متاجر البيع بالتجزئة التابعة لـ FawryPay. أو ادفع من خلال تطبيق FawryPay عن طريق إدخال الرقم المرجعي.",
            },
          },
          checkout: {
            kiosk: {
              title: {
                1: "يمكنك الدفع في أي من هذه الأماكن",
                2: "كيف تريد الدفع؟",
              },
              service: "الخدمة",
              code: "رمز الدفع",
              due_date: "تاريخ الاستحقاق",
              amount: "القيمة",
              how_to: {
                1: "{{reference}} اختر مدفوعات متنوعة-تأكيد-وتقديم الرقم المرجعي الخاص بك",
                2: "ادفع واستلم إيصالك",
              },
            },
          },
          "checkout_inst1.0": "عزيزي <0></0>، ادفع <1></1> لإتمام عملية الشراء",
          "checkout_inst1.2": "المستخدم",
          payment_method: " طريقة الدفع",
          filling_details: " تفاصيل التعبئة",
          trx_id: "معرّف المعاملة",
          time_remain: " الوقت المتبقي:",
          hide: " إخفاء",
          show: " إظهار",
          product_description: "وصف المنتج",
          order_value: " قيمة الطلب",
          fee_102: " رسوم بنكية",
          fee_101: "ضريبة",
          fee_100: " رسوم أخرى",
          discount: " خصم",
          total: " المجموع",
          confirm: " تأكيد",
          return: " العودة",
          privacy_policy_error: " يجب أن تقرأ وتقبل شروط الاستخدام وسياسة الخصوصية لإكمال عملية الشراء",
          privacy_policy1: " لقد قرأت ووافقت على",
          privacy_policy2: " شروط الاستخدام",
          privacy_policy3: " و",
          privacy_policy4: " سياسة الخصوصية",
          privacy_policy5: " Pagsmile من ",
          pci_notice: " PCI DSS جميع المعاملات مضمونة بأمان ومتوافقة مع بروتوكول",
          "payment.redirect.notice1": "ستتم إعادة توجيهك إلى صفحة التاجر خلال {{key}} ثانية",
          "payment.redirect.notice2": "انقر ",
          "payment.redirect.notice3": "هنا ",
          "payment.redirect.notice4": "للإلغاء",
          back_shop: " العودة إلى التسوق",
          invalid_name: " اسم غير صالح",
          cardholder_name: " الاسم الكامل لحامل البطاقة",
          exp_month: "شهر نهاية صلاحية",
          invalid_month: " اختر شهرًا",
          exp_year: "سنة نهاية صلاحية ",
          invalid_year: " اختر سنة",
          invalid_cvv: "غير صالح CVV",
          phone: " رقم الهاتف المحمول",
          invalid_phone: " رقم الهاتف غير صالح",
          email: " البريد الإلكتروني",
          invalid_email: " بريد إلكتروني غير صالح",
          address_billing: "عنوان إرسال الفواتير:",
          country: " البلد",
          invalid_country: " اختر البلد",
          state: "مقاطعة",
          invalid_state: " اختر مقاطعة ",
          city: "المدينة",
          invalid_city: " اختر المدينة",
          street: " شارع",
          invalid_street: " شارع غير صالح",
          number: "رقم",
          invalid_number: " رقم غير صالح",
          select_country: " الرجاء تحديد بلد أولاً",
          postal_code: " الرمز البريدي",
          invalid_postal_code: " رمز بريدي غير صالح",
          address_delivery: ": عنوان التسليم",
          address_same: " نفس عنوان إرسال الفواتير",
          details: " التفاصيل",
          card_no: "رقم البطاقة",
          invalid_card_no: "رقم بطاقة غير صالحة",
          expiry_month: "شهر انتهاء الصلاحية",
          invalid_month2: "شهر غير صالح",
          expiry_year: "سنة انتهاء الصلاحية",
          invalid_year2: "سنة غير صالحة",
          credit_debit_card: "بطاقة الائتمان / الخصم",
          okay: "!أوكي",
          network_err: "خطأ في الشبكة ، يرجى المحاولة مرة أخرى",
          fail_try_agin: "حدث خطأ ما ، يرجى المحاولة مرة أخرى لاحقًا",
          fail_change_method: "حدث خطأ ما. يُرجى استخدام طريقة دفع أخرى",
          fail_notice: "من الممكن أن تكون المعلومات خاطئة ، حاول مرة أخرى أو تواصل معنا",
          payment_fail: "فشل الدفع",
          failed: "فشل",
          success_result: "نجاح",
          success_notice: " Pagsmile  لقد دفعت الطلب بنجاح. الرجاء الرجوع إلى صفحة البائع! شكرًا لك على استخدام",
          cancelled: "Cancelled",
          order_timeout: " انتهى الحد الزمني لدفعتك ، يرجى الرجوع إلى صفحة البائع لتقديم طلب جديد",
          processing: "معالجة",
          processing_notice: "! Pagsmile المعاملة قيد المعالجة، وستكون سريعة. شكرًا لك على استخدام",
          success: "تم الدفع بنجاح",
          refund_verifying: "استرداد الأموال",
          refund_processing: "معالجة استرداد الأموال",
          risk_control: "التحكم في المخاطر",
          refunded: "استرداد",
          partial_refund: "تم رد الأموال جزئيًا",
          cash_payments: "التسديد نقذا",
          chargeback: " رد المبالغ المدفوعة",
          chargeback_reversed: "تم رد المبالغ المدفوعة",
          dispute: "نزاع",
          dispute_reversed: "تم النزاع",
          timeout: "انتهت مهلة المعاملة",
          payment_refuse: "دفعة مرفوضة",
          refund_reverse: "تم استرداد الأموال",
          refund_refuse: "تم رفض رد الأموال",
          pay_close: "مغلق",
          fail_sec: "تم إغلاق هذا الطلب. يرجى إرساله مرة أخرى من أجل تأمين معاملتك",
          crypto: "العملات الرقمية",
          20000: "الخدمة غير متوفرة حاليًا",
          40000: "فشلت المعاملة",
          40002: "فشلت المعاملة",
          40012: "الطلب غير موجود",
          40013: "انتهى الأمر",
          40014: "معلومات الطلب غير متسقة",
          40015: "تجاوز الحد الأقصى للمبلغ (بما في ذلك الرسوم)",
          40101: "مهلة الطلب",
          40201: "فشل التحميل",
          40301: "البنك مشغول",
          "SYS-0001": "تم رفض دفعتك. اختر طريقة دفع أخرى. نوصي بطرق الدفع نقدًا",
          "SYS-0002": "وقت مستقطع",
          "SYS-0003": "مبلغ المعاملة غير صالح",
          "SYS-0004": "يجب على الدافع تقديم بريد إلكتروني صالح",
          "CC-0001": "مستخدمون غير صالحين للمشغلين",
          "CC-0002": "رمز البطاقة غير صالح",
          "CC-0003": "الطوارئ المعلقة",
          "CC-0004": "دليل المراجعة المعلق",
          "CC-0005": "رقم البطاقة المرفوض والمعبأ غير صالح",
          "CC-0006": "تاريخ مرفوض وملء غير صالح",
          "CC-0007": "مرفوضة، وملأت أخرى سيئة",
          "CC-0008": "رمز أمان مرفوض ومملوء بشكل سيئ مرفوضة،",
          "CC-0009": " مرفوضة، في القائمة السوداء",
          "CC-0010": " مرفوض، دعوة للحصول على إذن",
          "CC-0011": "مرفوضة، البطاقة معطلة",
          "CC-0012": " مرفوض، خطأ في البطاقة ",
          "CC-0013": "دفع مكرر مرفوض",
          "CC-0014": "مرفوضة ، عالية الخطورة",
          "CC-0015": "مبلغ مرفوض وغير كافٍ",
          "CC-0016": "أقساط مرفوضة وغير صالحة",
          "CC-0017": "مرفوضة ، الحد الأقصى من المحاولات",
          "CC-0018": " مرفوض ، سبب آخر",
          70001: "نحن غير قادرين على معالجة دفعتك",
          refresh_notice: " سيتم تحديث الصفحة تلقائيًا في غضون 10 ثانية ، وإلا يرجى النقر فوق الزر أدناه",
          refresh: "تحديث",
          fail_query: "هل تريد المحاولة مرة أخرى؟",
          yes: "نعم",
          help_me: " المساعدة ",
          source: "موقع الويب أو اسم التطبيق الذي اشتريت منه المنتج",
          timed_out: "انتهت مهلة طلب المعاملة",
          name: "اسم",
          copy: "نسخ",
          wallet: "محافظ",
          copied: "تم النسخ",
          ref_no: "الرقم المرجعي :",
          payment_ticket: "تذكرة الدفع",
          no_channel: "لا توجد قناة ، يرجى الاتصال بالتاجر",
          wallet_inst1: "قم بمسح الرمز وقم بعملية الدفع",
          wallet_inst2: "يمكنك مسح الرمز والقيام بعملية الدفع",
          wallet_inst3: "كيف تريد الدفع؟",
          wallet_inst4: "افتح تطبيق محفظتك على جهازك",
          wallet_inst5: "اضغط على ميزة المسح الضوئي",
          wallet_inst6: "قم بمسح الرمز وإكمال عملية الدفع",
          wallet_inst7: "يمكن أيضًا استخدام الرقم المرجعي للدفع",
          amount: "مبلغ الدفع",
          "70001-10001": "CPF الذي تم إدخاله غير صالح ، أدخل واحدًا آخر",
          "70001-10002": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
          "70001-10003": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
          "70001-10004": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
          "70001-20001": "تجاوز حد المعاملة",
          "70001-20003": "تم تجاوز حد المعاملة الواحدة",
          "70001-99999": "هذه الصفقة لا يمكن أن تتم معالجتها. اتصل بدعم العملاء",
        },
      },
      zh: {
        translation: {
          en: "English",
          es: "Español",
          pt: "Português",
          ru: "Pусский",
          ar: "عربي",
          zh: "中文",
          "zh-TW": "繁體中文",
          jp: "日本語",
          ko: "한국어",
          tr: "Türkçe",
          bank_transfer: "银行转账",
          wallet_inst1: "扫描并支付", // Chinese (Simplified)
          wallet_inst2: "您可以使用以下方式扫描并支付：", // Chinese (Simplified)
          wallet_inst3: "如何支付？", // Chinese (Simplified)
          wallet_inst4: "1. 在您的设备上打开钱包/银行应用程序", // Chinese (Simplified)
          "wallet_inst4.1": "1. 在您的设备上打开银行应用程序", // Chinese (Simplified)
          wallet_inst5: "2. 点击“扫描”功能", // Chinese (Simplified)
          wallet_inst6: "3. 扫描付款二维码并完成付款", // Chinese (Simplified)
          wallet_inst7: "参考号也可以用来支付。", // Chinese (Simplified)
          wallet_mob_ins1: "点击并支付", // Chinese (Simplified)
          wallet_mob_ins2: "您可以使用以下方式点击并支付：", // Chinese (Simplified)
          wallet_mob_ins3: "1. 点击下方按钮以转到您手机上的钱包/银行应用程序", // Chinese (Simplified)
          wallet_mob_ins4: "2. 继续完成付款", // Chinese (Simplified)
          warning: {
            crypto: {
              ETH: "只通过ETH (ERC20)网络发送ETH到这个地址。",
              USDC: "需通过ETH (ERC20)网络发送USDC到这个地址。",
              USDT: "需通过ETH (ERC20)网络发送USDT到这个地址。",
              USDT_TRC20: "请仅通过TRON网络发送USDT。不接受TRX存款。使用其他网络或以TRX充值将导致您的资金丢失。",
            },
          },
          subscriptions: {
            title: "您即将订阅。",
            with_trial:
              "订阅期限为 <strong>{{subscription_period}}</strong>。试用期间将收取 <strong>{{trial_amount}}</strong>，然后每 <strong>{{interval_period}}</strong> 收取 <strong>{{interval_amount}}</strong>。",
            without_trial:
              "订阅期限为 <strong>{{subscription_period}}</strong>。每 <strong>{{interval_period}}</strong> 收取 <strong>{{interval_amount}}</strong>。",
          },
          times: {
            day_one: "{{count}} 天",
            day_other: "{{count}} 天",
            week: "{{count}} 周",
            week_other: "{{count}} 周",
            month_one: "{{count}} 个月",
            month_other: "{{count}} 个月",
            year_one: "{{count}} 年",
            year_other: "{{count}} 年",
          },
          other_fees: "其他费用",
          payment_method: "支付方式",
          filling_details: "填写详情",
          trx_id: "交易ID",
          time_remain: "剩余时间",
          hide: "隐藏",
          show: "显示",
          product_description: "产品描述",
          order_value: "订单金额",
          fee_102: "银行手续费",
          fee_101: "税",
          fee_100: "其他费用",
          discount: "折扣",
          total: "总计",
          confirm: "确认",
          return: "返回",
          privacy_policy_error: "您必须阅读并接受使用条款和隐私政策才能完成购买.",
          privacy_policy1: "我已阅读并同意Pagsmile的",
          privacy_policy2: "使用条款",
          privacy_policy3: "和",
          privacy_policy4: "隐私政策.",
          privacy_policy5: "",
          pci_notice: "所有交易都得到安全保证并符合PCI DSS协议.",
          "payment.redirect.notice1": "您将在{{key}}秒后被重定向到商家页面.",
          "payment.redirect.notice2": "单击",
          "payment.redirect.notice3": "此处",
          "payment.redirect.notice4": "取消.",
          back_shop: "返回购物",
          invalid_name: "无效姓名",
          cardholder_name: "持卡人姓名",
          exp_month: "到期月份",
          invalid_month: "选择月份",
          exp_year: "到期年",
          invalid_year: "选择年份",
          invalid_cvv: "无效的CVV",
          phone: "手机号",
          invalid_phone: "无效电话号码",
          email: "电子邮件",
          invalid_email: "无效的邮箱",
          address_billing: "账单地址:",
          country: "国家/地区",
          invalid_country: "选择国家/地区",
          state_city: "州/市",
          invalid_state_city: "无效的州/市",
          city: "城市",
          invalid_city: "选择城市",
          street: "街道",
          invalid_street: "无效街道",
          number: "楼号",
          invalid_number: "无效楼号",
          select_country: "请先选择国家/地区！",
          postal_code: "邮政编码",
          invalid_postal_code: "无效的邮政编码",
          address_delivery: "送货地址:",
          address_same: "与账单地址相同",
          details: "详细信息",
          card_no: "卡号",
          invalid_card_no: "无效卡号",
          expiry_month: "到期月份",
          invalid_month2: "无效月份",
          expiry_year: "到期年份",
          invalid_year2: "无效年份",
          credit_debit_card: "信用卡/借记卡",
          okay: "好！",
          network_err: "网络错误，请重试",
          fail_try_agin: "出了点问题，请稍后再试",
          fail_change_method: "出了点问题。请使用其他支付方式",
          fail_notice: "信息可能有误，请重试或联系我们",
          payment_fail: "支付失败了",
          failed: "失败",
          success_result: "成功",
          success_notice: "您已成功支付订单。请返回卖家页面！感谢您使用 Pagsmile.",
          cancelled: "取消了",
          order_timeout: "您的付款期限已过，请返回卖家页面重新下单",
          processing: "处理中",
          processing_notice: "交易正在进行中，会很快的。感谢您使用 Pagsmile！",
          success: "支付成功",
          refund_verifying: "验证退款",
          refund_processing: "处理退款",
          risk_control: "控制风险",
          refunded: "退款了",
          partial_refund: "部分退款了",
          chargeback: "Chargeback",
          chargeback_reversed: "Chargeback Reversed",
          dispute: "争议",
          dispute_reversed: "争议被撤销",
          timeout: "交易超时了",
          payment_refuse: "付款被拒绝了",
          refund_reverse: "退款撤销了",
          refund_refuse: "退款被拒绝了",
          pay_close: "关闭了",
          fail_sec: "为了您的交易安全，该订单已关闭，请重新提交",
          20000: "服务当前不可用",
          40000: "交易失败了",
          40002: "交易失败了",
          40012: "订单不存在",
          40013: "订单已结束",
          40014: "订单信息不一致",
          40015: "超出最大金额（包括手续费）",
          40101: "订单超时了",
          40201: "上传失败",
          40301: "银行忙",
          "SYS-0001": "您的付款已被拒绝。请选择其他付款方式。我们建议您使用现金付款",
          "SYS-0002": "超时了",
          "SYS-0003": "无效交易金额",
          "SYS-0004": "付款人必须提供有效的电子邮件地址",
          "CC-0001": "涉及无效的运营商用户",
          "CC-0002": "无效的卡令牌",
          "CC-0003": "未决应急",
          "CC-0004": "待审核手册",
          "CC-0005": "已拒绝，填错的卡号",
          "CC-0006": "已拒绝，填写日期错误",
          "CC-0007": "已拒绝，填错其他",
          "CC-0008": "已拒绝，错误填写的安全代码",
          "CC-0009": "已拒绝，在黑名单中",
          "CC-0010": "已拒绝，请求授权",
          "CC-0011": "已拒绝，卡禁用",
          "CC-0012": "已拒绝，卡片错误",
          "CC-0013": "已拒绝，重复支付",
          "CC-0014": "已拒绝，高风险",
          "CC-0015": "已拒绝，金额不足",
          "CC-0016": "已拒绝，无效分期付款",
          "CC-0017": "已拒绝，最大尝试次数",
          "CC-0018": "已拒绝，其他原因",
          70001: "我们无法处理您的付款",
          80004: "用户被列入黑名单",
          "70001-10001": "输入的CPF无效，再输入一个",
          "70001-10002": "这笔交易无法处理。联系客户支持",
          "70001-10003": "这笔交易无法处理。联系客户支持",
          "70001-10004": "这笔交易无法处理。联系客户支持",
          "70001-20001": "超出交易限制",
          "70001-20003": "超出单笔交易限额",
          "70001-99999": "这笔交易无法处理。联系客户支持",
          refresh_notice: "页面将在 10 秒后自动刷新，否则请点击下面的按钮",
          refresh: "刷新",
          fail_query: "你想再试一次吗？",
          yes: "是的",
          help_me: "帮帮我",
          source: "您购买产品的网站或APP名称",
          timed_out: "交易请求超时！",
          no_channel: "暂无频道，请联系商家",
          bank_agent: "选择 银行/代理",
          wallet: "钱包",
          mon_wallet: "钱包",
          name: "姓名",
          state: "州",
          invalid_state: "无效州",
          exp_date: "到期时间（月|年）",
          invalid_exp_date: "无效到期时间",
          invalid_TIN: "无效ID",
          bank_list: "银行列表",
          bank_select: "选择银行",
          online_banking: "网上银行",
          retail_outlet: "零售店",
          store_list: "选择商店",
          store_select: "选择商店",
        },
      },
      "zh-TW": {
        translation: {
          en: "English",
          es: "Español",
          pt: "Português",
          ru: "Pусский",
          ar: "عربي",
          zh: "中文",
          "zh-TW": "繁體中文",
          jp: "日本語",
          ko: "한국어",
          tr: "Türkçe",
          wallet_inst1: "掃描並支付", // Chinese (Traditional)
          wallet_inst2: "您可以使用以下方式掃描並支付：", // Chinese (Traditional)
          wallet_inst3: "如何支付？", // Chinese (Traditional)
          wallet_inst4: "1. 在您的設備上打開錢包/銀行應用程式", // Chinese (Traditional)
          "wallet_inst4.1": "1. 在您的設備上打開銀行應用程式", // Chinese (Traditional)
          wallet_inst5: "2. 點擊“掃描”功能", // Chinese (Traditional)
          wallet_inst6: "3. 掃描付款二維碼並完成付款", // Chinese (Traditional)
          wallet_inst7: "也可以使用參考號碼支付。", // Chinese (Traditional)
          wallet_mob_ins1: "點擊並支付", // Chinese (Traditional)
          wallet_mob_ins2: "您可以使用以下方式點擊並支付：", // Chinese (Traditional)
          wallet_mob_ins3: "1. 點擊下面的按鈕以轉到您手機上的錢包/銀行應用程式", // Chinese (Traditional)
          wallet_mob_ins4: "2. 繼續完成付款", // Chinese (Traditional)
          bank_transfer: "銀行轉帳",
          warning: {
            crypto: {
              ETH: "只通過ETH (ERC20)網絡發送ETH到這個地址。",
              USDC: "需通過ETH (ERC20)網絡發送USDC到這個地址。",
              USDT: "需通過ETH (ERC20)網絡發送USDT到這個地址。",
              USDT_TRC20: "請僅透過TRON網路發送USDT。不接受TRX存款。使用其他網路或以TRX充值將導致您的資金損失。",
            },
          },
          subscriptions: {
            title: "您即將訂閱。",
            with_trial:
              "訂閱期限為 <strong>{{subscription_period}}</strong>。試用期間將收取 <strong>{{trial_amount}}</strong>，之後每 <strong>{{interval_period}}</strong> 將收取 <strong>{{interval_amount}}</strong>。",
            without_trial:
              "訂閱期限為 <strong>{{subscription_period}}</strong>。每 <strong>{{interval_period}}</strong> 將收取 <strong>{{interval_amount}}</strong>。",
          },
          times: {
            day_one: "{{count}} 天",
            day_other: "{{count}} 天",
            week: "{{count}} 週",
            week_other: "{{count}} 週",
            month_one: "{{count}} 個月",
            month_other: "{{count}} 個月",
            year_one: "{{count}} 年",
            year_other: "{{count}} 年",
          },
          other_fees: "其他費用",
          payment_method: "付款方式",
          filling_details: "填寫詳細資料",
          trx_id: "交易ID",
          time_remain: "剩餘時間",
          hide: "隱藏",
          show: "顯示",
          product_description: "產品描述",
          order_value: "訂單金額",
          fee_102: "銀行手續費",
          fee_101: "稅",
          fee_100: "其他費用",
          discount: "折價",
          total: "總計",
          confirm: "確認",
          return: "返回",
          privacy_policy_error: "您必须閱讀並接受使用條款和隱私政策才能完成購買.",
          privacy_policy1: "我已閱讀並同意Pagsmile的",
          privacy_policy2: "使用條款",
          privacy_policy3: "和",
          privacy_policy4: "隱私政策.",
          privacy_policy5: "",
          pci_notice: "所有交易都得到安全保證並符合PCI DSS協議.",
          "payment.redirect.notice1": "您將在{{key}}秒後被重新導向到商家頁面.",
          "payment.redirect.notice2": "點擊",
          "payment.redirect.notice3": "此處",
          "payment.redirect.notice4": "取消.",
          back_shop: "返回購物",
          invalid_name: "無效姓名",
          cardholder_name: "持卡人姓名",
          exp_month: "到期月份",
          invalid_month: "選擇月份",
          exp_year: "到期年",
          invalid_year: "選擇年份",
          invalid_cvv: "無效的CVV",
          phone: "手機號碼",
          invalid_phone: "無效電話號碼",
          email: "電子郵件",
          invalid_email: "無效的電子郵件",
          address_billing: "帳單地址:",
          country: "國家/地區",
          invalid_country: "選擇國家/地區",
          state_city: "州/市",
          invalid_state_city: "無效的州/市",
          city: "城市",
          invalid_city: "選擇城市",
          street: "街道",
          invalid_street: "無效街道",
          number: "樓號",
          invalid_number: "無效樓號",
          select_country: "請先選擇國家/地區！",
          postal_code: "郵遞區號",
          invalid_postal_code: "無效的郵遞區號",
          address_delivery: "送貨地址:",
          address_same: "同帳單地址相同",
          details: "詳細資訊",
          card_no: "卡號",
          invalid_card_no: "無效卡號",
          expiry_month: "到期月份",
          invalid_month2: "無效月份",
          expiry_year: "到期年份",
          invalid_year2: "無效年份",
          credit_debit_card: "信用卡/借記卡",
          okay: "好！",
          network_err: "網路錯誤，請重試",
          fail_try_agin: "出了點問題，請稍後再試",
          fail_change_method: "出了點問題。請使用其他支付方式",
          fail_notice: "信息可能有誤，請重試或聯繫我們",
          payment_fail: "支付失敗了",
          failed: "失敗",
          success_result: "成功",
          success_notice: "您已成功支付訂單。請返回賣家頁面！感謝您使用 Pagsmile.",
          cancelled: "取消了",
          order_timeout: "您的付款期限已過，請返回賣家頁面重新下單",
          processing: "處理中",
          processing_notice: "交易正在進行中，會很快的。感謝您使用 Pagsmile！",
          success: "支付成功",
          refund_verifying: "驗證退款",
          refund_processing: "處理退款",
          risk_control: "控制風險",
          refunded: "退款了",
          partial_refund: "部分退款了",
          chargeback: "Chargeback",
          chargeback_reversed: "Chargeback Reversed",
          dispute: "爭議",
          dispute_reversed: "爭議被撤銷",
          timeout: "交易超時了",
          payment_refuse: "付款被拒絕了",
          refund_reverse: "退款撤銷了",
          refund_refuse: "退款被拒絕了",
          pay_close: "關閉了",
          fail_sec: "為了您的交易安全，該訂單已關閉，請重新提交",
          20000: "服務當前不可用",
          40000: "交易失敗了",
          40002: "交易失敗了",
          40012: "訂單不存在",
          40013: "訂單已結束",
          40014: "訂單資訊不一致",
          40015: "超出最大金額（包括手續費）",
          40101: "訂單超時了",
          40201: "上傳失敗",
          40301: "銀行忙",
          "SYS-0001": "您的付款已被拒絕。請選擇其他付款方式。我們建議您使用現金付款",
          "SYS-0002": "超時了",
          "SYS-0003": "無效交易金額",
          "SYS-0004": "付款人必须提供有效的電子郵箱地址",
          "CC-0001": "涉及無效的運營商用戶",
          "CC-0002": "無效的卡令牌",
          "CC-0003": "未決應急",
          "CC-0004": "待審核手冊",
          "CC-0005": "已拒絕，填錯的卡號",
          "CC-0006": "已拒絕，填寫日期錯誤",
          "CC-0007": "已拒絕，填錯其他",
          "CC-0008": "已拒絕，錯誤填寫的安全碼",
          "CC-0009": "已拒絕，在黑名單中",
          "CC-0010": "已拒絕，請求授權",
          "CC-0011": "已拒絕，卡禁用",
          "CC-0012": "已拒絕，卡片錯誤",
          "CC-0013": "已拒絕，重複支付",
          "CC-0014": "已拒絕，高風險",
          "CC-0015": "已拒絕，金額不足",
          "CC-0016": "已拒絕，無效分期付款",
          "CC-0017": "已拒絕，最大嘗試次數",
          "CC-0018": "已拒絕，其他原因",
          70001: "我們無法處理您的付款",
          80004: "用戶被列入黑名單",
          "70001-10001": "輸入的CPF無效，再輸入一個",
          "70001-10002": "這筆交易無法處理。聯繫客戶支援",
          "70001-10003": "這筆交易無法處理。聯繫客戶支援",
          "70001-10004": "這筆交易無法處理。聯繫客戶支援",
          "70001-20001": "超出交易限制",
          "70001-20003": "超出單筆交易限額",
          "70001-99999": "這筆交易無法處理。聯繫客戶支援",
          refresh_notice: "頁面將在 10 秒後自動刷新，否則請點擊下面的按鈕",
          refresh: "刷新",
          fail_query: "你想再試一次嗎？",
          yes: "是的",
          help_me: "幫幫我",
          source: "您購買產品的網站或APP名稱",
          timed_out: "交易請求超時！",
          no_channel: "暫無頻道，請聯絡商家",
          bank_agent: "選擇 銀行/代理",
          wallet: "錢包",
          mon_wallet: "錢包",
          name: "姓名",
          state: "州",
          invalid_state: "無效州",
          exp_date: "到期時間（月|年）",
          invalid_exp_date: "無效到期時間",
          invalid_TIN: "無效ID",
          bank_list: "銀行列表",
          bank_select: "選擇銀行",
          online_banking: "網路銀行",
          retail_outlet: "零售店",
          store_list: "选择商店",
          store_select: "選擇商店",
        },
      },
      jp: {
        translation: {
          en: "English",
          es: "Español",
          pt: "Português",
          ru: "Pусский",
          ar: "عربي",
          zh: "中文",
          "zh-TW": "繁體中文",
          jp: "日本語",
          ko: "한국어",
          tr: "Türkçe",
          wallet_inst1: "スキャンして支払う", // Japanese
          wallet_inst2: "スキャンして支払うには以下を使用します：", // Japanese
          wallet_inst3: "支払い方法は？", // Japanese
          wallet_inst4: "1. デバイスでウォレット/銀行アプリを開く", // Japanese
          "wallet_inst4.1": "1. デバイスで銀行アプリを開く", // Japanese
          wallet_inst5: "2. 「スキャン」機能をタップする", // Japanese
          wallet_inst6: "3. 支払いQRをスキャンして支払いを完了する", // Japanese
          wallet_inst7: "支払いには参照番号も使用できます。", // Japanese
          wallet_mob_ins1: "クリックして支払う", // Japanese
          wallet_mob_ins2: "クリックして支払うには以下を使用します：", // Japanese
          wallet_mob_ins3: "1. 下のボタンをクリックして携帯電話のウォレット/銀行アプリに移動する", // Japanese
          wallet_mob_ins4: "2. 支払いを完了するために進む", // Japanese
          bank_transfer: "銀行振込",
          warning: {
            crypto: {
              ETH: "ETH (ERC20)ネットワークでのみETHをこのアドレスに送信してください。",
              USDC: "ETH (ERC20)ネットワークでのみUSDCをこのアドレスに送信してください。",
              USDT: "ETH (ERC20)ネットワークでのみUSDTをこのアドレスに送信してください。",
              USDT_TRC20:
                "USDTはTRONネットワークのみを通じて送信してください。TRXの預金は受け付けていません。他のネットワークを使用するか、TRXでトップアップすると、資金が失われます。",
            },
          },
          subscriptions: {
            title: "これから購読手続きをします。",
            with_trial:
              "購読期間は <strong>{{subscription_period}}</strong> です。試用期間は <strong>{{trial_period}}</strong> で、<strong>{{trial_amount}}</strong> が請求されます。その後、<strong>{{interval_period}}</strong> ごとに <strong>{{interval_amount}}</strong> が請求されます。",
            without_trial:
              "購読期間は <strong>{{subscription_period}}</strong> です。各 <strong>{{interval_period}}</strong> ごとに <strong>{{interval_amount}}</strong> が請求されます。",
          },
          times: {
            day_one: "{{count}} 日",
            day_other: "{{count}} 日",
            week: "{{count}} 週間",
            week_other: "{{count}} 週間",
            month_one: "{{count}} ヶ月",
            month_other: "{{count}} ヶ月",
            year_one: "{{count}} 年",
            year_other: "{{count}} 年",
          },
          other_fees: "その他の料金",
          payment_method: "お支払い",
          filling_details: "お支払い情報",
          trx_id: "取引ID",
          time_remain: "残る時間",
          hide: "隠す",
          show: "示す",
          product_description: "商品情報",
          order_value: "入金金額",
          fee_102: "銀行料金",
          fee_101: "税",
          fee_100: "ほかの料金",
          discount: "割引",
          total: "合計",
          confirm: "確認",
          return: "戻す",
          privacy_policy_error: "購入を完了するには、利用規約とプライバシー ポリシーを同意する必要があります",
          privacy_policy1: "Pagsmileの",
          privacy_policy2: "利用規約",
          privacy_policy3: "と",
          privacy_policy4: "プライバシー ポリシーに同意する",
          privacy_policy5: "",
          pci_notice: "すべての取引は安全であり、PCI DSSプロトコルに準拠しています",
          "payment.redirect.notice1": "{{key}}秒後にお店のページに戻されます",
          "payment.redirect.notice2": "クリック",
          "payment.redirect.notice3": "こちらへ",
          "payment.redirect.notice4": "キャンセル",
          back_shop: "お買い物に戻す",
          invalid_name: "氏名無効",
          cardholder_name: "カード会員の氏名",
          exp_month: "月間切れ",
          invalid_month: "月間無効",
          exp_year: "年間切れ",
          invalid_year: "年間無効",
          invalid_cvv: "CVV無効",
          phone: "電話番号",
          invalid_phone: "電話無効",
          email: "メール",
          invalid_email: "メール無効",
          address_billing: "請求先住所",
          country: "国家/地区",
          invalid_country: "国家/地区を選ぶ",
          state_city: "都/道/府/县",
          invalid_state_city: "都/道/府/县を選ぶ",
          city: "市/町/村",
          invalid_city: "市/町/村を選ぶ",
          street: "街区",
          invalid_street: "街区無効",
          number: "ビル番号",
          invalid_number: "ビル番号無効",
          select_country: "国家/地区を選んでください",
          postal_code: "郵便番号",
          invalid_postal_code: "郵便番号無効",
          address_delivery: "配送先住所",
          address_same: "請求先住所と同じ",
          details: "詳細な説明",
          card_no: "カード番号",
          invalid_card_no: "カード番号無効",
          expiry_month: "月間切れ",
          invalid_month2: "月間無効",
          expiry_year: "年間切れ",
          invalid_year2: "年間無効",
          credit_debit_card: "クレジットカード/デビットカード",
          okay: "完了",
          network_err: "ネットワークエラーですが、もう一度お試しください",
          fail_try_agin: "何かが間違っていますが、しばらくもう一度お試しください",
          fail_change_method: "何かが間違っていますが、ほかのお支払いを選んでください",
          fail_notice: "お支払い情報が間違っていますが、もう一度お試しいただくか、お問い合わせください",
          payment_fail: "決済失敗",
          failed: "失敗",
          success_result: "成功",
          success_notice:
            "ご注文の支払いが完了しましたが、お店のページに戻ってください。Pagsmileをご利用いただきありがとうございます！",
          cancelled: "キャンセルしました",
          order_timeout: "お支払い期限が切れましたが、お店のページに戻って再度ご注文ください。",
          processing: "処理中",
          processing_notice: "お取引は進行中であり、少少お待ちください。Pagsmileをご利用いただきありがとうございます！",
          success: "決済成功",
          refund_verifying: "返金確認",
          refund_processing: "返金処理",
          risk_control: "リスクコントロール",
          refunded: "返金完了",
          partial_refund: "部分返金完了",
          chargeback: "チャージバック",
          chargeback_reversed: "チャージバック取消",
          dispute: "異議",
          dispute_reversed: "異議取消",
          timeout: "タイムアウト",
          payment_refuse: "決済拒否",
          refund_reverse: "返金取消",
          refund_refuse: "返金拒否",
          pay_close: "終了",
          fail_sec: "お取引の安全性を確保するため、この注文は終了しました。もう一度お試しください。",
          20000: "サービス利用停止",
          40000: "決済失敗",
          40002: "決済失敗",
          40012: "注文無し",
          40013: "注文终了",
          40014: "注文情報不一致",
          40015: "上限金額を超えました（手数料込み）",
          40101: "注文タイムアウト",
          40201: "アップロード失敗",
          40301: "銀行が忙しいです",
          "SYS-0001": "お支払いは拒否されましたが、ほかのお支払いを選んでください。現金でのお支払いをお勧めします。",
          "SYS-0002": "タイムアウト",
          "SYS-0003": "金額無効",
          "SYS-0004": "有効なメールアドレスを提供する必要があります",
          "CC-0001": "無効な通信事業者ユーザーが関与しています",
          "CC-0002": "カードトークン無効",
          "CC-0003": "未决应急",
          "CC-0004": "ハンドブックは審査待ち",
          "CC-0005": "拒否，カード番号エラー",
          "CC-0006": "拒否，日付エラー",
          "CC-0007": "拒否，ほかのエラー",
          "CC-0008": "拒否，セキュリティコードエラー",
          "CC-0009": "拒否，ブラックリストユーザー",
          "CC-0010": "拒否，権限を授ける必要があります",
          "CC-0011": "拒否，カード使用禁止",
          "CC-0012": "拒否，カードエラー",
          "CC-0013": "拒否，決済重複",
          "CC-0014": "拒否，高リスク",
          "CC-0015": "拒否，残高不足",
          "CC-0016": "拒否，分割払い無効",
          "CC-0017": "拒否，最大注文回数",
          "CC-0018": "拒否，ほかの原因",
          70001: "お支払いを処理できませんでした",
          80004: "ユーザーがブラックリストに登録されています",
          "70001-10001": "このCPFは無効ですが，もう一度お試しください",
          "70001-10002": "この取引は処理できませんでしたが、お問い合わせください",
          "70001-10003": "この取引は処理できませんでしたが、お問い合わせください",
          "70001-10004": "この取引は処理できませんでしたが、お問い合わせください",
          "70001-20001": "取引制限を超えました",
          "70001-20003": "単一トランザクションの制限を超えました",
          "70001-99999": "この取引は処理できませんでしたが、お問い合わせください",
          refresh_notice:
            "ページは 10 秒後に自動的にリフレッシュされます。そうでない場合は、下のボタンをクリックしてください。",
          refresh: "リフレッシュ",
          fail_query: "もう一度試してみますか?",
          yes: "はい",
          help_me: "ヘルプ",
          source: "商品を購入したサイトまたはアプリの氏名",
          timed_out: "タイムアウト",
          no_channel: "チャネル利用停止、商家にお問い合わせください",
          bank_agent: "銀行/代理を選ぶ",
          wallet: "電子ウォレット",
          name: "氏名",
          state: "都/道/府/县",
          invalid_state: "都/道/府/县無効",
          exp_date: "期限切れ",
          invalid_exp_date: "期限切れ無効",
          invalid_TIN: "ID無効",
          ecp_notice1: "この購入はカードの明細に表示されます",
          ecp_notice2: '"SMILE.ONE PORTUGAL"',
          first_name: "名",
          last_name: "姓",
          help_contact: "お問い合わせ: ",
        },
      },
      ko: {
        translation: {
          en: "English",
          es: "Español",
          pt: "Português",
          ru: "Pусский",
          ar: "عربي",
          zh: "中文",
          "zh-TW": "繁體中文",
          jp: "日本語",
          ko: "한국어",
          tr: "Türkçe",
          bank_transfer: "은행 송금",
          wallet_inst1: "스캔하여 결제", // Korean
          wallet_inst2: "다음을 사용하여 스캔하여 결제할 수 있습니다:", // Korean
          wallet_inst3: "결제 방법?", // Korean
          wallet_inst4: "1. 장치에서 지갑/은행 앱을 엽니다", // Korean
          "wallet_inst4.1": "1. 장치에서 은행 앱을 엽니다", // Korean
          wallet_inst5: "2. “스캔” 기능을 탭 합니다", // Korean
          wallet_inst6: "3. 결제 QR을 스캔하고 결제를 완료합니다", // Korean
          wallet_inst7: "참조 번호를 사용하여 결제할 수도 있습니다.", // Korean
          wallet_mob_ins1: "클릭하여 결제", // Korean
          wallet_mob_ins2: "다음을 사용하여 클릭하여 결제할 수 있습니다:", // Korean
          wallet_mob_ins3: "1. 아래 버튼을 클릭하여 휴대전화에서 지갑/은행 앱으로 이동합니다", // Korean
          wallet_mob_ins4: "2. 결제를 완료하기 위해 계속 진행합니다", // Korean
          warning: {
            crypto: {
              ETH: "이 주소로 ETH (ERC20) 네트워크에서만 ETH를 보내십시오.",
              USDC: "이 주소로 ETH (ERC20) 네트워크에서만 USDC를 보내십시오.",
              USDT: "이 주소로 ETH (ERC20) 네트워크에서만 USDT를 보내십시오.",
              USDT_TRC20:
                "USDT는 TRON 네트워크를 통해서만 보내주십시오. TRX 입금은 허용되지 않습니다. 다른 네트워크 사용이나 TRX로 충전 시 자금 손실이 발생할 수 있습니다.",
            },
          },
          other_fees: "기타 수수료",
          subscriptions: {
            title: "구독을 하려고 합니다. ",
            with_trial:
              "구독 기간은 <strong>{{subscription_period}}</strong>입니다. <strong>{{trial_period}}</strong> 동안 <strong>{{trial_amount}}</strong>이 청구되며, 그 후 <strong>{{interval_period}}</strong>마다 <strong>{{interval_amount}}</strong>가 청구됩니다.",
            without_trial:
              "구독 기간은 <strong>{{subscription_period}}</strong>입니다. 매 <strong>{{interval_period}}</strong>마다 <strong>{{interval_amount}}</strong>가 청구됩니다。",
          },
          times: {
            day_one: "{{count}} 일",
            day_other: "{{count}} 일",
            week: "{{count}} 주",
            week_other: "{{count}} 주",
            month_one: "{{count}} 개월",
            month_other: "{{count}} 개월",
            year_one: "{{count}} 년",
            year_other: "{{count}} 년",
          },
          info_fill: {
            common: "아래 정보를 입력하세요.",
            bank: "은행을 선택하세요.",
            wallet: "지갑을 선택하세요.",
            crypto: "암호화폐를 선택하세요.",
          },
          channels: {},
          methods: {
            CreditCard: "신용카드",
            Wallet: "지갑",
          },
          alert: {
            title: {
              error: "에러",
              success: "성공",
              notice: "공지",
              warning: "경고",
              info: "알림",
              unspecific: "알림",
            },
          },
          payment_via: "{{key}}로 결제하기",
          checkout: {
            user: "사용자",
            how_to_pay: "결제는 어떻게 하나요？",
            here: "이 곳",
            title: {
              common: "{{user}}님, 결제를 완료하기 위해 다음 계좌를 이용해 결제하세요.{{value}}",
              deposit: "{{user}}님, 결제를 완료하기 위해 {{value}}결제후 영수증을 보내주시길 바랍니다.",
              deposit_proof: "{{user}}님, 환영합니다.",
            },
            alert: {
              qr: "결제하실 어플을 실행한 후 아래 QR코드를 스캔하세요.",
              barcode: "바코드를 보여주세요.",
            },
            qr: {
              how_to: {
                desktop: {
                  p1: "결제하실 어플을 실행하세요.",
                  p2: "“스캔”기능을 통해 코드를 스캔합니다.",
                },
                mobile: {
                  p1: "상단의 QR코드를 복사합니다.",
                  p2: "결제하실 어플을 실행합니다.",
                  p3: "“스캔”기능을 통해 복사하신 코드를 스캔합니다.",
                  p4: "하단 버튼을 통해 모바일 결제 페이지로 이동합니다.",
                  go_app: "어플로 이동하기",
                },
              },
            },
          },
          result: {
            success: {
              mainTitle: "결제를 완료하였습니다！ 🎉",
              subTitles: { 0: "결제성공" },
              description: {
                notice: "결제를 완료하였습니다. 판매 페이지로 돌아가세요.！Pagsmile을 이용해주셔서 감사합니다.",
              },
            },
            fail: {
              mainTitle: "문제가 발생했습니다 😕",
              subTitles: {
                0: "결제가 실패했습니다.",
                1: "결제가 거부되었습니다.",
                2: "환불이 거부되었습니다.",
                3: "페이지가 종료되었습니다.",
                4: "결제가 실패했습니다.",
              },
              description: {
                fail_sec: "안전한 결제를 위해 해당 주문내역이 종료되었습니다. 다시 시도해주세요.",
              },
              20000: "현재 서비스를 사용할 수 없습니다.",
              40000: "결제가 실패했습니다.",
              40002: "결제가 실패했습니다.",
              40012: "주문이 존재하지 않습니다.",
              40013: "주문이 완료되었습니다.",
              40014: "주문정보가 일치하지 않습니다.",
              40015: "최대한도가 초과되었습니다(수수료포함)",
              40101: "주문 시간이 초과되었습니다.",
              40201: "업로드에 실패하였습니다.",
              40301: "은행의 데이터 처리가 길어지고 있습니다.",
              "SYS-0001": "결제가 거부되었습니다. 다른 결제수단을 선택해주세요. 현금 결제를 추천합니다.",
              "SYS-0002": "시간이 초과되었습니다.",
              "SYS-0003": "금액이 올바르지 않습니다.",
              "SYS-0004": "결제시 반드시 유효한 이메일 주소를 제공해야 합니다.",
              "CC-0001": "잘못된 통신사 사용자가 관련됨.",
              "CC-0002": "잘못된 카드 토큰",
              "CC-0003": "임시보류",
              "CC-0004": "심사 매뉴얼",
              "CC-0005": "거부됨, 잘못된 카드 번호",
              "CC-0006": "거부됨, 잘못된 일자",
              "CC-0007": "거부됨, 알맞지 않은 내용",
              "CC-0008": "거부됨, 잘못된 보안 코드",
              "CC-0009": "거부됨, 블랙리스트 등재",
              "CC-0010": "거부됨, 권한 요청",
              "CC-0011": "거부됨, 사용금지된 카드",
              "CC-0012": "거부됨, 카드 오류",
              "CC-0013": "거부됨, 중복결제",
              "CC-0014": "거부됨, 하이리스크",
              "CC-0015": "거부됨, 잔액부족",
              "CC-0016": "거부됨, 유효하지 않은 할부결제",
              "CC-0017": "거부됨, 최대 시도 횟수",
              "CC-0018": "거부됨, 기타 사유",
              70001: "결제를 처리할 수 없습니다.",
              80004: "사용자가 블랙리스트 명단에 있습니다.",
              "70001-10001": "입력한 CPF가 잘못되었습니다. 다시 입력하세요.",
              "70001-10002": "이 결제는 처리할 수 없습니다. 고객센터에 연락하세요.",
              "70001-10003": "이 결제는 처리할 수 없습니다. 고객센터에 연락하세요.",
              "70001-10004": "이 결제는 처리할 수 없습니다. 고객센터에 연락하세요.",
              "70001-20001": "거래제한초과",
              "70001-20003": "단일 거래 한도를 초과했습니다.",
              "70001-99999": "이 결제는 처리할 수 없습니다. 고객센터에 연락하세요",
            },
            process: {
              mainTitle: "알겠습니다! 구매에 약간의 시간이 필요합니다 😊",
              subTitles: {
                0: "결제 처리하기",
                1: "대부분 결제완료",
                2: "소액 결제완료",
                3: "환불 확인하기",
                4: "환불 처리하기",
                5: "리스크 관리하기",
              },
              description: {
                notice: "결제가 곧 완료됩니다. Pagsmile을 이용해 주셔서 감사합니다！",
              },
            },
            other: {
              mainTitle: "문제가 발생했습니다 😕",
              subTitles: {
                0: "",
                1: "취소되었습니다.",
                2: "환불되었습니다.",
                3: "일부 환불되었습니다.",
                4: "환불이 취소되었습니다.",
                5: "Chargeback",
                6: "Chargeback Reversed",
                7: "조정",
                8: "조정이 철회되었습니다.",
                9: "주문 시간이 초과되었습니다.",
              },
              description: {
                order_timeout: "결제 기한이 지났습니다. 판매자 페이지로 돌아가 다시 주문해 주세요.",
              },
            },
          },
          powered_by: "Powered by",
          terms_privacy: "Terms and Privacy",
          pay_within: "Pay within",
          wrong_format: "Wrong format",
          close: "Close",
          payment_method: "결제방식",
          filling_details: "상세한 내용을 기입하세요.",
          trx_id: "결제ID",
          time_remain: "남은시간",
          hide: "숨기기",
          show: "표시하기",
          product_description: "상품 상세",
          order_value: "주문금액",
          fee_102: "은행 수수료",
          fee_101: "세금",
          fee_100: "기타 비용",
          discount: "할인",
          total: "총 금액",
          confirm: "확인",
          return: "돌아가기",
          privacy_policy_error: "이용약관 및 개인정보 보호정책을 반드시 읽고 동의하셔야 구매가 완료됩니다",
          privacy_policy1: "Pagsmile 약관 및 정책을 확인하였으며 동의합니다.",
          privacy_policy2: "이용약관",
          privacy_policy3: "와(과)",
          privacy_policy4: "보호정책.",
          privacy_policy5: "",
          pci_notice: "모든 거래는 안전 보장을 받으며 PCI DSS 협의를 준수합니다.",
          "payment.redirect.notice1": "{{key}}초 후에 상점 페이지로 이동합니다.",
          "payment.redirect.notice2": "클릭",
          "payment.redirect.notice3": "이곳",
          "payment.redirect.notice4": "취소",
          back_shop: "구매 페이지로 돌아가기",
          invalid_name: "유효하지 않은 이름입니다.",
          cardholder_name: "소유자 성명",
          exp_month: "만료되는 월",
          invalid_month: "월을 선택하세요.",
          exp_year: "만료되는 해",
          invalid_year: "연도를 선택하세요.",
          invalid_cvv: "유효하지 않은 CVV",
          phone: "핸드폰 번호",
          invalid_phone: "유효하지 않은 전화번호",
          email: "이메일 주소",
          invalid_email: "유효하지 않은 이메일 주소",
          address_billing: "청구서 주소",
          country: "국가/지역",
          invalid_country: "국가/지역을 선택하세요.",
          state_city: "시/도",
          invalid_state_city: "유효하지 않은 시/도",
          city: "시",
          invalid_city: "시를 선택하세요.",
          street: "도로명 주소를 입력하세요.",
          invalid_street: "유효하지 않은 도로명 주소",
          number: "동/호수를 입력하세요.",
          invalid_number: "유효하지 않은 동/호수",
          select_country: "국가/지역을 선택하세요！",
          postal_code: "우편번호",
          invalid_postal_code: "유효하지 않은 우편번호",
          address_delivery: "배송주소",
          address_same: "청구서 주소와 동일",
          details: "상세 정보",
          card_no: "카드번호",
          invalid_card_no: "유효하지 않은 카드번호",
          expiry_month: "만료되는 월",
          invalid_month2: "유효하지 않은 월",
          expiry_year: "만료되는 연도",
          invalid_year2: "유효하지 않은 연도",
          credit_debit_card: "신용카드/체크카드",
          okay: "OK！",
          network_err: "네트워크 오류, 다시 시도하세요.",
          fail_try_agin: "문제가 발생했습니다. 나중에 다시 시도하세요.",
          fail_change_method: "문제가 발생했습니다. 다른 결제방식을 선택하세요.",
          fail_notice: "정보가 잘못되었을 수 있습니다. 다시 시도하거나 고객센터에 문의하세요.",
          payment_fail: "결제가 실패했습니다.",
          failed: "실패",
          success_result: "성공",
          success_notice:
            "주문이 성공적으로 결제되었습니다. 판매자 페이지로 돌아가십시오! Pagsmile을 이용해 주셔서 감사합니다.",
          cancelled: "취소 되었습니다.",
          order_timeout: "결제 시간이 초과되었습니다. 판매자 페이지로 돌아가 다시 시도해주세요.",
          processing: "처리중",
          processing_notice: "결제가 곧 완료됩니다. Pagsmile을 사용해주셔서 감사합니다! ",
          success: "결제성공",
          refund_verifying: "환불심사",
          refund_processing: "환불처리중",
          risk_control: "리스크 관리",
          refunded: "환불이 완료되었습니다.",
          partial_refund: "일부 환불되었습니다.",
          chargeback: "Chargeback",
          chargeback_reversed: "Chargeback Reversed",
          dispute: "조정",
          dispute_reversed: "조정이 철회되었습니다.",
          timeout: "거래 시간 초과",
          payment_refuse: "결제가 거절되었습니다.",
          refund_reverse: "환불이 철회되었습니다.",
          refund_refuse: "환불이 거절되었습니다.",
          pay_close: "종료되었습니다.",
          fail_sec: "안전한 거래를 위해 주문이 종료되었습니다. 다시 시도해주세요.",
          refresh_notice: "페이지는 10초 후에 자동 새로 고침됩니다. 안될경우 아래 버튼을 클릭하세요.",
          refresh: "새로고침",
          fail_query: "다시 시도하시겠습니까?",
          yes: "네",
          help_me: "도와주세요",
          source: "구매한 제품의 웹사이트 또는 앱 이름이 무엇인가요?",
          timed_out: "거래 요청 시간이 초과되었습니다!",
          no_channel: "현재 채널이 없습니다. 판매자에게 문의하세요.",
          bank_agent: "은행/에이전시를 선택하세요.",
          mon_wallet: "지갑",
          name: "이름",
          state: "주",
          invalid_state: "유효하지 않은 주",
          exp_date: "만료 기간 (월|년)",
          invalid_exp_date: "유효하지 않은 만료 기간",
          invalid_TIN: "유효하지 않은 ID",
          name_on_card: "카드 소지자 이름",
          credit_card: "신용카드",
          wallet: "지갑",
          online_banking: "온라인 뱅킹",
          help_chat: "문의하기",
          virtual_account: "가상계좌",
          20000: "현재 서비스를 사용할 수 없습니다.",
          40000: "결제가 실패했습니다.",
          40002: "결제가 실패했습니다.",
          40012: "주문이 존재하지 않습니다.",
          40013: "주문이 완료되었습니다.",
          40014: "주문정보가 일치하지 않습니다.",
          40015: "최대한도가 초과되었습니다(수수료포함)",
          40101: "주문 시간이 초과되었습니다.",
          40201: "업로드에 실패하였습니다.",
          40301: "은행의 데이터 처리가 길어지고 있습니다.",
          "SYS-0001": "결제가 거부되었습니다. 다른 결제수단을 선택해주세요. 현금 결제를 추천합니다.",
          "SYS-0002": "시간이 초과되었습니다.",
          "SYS-0003": "금액이 올바르지 않습니다.",
          "SYS-0004": "결제시 반드시 유효한 이메일 주소를 제공해야 합니다.",
          "CC-0001": "잘못된 통신사 사용자가 관련됨.",
          "CC-0002": "잘못된 카드 토큰",
          "CC-0003": "임시보류",
          "CC-0004": "심사 매뉴얼",
          "CC-0005": "거부됨, 잘못된 카드 번호",
          "CC-0006": "거부됨, 잘못된 일자",
          "CC-0007": "거부됨, 알맞지 않은 내용",
          "CC-0008": "거부됨, 잘못된 보안 코드",
          "CC-0009": "거부됨, 블랙리스트 등재",
          "CC-0010": "거부됨, 권한 요청",
          "CC-0011": "거부됨, 사용금지된 카드",
          "CC-0012": "거부됨, 카드 오류",
          "CC-0013": "거부됨, 중복결제",
          "CC-0014": "거부됨, 하이리스크",
          "CC-0015": "거부됨, 잔액부족",
          "CC-0016": "거부됨, 유효하지 않은 할부결제",
          "CC-0017": "거부됨, 최대 시도 횟수",
          "CC-0018": "거부됨, 기타 사유",
          70001: "결제를 처리할 수 없습니다.",
          80004: "사용자가 블랙리스트 명단에 있습니다.",
          "70001-10001": "입력한 CPF가 잘못되었습니다. 다시 입력하세요.",
          "70001-10002": "이 결제는 처리할 수 없습니다. 고객센터에 연락하세요.",
          "70001-10003": "이 결제는 처리할 수 없습니다. 고객센터에 연락하세요.",
          "70001-10004": "이 결제는 처리할 수 없습니다. 고객센터에 연락하세요.",
          "70001-20001": "거래제한초과",
          "70001-20003": "단일 거래 한도를 초과했습니다.",
          "70001-99999": "이 결제는 처리할 수 없습니다. 고객센터에 연락하세요",
        },
      },
      tr: {
        translation: {
          ...privacyEn,
          ...termsOfServiceEn,
          en: "English",
          es: "Español",
          pt: "Português",
          ru: "Pусский",
          ar: "عربي",
          zh: "中文",
          "zh-TW": "繁體中文",
          tr: "Türkçe",
          jp: "日本語",
          ko: "한국어",
          bank_transfer: "Banka Havalesi",
          wallet_inst1: "Tara ve öde", // Turkish
          wallet_inst2: "Şunu kullanarak tarayıp ödeyebilirsiniz:", // Turkish
          wallet_inst3: "Nasıl ödenir?", // Turkish
          wallet_inst4: "1. Cihazınızda cüzdan/banka uygulamanızı açın", // Turkish
          "wallet_inst4.1": "1. Cihazınızda banka uygulamanızı açın", // Turkish
          wallet_inst5: "2. “Tara” özelliğine dokunun", // Turkish
          wallet_inst6: "3. Ödeme QR kodunu tarayın ve ödemeyi tamamlayın", // Turkish
          wallet_inst7: "Ödeme yapmak için referans numarası da kullanılabilir.", // Turkish
          wallet_mob_ins1: "Tıkla ve öde", // Turkish
          wallet_mob_ins2: "Şunu kullanarak tıklayıp ödeyebilirsiniz:", // Turkish
          wallet_mob_ins3: "1. Telefonunuzda cüzdan/banka uygulamasına gitmek için aşağıdaki butona tıklayın", // Turkish
          wallet_mob_ins4: "2. Ödemeyi tamamlamak için devam edin", // Turkish
          warning: {
            crypto: {
              ETH: "Bu adrese yalnızca ETH (ERC20) ağı üzerinden ETH gönderin.",
              USDC: "Bu adrese yalnızca ETH (ERC20) ağı üzerinden USDC gönderin.",
              USDT: "Bu adrese yalnızca ETH (ERC20) ağı üzerinden USDT gönderin.",
              USDT_TRC20:
                "Lütfen USDT'yi yalnızca TRON Ağı üzerinden gönderin. TRX yatırımı KABUL edilmez. Diğer ağları kullanmak veya TRX ile yükleme yapmak fonlarınızın kaybolmasına neden olacaktır.",
            },
          },
          subscriptions: {
            title: "Abone olmaya hazırlanıyorsunuz.",
            with_trial:
              "Abonelik süresi <strong>{{subscription_period}}</strong>. Deneme süresi için <strong>{{trial_period}}</strong> boyunca <strong>{{trial_amount}}</strong> tahsil edilecek, ardından <strong>{{interval_amount}}</strong> her <strong>{{interval_period}}</strong> için faturalandırılacak.",
            without_trial:
              "Abonelik süresi <strong>{{subscription_period}}</strong>. Her <strong>{{interval_period}}</strong> için <strong>{{interval_amount}}</strong> tahsil edilecek.",
          },
          times: {
            day_one: "{{count}} gün",
            day_other: "{{count}} gün",
            week: "{{count}} hafta",
            week_other: "{{count}} hafta",
            month_one: "{{count}} ay",
            month_other: "{{count}} ay",
            year_one: "{{count}} yıl",
            year_other: "{{count}} yıl",
          },
          other_fees: "Diğer ücretler",
          select_bank: "Bankanı seç",
          search: "Ara",
          help_contact: "Destek için bize ulaşın:",
          faq: "Sıkça sorulan sorular",
          help_chat: "Destek hattı",
          okay: "Tamam!",
          select_notice: "Ödeme Yöntemi/Acenta seçiniz",
          payment_method: "Ödeme Yöntemi",
          bank_agent: "Banka/Acenta seçiniz",
          filling_details: "Ödeme bilgileri",
          fill_info: "Bilgilerini doldur",
          details: "Detaylar",
          name: "İsım",
          email: "E-posta",
          phone: "Cep Telefonu",
          time_remain: "Kalan zaman:",
          trx_id: "İşlem numarası",
          hide: "Sakla",
          show: "Göster",
          product_description: "Ürün açıklaması:",
          order_value: "Sipariş ücreti",
          fee_102: "Banka ücreti",
          notice_102: "",
          fee_101: "Vergi",
          fee_100: "Diğer ücretler",
          notice_100: "",
          fee_104: "Ülke",
          discount: "İndirim",
          total: "Toplam",
          confirm: "Onayla",
          return: "Geri dön",
          privacy_policy_error:
            "Satın alma işleminizi tamamlamak için Kullanım Koşulları ve Gizlilik Politikasını okuyup kabul etmelisiniz.",
          privacy_policy1: "Pagsmile",
          privacy_policy2: " Kullanım Koşulları",
          privacy_policy3: " ve",
          privacy_policy4: " Gizlilik Politikasını",
          privacy_policy5: " okudum ve kabul ediyorum",
          invalid_name: "Geçersiz isim",
          invalid_TIN: "Geçersiz ID",
          invalid_email: "Geçersiz e-posta",
          invalid_phone: "Geçersiz numara",
          pay: "Öde:",
          pay2: "Öde",
          processing: "İşleniyor",
          processing_notice: "İşlem devam ediyor. Pagsmile'ı kullandığınız için teşekkür ederiz!",
          refund_in_process: "İade işlemi devam ediyor. Pagsmile'ı kullandığınız için teşekkür ederiz!",
          success: "Ödeme başarılı",
          success_result: "Başarılı",
          success_notice:
            "You have paid the order successfully. Please return to seller page! Thank you for using Pagsmile.",
          cancelled: "Cancelled",
          order_timeout:
            "Siparişi başarıyla ödediniz. Lütfen satıcı sayfasına geri dönün! Pagsmile'ı kullandığınız için teşekkür ederiz.",
          failed: "Başarısız",
          copied: "Başarıyla Kopyalandı",
          copy: "Kopyala",
          visit_us: "Hakkımızda daha fazla bilgi edinmek için ziyaret edin",
          fail_notice: "Bir bilgi hatalı olabilir, tekrar deneyiniz veya bize ulaşınız.",
          fail_query: "Tekrar denemek ister misiniz?",
          credit_debit_card: "Kredi/Banka Kartı",
          credit_card: "Kredi Kartı",
          debit_card: "Banka Kartı",
          wallet: "Dijital Cüzdan",
          yes: "Evet",
          help_me: "Yardımcı ol",
          order: "Sipariş",
          back_shop: "Alışverişe geri dön",
          cardholder_name: "Kart sahibinin tam adı",
          card_no: "Kart numarası",
          invalid_card_no: "Geçersiz kart numarası",
          exp_year: "Geç. Yılı",
          exp_date: "Son kullanma tarihi (MM/YY)",
          invalid_exp_date: "Geçersiz son kullanma tarihi",
          invalid_year: "Bir yıl seçin",
          exp_month: "Geç. Ayı",
          month: "ay",
          invalid_month: "Bir ay seçin",
          state: "Eyalet",
          invalid_state: "Bir eyalet seçin",
          city: "Şehir",
          invalid_city: "Bir şehir seçin",
          postal_code: "Posta kodu",
          invalid_postal_code: "Geçersiz posta kodu",
          street: "Cadde",
          invalid_street: "Geçersiz cadde",
          number: "Kapı numarası",
          invalid_number: "Geçersiz numara",
          source: "Ürünü satın aldığınız uygulamanın web sitesi veya adı",
          invalid_source: "Geçersiz",
          source_notice: "Yanlış doldurma durumunda ödeme başarısız olabilir",
          installments: "Taksitler",
          invalid_cvv: "Geçersiz CVV",
          paid_major: "Büyük kısmı ödendi",
          paid_minor: "Küçük kısmı ödendi",
          refund_verifying: "Geri ödeme doğrulanıyor",
          refund_processing: "Geri ödeme işleniyor",
          risk_control: "Riskli işlemler kontrol etdiliyor",
          refunded: "Geri ödeme yapıldı",
          partial_refund: "Kısmi geri ödeme yapıldı",
          chargeback: "Geri ödeme",
          chargeback_reversed: "Geri Ödeme Geri Alındı",
          dispute: "Anlaşmazlık",
          dispute_reversed: "Anlaşmazlık geri alındı",
          timeout: "İşlem zaman aşımına uğradı",
          payment_refuse: "Ödeme reddedildi",
          payment_fail: "Ödeme başarısız oldu",
          refund_reverse: "Geri ödeme geri alındı",
          refund_refuse: "Geri ödeme reddedildi",
          pay_close: "İşlem kapatıldı",
          fail_sec: "İşleminizin güvenliği açısından bu işlem iptal edilmiştir. Lütfen tekrar gönderin",
          fail_try_agin: "Bir şeyler ters gitti, lütfen daha sonra tekrar deneyin",
          fail_change_method: "Bir şeyler ters gitti. Lütfen başka bir ödeme yöntemi kullanın",
          fail_upload_again: "Bir şeyler ters gitti. Lütfen yüklemeyi tekrar deneyin",
          fail_upload: "Yükleme başarısız oldu",
          timed_out: "İşlem isteği zaman aşımına uğradı!",
          no_channel: "Ödeme kanalı yok, lütfen satıcıyla iletişime geçin",
          id: "Kimlik numarası: ",
          amount: "Tutar: ",
          due_date: "Son tarih",
          select_country: "Lütfen önce bir ülke seçin!",
          country: "Ülke",
          invalid_country: "Bir ülke seçin",
          state_city: "Eyalet/Şehir",
          invalid_state_city: "Geçersiz Eyalet/Şehir",
          refresh: "Yenile",
          refresh_notice:
            "Sayfa 10 saniye içinde otomatik olarak yenilenecektir, aksi takdirde lütfen aşağıdaki butona tıklayın.",
          network_err: "Ağ hatası, lütfen tekrar deneyin.",
          address_billing: "Fatura adresi:",
          address_delivery: "Teslimat adresi:",
          address_same: "Fatura adresiyle aynı",
          agent: "Acente",
          agent_invalid: "Geçersiz acente",
          agent_select: "Bir acente seçin",
          address: "Adres",
          invalid_address: "Geçersiz adres",
          fill_all: "Lütfen gerekli tüm alanları doldurun",
          crypto: "Kripto Paralar",
          choose_token: "Token seçin",
          pci_notice: "Tüm işlemlerin güvenliği garanti edilir ve PCI DSS protokolüyle uyumludur.",
          20000: "Hizmet şu anda kullanılamıyor",
          40000: "İşlem başarısız oldu",
          40002: "İşlem başarısız oldu",
          40012: "Sipariş mevcut değil",
          40013: "Sipariş sona erdi",
          40014: "Sipariş bilgileri tutarsız",
          40015: "Maksimum tutar (ücretler dahil) aşıldı",
          40101: "Sipariş zaman aşımı",
          40201: "Yükleme başarısız oldu",
          40301: "Banka meşgul",
          "SYS-0001": "Ödemeniz reddedildi. Başka bir ödeme yöntemi seçin. Nakit ödeme yöntemlerini öneriyoruz.",
          "SYS-0002": "Zaman aşımı",
          "SYS-0003": "Geçersiz işlem tutarı",
          "SYS-0004": "Ödeme yapanın geçerli bir e-posta adresi sağlaması gerekir",
          "CC-0001": "Geçersiz operatör kullanıcıları dahil",
          "CC-0002": "Geçersiz kart belirteci",
          "CC-0003": "Bekleyen beklenmedik durum",
          "CC-0004": "Kılavuzun incelenmesi bekleniyor",
          "CC-0005": "Reddedildi, hatalı doldurulmuş kart numarası",
          "CC-0006": "Reddedildi, hatalı doldurulmuş tarih",
          "CC-0007": "Reddedildi, hatalı doldurulmuş diğer",
          "CC-0008": "Reddedildi, hatalı doldurulmuş güvenlik kodu",
          "CC-0009": "Reddedildi, kara listede",
          "CC-0010": "Reddedildi, yetki çağrısı",
          "CC-0011": "Reddedildi, kart devre dışı bırakıldı",
          "CC-0012": "Reddedildi, kart hatası",
          "CC-0013": "Reddedildi, yinelenen ödeme",
          "CC-0014": "Reddedildi, yüksek risk",
          "CC-0015": "Reddedildi, tutar yetersiz",
          "CC-0016": "Reddedildi, geçersiz taksitler",
          "CC-0017": "Reddedildi, maksimum deneme sayısı",
          "CC-0018": "Reddedildi, başka bir neden",
          70001: "Ödemenizi gerçekleştiremiyoruz.",
          80004: "Kullanıcı kara listeye alındı",
          "70001-10001": "Girilen CPF geçersiz, başka bir tane girin",
          "70001-10002": "Bu işlem gerçekleştirilemiyor. Müşteri Destek Hattı ile iletişime geçin",
          "70001-10003": "Bu işlem gerçekleştirilemiyor. Müşteri Destek Hattı ile iletişime geçin",
          "70001-10004": "Bu işlem gerçekleştirilemiyor. Müşteri Destek Hattı ile iletişime geçin",
          "70001-20001": "İşlem limiti aşıldı",
          "70001-20003": "Tek işlem limiti aşıldı",
          "70001-99999": "Bu işlem gerçekleştirilemiyor. Müşteri Destek Hattı ile iletişime geçin",
        },
      },
    },
  });

export default i18n;
