import i18n from "../../language/i18n";
import { latinCountries } from "../configurations";

export const setLiveChat = (region) => {
  const key = "199c940a-0e3e-4079-a474-c775b52fcb79";
  let formID = "26415773889556"; // default english
  if (region === "BRA") {
    formID = "25766131541140";
  } else if (latinCountries.includes(region)) {
    formID = "26335605183124";
  }

  const zenDesk = document.createElement("script");
  zenDesk.type = "text/javascript";
  zenDesk.id = "ze-snippet";
  zenDesk.async = true;
  zenDesk.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
  document.body.appendChild(zenDesk);

  zenDesk.onload = () => {
    if (i18n?.resolvedLanguage && window.zE) {
      window.zE?.(function () {
        window.zE.setLocale(i18n?.resolvedLanguage);
      });
    }
  };

  window.zESettings = {
    webWidget: {
      contactForm: {
        ticketForms: [{ id: formID }],
      },
    },
  };
};
