import React from "react";
import { connect } from "react-redux";
import { formatAmount } from "../../utils/formats";
import "./Subscription.scss";
import { useTranslation, Trans } from "react-i18next";

const getNumber = (s) => {
  return Number(s.replace(/[^\d]/g, ""));
};

const getTime = (s) => {
  return s.replace(/[^DWMY]/gi, "").toUpperCase();
};

function Subscription(props) {
  const { t } = useTranslation();
  const char2Time = (key, number) => {
    const convert = {
      D: t("times.day", { count: number }),
      W: t("times.week", { count: number }),
      M: t("times.month", { count: number }),
      Y: t("times.year", { count: number }),
    };

    return convert[key];
  };

  if (props.tradeInfo.recurring) {
    // 1M -> 1
    const intervalNumber = getNumber(props.tradeInfo.recurring?.recurring_interval);
    // 1M -> M
    const intervalTime = getTime(props.tradeInfo.recurring?.recurring_interval);
    //
    const intervalPeriod = char2Time(intervalTime, intervalNumber);
    // total subscription duration
    const subscriptionPeriod = char2Time(intervalTime, props.tradeInfo.recurring?.total_stage * intervalNumber);
    // amount for every period of time
    const intervalAmount = formatAmount(props.tradeInfo.recurring?.recurring_amount, props.tradeInfo);

    if (props.tradeInfo.recurring?.trial_period && props.tradeInfo.recurring?.trial_amount) {
      const trialAmount = formatAmount(props.tradeInfo.recurring?.trial_amount, props.tradeInfo);
      // 5D -> D
      const trialTime = getTime(props.tradeInfo.recurring?.trial_period);
      // 5D -> 5
      const trialNumber = getNumber(props.tradeInfo.recurring?.trial_period);

      const trialPeriod = char2Time(trialTime, trialNumber);
      return (
        <div style={{ padding: "4px" }} className="subscription">
          <p style={{ marginBottom: "12px", fontWeight: "bold", fontSize: "16px" }}>{t("subscriptions.title")}</p>
          <p style={{ fontSize: "14px" }}>
            {
              <Trans
                i18nKey="subscriptions.with_trial"
                values={{
                  subscription_period: subscriptionPeriod,
                  trial_amount: trialAmount,
                  trial_period: trialPeriod,
                  interval_amount: intervalAmount,
                  interval_period: intervalPeriod,
                }}
              />
            }
          </p>
        </div>
      );
    } else {
      return (
        <div style={{ padding: "4px" }} className="subscription">
          <p style={{ marginBottom: "12px", fontWeight: "bold", fontSize: "16px" }}>{t("subscriptions.title")}</p>
          <p style={{ fontSize: "14px" }}>
            {
              <Trans
                i18nKey="subscriptions.without_trial"
                values={{
                  subscription_period: subscriptionPeriod,
                  interval_amount: intervalAmount,
                  interval_period: intervalPeriod,
                }}
              />
            }
          </p>
        </div>
      );
    }
  } else {
    return null;
  }
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
    paymentInfo: state.paymentInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscription);
