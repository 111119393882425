import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Html3DsSecure from "../../form-input/Papara-creditcard/Html3DsSecure";

function CheckoutPapara() {
  const { payData } = useSelector((state) => state);
  const history = useHistory();
  if (!payData.html) history.replace("/not-found");
  return (
    <div>
      <Html3DsSecure htmlContent={payData.html} />
    </div>
  );
}

export default CheckoutPapara;
