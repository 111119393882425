import React, { useEffect, useState } from "react";
import "./Bank131.css";
import { useDispatch, useSelector } from "react-redux";
import req from "../../../utils/axios";
import { setTradeStatus } from "../../../redux/actions";
import { useHistory } from "react-router-dom";
import { returnUrlAsParam } from "../../../utils/queryUrl";
import { CircularProgress } from "@material-ui/core";

function Bank131Source({ setIsloading, handleError, payRequest }) {
  const { method, payChannel, prepayId, tradeInfo } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [isRendered, setIsRendered] = useState(false);
  const [isInputsHidden, setIsInputsHidden] = useState(true);
  const [submitPay, setSubmitPay] = useState(false);
  const history = useHistory();

  const isBank131 = `${method}-${payChannel}` === "CreditCard-Bank131";
  useEffect(() => {
    submitPay && payRequest();
  }, [submitPay]);

  useEffect(() => {
    if (isBank131 && !isRendered) {
      setIsloading(true);

      let postUrl = `/api/trade/get-client-token?prepay_id=${prepayId}&method=CreditCard`;
      if (tradeInfo?.app_info?.global) postUrl += `&region=${tradeInfo?.app_info?.region}`;

      req
        .post(postUrl)
        .then((res) => {
          if (res.data?.code === "10000" && "data" in res.data) {
            const paymentForm = new window.Bank131PaymentForm(res.data?.data?.token, {
              isCvcMasked: true,
              customerInteractionRedirect: { target: "_parent" },
            });
            paymentForm.onReady = function () {
              setTimeout(() => {
                setIsloading(false);
                setIsRendered(true);
              }, 3000);
            };
            paymentForm.onPaymentStart = function () {
              setSubmitPay(true);
            };
            paymentForm.onPaymentSuccess = function () {
              // dispatch(setTradeStatus({ id: "process", query: true }));
              // history.replace(`/result?prepay_id=${prepayId}${returnUrlAsParam()}`);
            };
            paymentForm.onPaymentFail = function (error) {
              handleError(error);
            };

            paymentForm.render?.();
          } else {
            handleError("get-client-token error!");
          }
        })
        .catch(() => handleError("get-client-token error!"));
    }
  }, [isRendered, isBank131]);

  useEffect(() => {
    if (isRendered && isBank131) {
      setIsInputsHidden(false);
    } else if (isRendered) {
      setIsInputsHidden(true);
    }
  }, [isRendered, isBank131]);

  return (
    <div>
      {isBank131 && !isRendered && (
        <div style={{ display: "flex", justifyContent: "center", margin: "20px 0" }}>
          <CircularProgress size={24} style={{ color: "var(--pagsmile--color)" }} />
        </div>
      )}
      <div id="bank131-payment-form" style={{ display: isInputsHidden ? "none" : "block" }}></div>
    </div>
  );
}

const Bank131 = ({ setIsloading, handleError, payRequest }) => {
  const { tradeInfo, loadedLibs } = useSelector((state) => state);
  const isBank131InList = tradeInfo?.channel_info_list?.some(
    (item) => `${item.method}-${item.channel}` === "CreditCard-Bank131"
  );
  if (!isBank131InList || !loadedLibs["CreditCard-Bank131"]) return null;

  return <Bank131Source setIsloading={setIsloading} handleError={handleError} payRequest={payRequest} />;
};

export default Bank131;
