import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import { checkName } from "../../utils/validations";
import InfoPromp from "../comp/InfoPromp";
import CardNameInfo from "../../img/cardName_info2.jpg";
import { useSelector } from "react-redux";

function Name({
  form,
  setForm,
  setFocusOnInput = () => {},
  creditcard = false,
  mobile,
  infoPromp = true,
  size = "small",
}) {
  const { t } = useTranslation();
  const [tradeInfo, method] = useSelector((state) => [state.tradeInfo, state.method]);

  useEffect(() => {
    if (!form.name.checked) {
      let name = tradeInfo?.user?.username ? tradeInfo?.user?.username : localStorage?.getItem("__frm_name");

      if (name) {
        setForm((prev) => {
          return {
            ...prev,
            name: { ...prev.name, value: name },
          };
        });
        checkName({ form: form, setForm: setForm, data: name });
      }
    }
  }, []);

  useEffect(() => {
    if (form.name.checked) {
      checkName({ form, setForm });
    }
  }, [method]);

  return (
    <>
      <TextField
        id="inputform-name"
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{ autocomplete: "new-password", "ga-data": "336" }}
        size={size}
        error={!form.name.valid}
        helperText={!form.name.valid && t("invalid_name")}
        className="homeMobile__filling__content__input"
        label={creditcard ? t("cardholder_name") : t("name")}
        variant="outlined"
        value={form.name.value}
        onChange={(event) => {
          var format = /[0-9!@#$%^&*()_+\=\[\]{};:"\\|,<>\/?`·～！¥（）—】【」「、｜；：’‘“”？》。《，]+/g;
          var value = event.target.value;
          value = value.replace(format, "");
          if (value?.length > form.name.max) {
            value = value.slice(0, form.name.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              name: { ...prev.name, value: value },
            };
          });
        }}
        onBlur={() => {
          checkName({ form, setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <>
                <img
                  src={Correct}
                  alt=""
                  style={{
                    display: form.name.valid && form.name.checked ? "block" : "none",
                    marginRight: "5px",
                  }}
                />
                {infoPromp && creditcard && <InfoPromp promp={<img src={CardNameInfo} alt="" />} />}
              </>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default Name;
