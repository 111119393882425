import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { setPaypalWallet, setChannel, setPayChannel } from "../../../redux/actions";
import req from "../../../utils/axios";
import {
  checkEmail,
  checkFirstName,
  checkIsEmailValid,
  checkIsNameValid,
  checkIsPhoneValid,
  checkIsSourceValid,
  checkLastName,
  checkPhone,
  checkSource,
} from "../../../utils/validations";

function PaypalButtonNative({ setIsloading, handleError, setForm, containerId, payRequest, providerId, ...props }) {
  const [token, setToken] = useState(null);
  useEffect(() => {
    //console.log("token set: ", token);
    token && payRequest({ token });
  }, [token]);

  useEffect(() => {
    if (props.loadedLibs[`Wallet-Paypal-${providerId}`]) {
      setIsloading(true);
      window?.paypal
        ?.Buttons({
          onInit(data, actions) {
            setIsloading(false);
            props.setPaypalWallet({ isReady: { native: true } });

            if (providerId === "1160") {
              const inputNodes = {
                firstname: document.getElementById("inputform-firstname"),
                lastname: document.getElementById("inputform-lastname"),
                email: document.getElementById("inputform-email"),
                phone: document.getElementById("inputform-phone"),
                source: document.getElementById("inputform-source"),
              };
              const checkIsAllValid = () => {
                return (
                  checkIsNameValid(inputNodes.firstname?.value) &&
                  checkIsNameValid(inputNodes.lastname?.value) &&
                  checkIsPhoneValid(inputNodes.phone?.value, window.phoneObj?.alpha2_code) &&
                  checkIsEmailValid(inputNodes.email?.value) &&
                  (!props.tradeInfo?.is_original_source_required || checkIsSourceValid(inputNodes.source?.value))
                );
              };
              const setButtonAction = () => {
                if (checkIsAllValid()) {
                  actions.enable();
                } else {
                  actions.disable();
                }
              };

              setButtonAction();

              inputNodes.firstname.oninput = setButtonAction;
              inputNodes.lastname.oninput = setButtonAction;
              inputNodes.email.oninput = setButtonAction;
              inputNodes.phone.oninput = setButtonAction;
              if (props.tradeInfo?.is_original_source_required) {
                inputNodes.source.oninput = setButtonAction;
              }
            }

            const policyCheckbox = document.querySelector("#policy-checkbox");
            !policyCheckbox.checked && actions.disable();

            // Listen for changes to the checkbox
            policyCheckbox?.addEventListener("change", function (event) {
              // Enable or disable the button when it is checked or unchecked
              if (event.target.checked) {
                actions.enable();
              } else {
                actions.disable();
              }
            });
          },
          onClick: function () {
            if (providerId === "1160") {
              const inputNodes = {
                firstname: document.getElementById("inputform-firstname"),
                lastname: document.getElementById("inputform-lastname"),
                email: document.getElementById("inputform-email"),
                phone: document.getElementById("inputform-phone"),
                source: document.getElementById("inputform-source"),
              };
              checkFirstName({ setForm: setForm, data: inputNodes.firstname?.value });
              checkLastName({ setForm: setForm, data: inputNodes.lastname?.value });
              checkPhone({ setForm: setForm, region: window.phoneObj?.alpha2_code, data: inputNodes.phone?.value });
              checkEmail({ setForm: setForm, data: inputNodes.email?.value });
              if (props.tradeInfo?.is_original_source_required) {
                checkSource({ setForm: setForm, data: inputNodes.source?.value });
              }
            }
          },
          // Order is created on the server and the order id is returned
          createOrder() {
            const inputNodes = {
              firstname: document.getElementById("inputform-firstname"),
              lastname: document.getElementById("inputform-lastname"),
              email: document.getElementById("inputform-email"),
              phone: document.getElementById("inputform-phone"),
              source: document.getElementById("inputform-source"),
            };
            return req
              .post(`/api/trade/paypal/authorize?prepay_id=${props.prepayId}`, {
                username: inputNodes.firstname?.value.trim() + " " + inputNodes.lastname?.value.trim(),
                email: inputNodes.email?.value?.toLowerCase(),
                phone: inputNodes.phone?.value?.replace(/[^\d]/g, ""),
                region: window.phoneObj,
              })
              .then((response) => {
                if (response?.data?.code === "10000" && response?.data?.data?.token) {
                  return response?.data?.data?.token;
                } else {
                  handleError("/paypal/authorize endpoint error!");
                }
              })
              .catch((err) => {
                handleError(err);
              });
          },
          // Finalize the transaction on the server after payer approval
          onApprove(data) {
            //console.log("paypal data: ", data);
            setToken(data.orderID);
          },
          onError(err) {
            // For example, redirect to a specific error page
            // closeing popup generates not specific error!
          },
        })
        .render(`#${containerId}`);
    }
  }, [props.loadedLibs[`Wallet-Paypal-${providerId}`]]);
  return (
    <div
      id={containerId}
      style={{
        display:
          props.paypalWallet.isReady.native && props.method === "Wallet" && props.payChannel === "Paypal"
            ? "block"
            : "none",
      }}
    ></div>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    paypalWallet: state.paypalWallet,
    paymentInfo: state.paymentInfo,
    tradeInfo: state.tradeInfo,
    returnUrl: state.returnUrl,
    loadedLibs: state.loadedLibs,
    channelList: state.channelList,
    method: state.method,
    payChannel: state.payChannel,
    prepayId: state.prepayId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setPaypalWallet: (item) => dispatch(setPaypalWallet(item)),
    setChannel: (item) => dispatch(setChannel(item)),
    setPayChannel: (item) => dispatch(setPayChannel(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaypalButtonNative);
