import React from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { useTranslation } from "react-i18next";
import { checkCardNumber } from "../../utils/validations";
import CardNoInfo from "../../img/cardNo_info2.jpg";
import { formatCreditCardNumber, getCardData } from "../../utils/formats";
import InfoPromp from "../comp/InfoPromp";

function CreditCardNumber({ form, setForm, setFocusOnInput = () => {}, id }) {
  const { t } = useTranslation();
  return (
    <>
      <TextField
        id={id || "num-cc"}
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        inputProps={{ autocomplete: "new-password", "ga-data": "341", inputMode: "numeric" }}
        size="small"
        error={!form.cardNumber.valid}
        helperText={!form.cardNumber.valid && t("invalid_card_no")}
        className="homeMobile__filling__content__input"
        label={t("card_no")}
        variant="outlined"
        value={form.cardNumber.value}
        onChange={(event) => {
          let value = formatCreditCardNumber(event.target.value);
          setForm((prev) => {
            return {
              ...prev,
              cardNumber: { ...prev.cardNumber, value: value },
            };
          });
        }}
        onBlur={() => {
          checkCardNumber({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={getCardData(form.cardNumber.value)?.logo}
                alt=""
                style={{
                  height: "20px",
                  paddingRight: "3px",
                }}
              />
              <InfoPromp promp={<img src={CardNoInfo} alt="" />} />
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default CreditCardNumber;
