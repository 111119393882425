import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import i18n from "../language/i18n";
import uuid from "react-uuid";
import OnlineBanking from "../img/online_banking.svg";
import CashPayments from "../img/cash_payments.svg";
import CreditCard from "../img/credit_card.svg";
import Spei from "../img/spei.png";
import Oxxo from "../img/oxxo.png";
import Todito from "../img/todito.png";
import Efecty from "../img/efecty.png";
import PSE from "../img/pse.png";
import Khipu from "../img/khipu.png";
import CashPaymentsM from "../img/cash_mobile.jpg";
import EfectyM from "../img/efecty_mobile.png";
import PseM from "../img/pse_mobile.png";
import KhipuM from "../img/khipu_mobile.png";
import ToditoM from "../img/todito_mobile.png";
import ToditoCash from "../img/todito_cash.png";
import Paypal from "../img/paypal.png";
import Ame from "../img/ame.png";
import Picpay from "../img/picpay.png";
import Boleto from "../img/boleto.svg";
import WalletChl from "../img/wallet_chl.png";
import WalletChl2 from "../img/wallet_chl2.png";
import Mach from "../img/mach.png";
import Vita from "../img/vita.png";
import BoletoRapidao from "../img/boleto_rapidao.png";
import DepositExpress from "../img/deposit_express6.svg";
import Lottery from "../img/lottery.svg";
import Pix from "../img/pix.svg";
import Itau from "../img/itau.png";
import Santander from "../img/santander.png";
import BancoDoBrasil from "../img/banco-do-brasil.png";
import Bradesco from "../img/bradesco.png";
import Caixa from "../img/caixa.png";
import Alipayhk from "../img/alipayhk_alipay-partner.png";
import AlipayhkLogo from "../img/alipayhk_logo.svg";
import AlipayLogo from "../img/alipay_logo.svg";
import Alipay from "../img/alipay.svg";
import Gcash from "../img/gcash.png";
import BoostAlipay from "../img/boost_alipay.png";
import GcashAlipay from "../img/gcash_alipay-partner.png";
import KakaoPayAlipay from "../img/kakaopay_alipay-partner.png";
import KakaoPay from "../img/kakaopay.png";
import KakaopayLogo from "../img/kakaopay_logo.svg";
import DanaAlipay from "../img/dana_alipay-partner.png";
import Dana from "../img/dana.png";
import Tng from "../img/tng_alipay-partner.png";
import TngLogo from "../img/tng_logo.svg";
import Truemoney from "../img/truemoney_alipay-partner.png";
import Paymaya from "../img/paymaya.jpg";
import GcashLogo from "../img/gcash_logo.svg";
import TpagaLogo from "../img/tpaga_logo.jpg";
import DebitPh from "../img/debitph.png";
import Ubp from "../img/ubp.png";
import Bpi from "../img/bpi.png";
import Pipay from "../img/pipay.png";
import Paygo from "../img/paygo.png";
import Tpaga from "../img/tpaga.jpg";
import Seven11 from "../img/seven11.png";
import Seven11M from "../img/seven11.svg";
import PeScotia from "../img/pe_scotiabank.jpg";
import PePambo from "../img/pe_pambo.jpg";
import PeWu from "../img/pe_wu.jpg";
import PeBanbif from "../img/pe_banbif.jpg";
import PeBbva from "../img/pe_bbva.jpg";
import PeBcp from "../img/pe_bcp.jpg";
import PeCaja from "../img/pe_caja.jpg";
import PeDisashop from "../img/pe_disashop.jpg";
import PeInterbank from "../img/pe_interbank.jpg";
import PeKasnet from "../img/pe_kasnet.jpg";
import PeCellPower from "../img/pe_cellpower.jpg";
import PeGanaste from "../img/pe_ganaste.jpg";
import PeMoneyGram from "../img/pe_moneygram.jpg";
import PeNiuBiz from "../img/pe_niubiz.jpg";
import PePichinca from "../img/pe_pichinca.jpg";
import PeRedDigit from "../img/pe_reddigit.jpg";
import PeBanbifWallet from "../img/banbifwallet.png";
import PeBbvaWallet from "../img/bbvawallet.png";
import InterbankWallet from "../img/interbankwallet.png";
import LigoWallet from "../img/ligowallet.png";
import PlinWallet from "../img/plinwallet.png";
import ScotiabankWallet from "../img/scotiabankwallet.png";
import TunkiWallet from "../img/tunkiwallet.png";
import YapeWallet from "../img/yapewallet.png";
import Wallet from "../img/wallet.svg";
import WalletM from "../img/wallet_mobile.png";
import Grab from "../img/grab.jpg";
import Paynow from "../img/paynow.png";
import RabbitLineAc from "../img/rabbitline_ac.png";
import BpiAc from "../img/BPI_ac.png";
import Mada from "../img/mada.jpg";
import Knet from "../img/knet.jpg";
import UPI from "../img/UPI.png";
import Netbank from "../img/netbank.png";
import AlfaClick from "../img/alfa_click.png";
import Qiwi from "../img/qiwi.png";
import WebMoney from "../img/webmoney.png";
import YandexMoney from "../img/yandex_money.png";
import PagoEfectivo from "../img/pago_efectivo.png";
import Yoomoney from "../img/yoomoney.png";
import WalletRu from "../img/yoomoney_logo.png";
import Eprepag from "../img/eprepag.png";
import Paysafecard from "../img/paysafecard.png";
import Skrill from "../img/skrill.png";
import Fawry from "../img/fawry.png";
import Baloto from "../img/baloto.png";
import OxxoPay from "../img/oxxo_pay.png";
import UnionPay from "../img/union_pay.png";
import AlipayPlus from "../img/alipay_plus.png";
import AlipayPlusLogo from "../img/alipay_plus_52.png";
import Codi from "../img/codi.png";
import CodiM from "../img/codiM.png";
import Payco from "../img/payco.png";
import Toss from "../img/toss.png";
import SamsungPay from "../img/samsungPay.png";
import VirtualAccount from "../img/virtual_account.png";
import Chek from "../img/chek.png";
import Sured from "../img/sured.png";
import Gana from "../img/gana.png";
import Pago46 from "../img/pago46.png";
import PlayersBank from "../img/pb.svg";
import KiwiWallet from "../img/kiwi_wallet.png";
import PagoFacil from "../img/pago_facil.png";
import RapiPago from "../img/rapi_pago.png";
import Brou from "../img/banco_republica.jpg";
import Bancontact from "../img/bancontact.png";
import Giropay from "../img/giropay.jpg";
import Ideal from "../img/ideal.png";
import MyBank from "../img/myBank.jpg";
import Neosurf from "../img/neosurf.png";
import Przelewy from "../img/przelewy.png";
import Sofort from "../img/sofort.png";
import Trustly from "../img/trustly.png";
import EPS from "../img/eps.png";
import SberPay from "../img/sberpay.png";
import Transfiya from "../img/transfiya.png";
import AstroPay from "../img/astropay.png";
import Nequi from "../img/nequi.png";
import Rapipay from "../img/rapipay.png";
import Movii from "../img/movii.png";
import Daviplata from "../img/daviplata.png";
import Dale from "../img/dale.png";
import Bancolombia from "../img/bancolombia.png";
import Fpay from "../img/fpay.png";
import BCR from "../img/bcr.png";
import BNCR from "../img/bncr.png";
import ClaroPay from "../img/claro_pay.png";
import Servipag from "../img/servipag.png";
import ServipagPc from "../img/servipagpc.png";
import Webpay from "../img/webpay.png";
import NaverPay from "../img/npay.png";
import Crypto from "../img/crypto.png";
import MpWallet from "../img/mp_wallet.png";
import GrabAlipay from "../img/grab_alipay.jpg";
import OvoAlipay from "../img/ovo_alipay.jpg";
import ToditoCashLogo from "../img/toditocash_logo.png";
import OpenFinance from "../img/openfinance.svg";
import Tupay from "../img/tupay-channels.png";
import Linepay from "../img/linepay.png";
import RetailStore from "../img/retail-store.png";
import ShopeePay from "../img/shopeepay.png";
import Payconiq from "../img/payconiq.png";
import Multibanco from "../img/multibanco.png";
import BNI from "../img/TarsPay/bni.png";
import BRI from "../img/TarsPay/bri.png";
import BCA from "../img/TarsPay/bca.png";
import CIMB from "../img/TarsPay/cimb.png";
import Mandiri from "../img/TarsPay/mandiri.png";
import Permata from "../img/TarsPay/permata.png";
import QrPh from "../img/qrph.png";
import Binance from "../img/binance.png";
import Blik from "../img/blik.png";
import Aman from "../img/Kiosk/AMAN.png";
import Masary from "../img/Kiosk/Masary.png";
import Momken from "../img/Kiosk/Momken.png";
import Sadad from "../img/Kiosk/Sadad.png";

import BangkokBankIcon from "../img/banktransfer/alipay/Thailand/bangkok-bank.svg";
import SiamCommercialBankIcon from "../img/banktransfer/alipay/Thailand/scb.svg";
import BankofAyudhyaIcon from "../img/banktransfer/alipay/Thailand/bank-ayudhya.svg";
import KrungThaiBankIcon from "../img/banktransfer/alipay/Thailand/krung-thai-bank.svg";
import KasikornBankIcon from "../img/banktransfer/alipay/Thailand/kasikorn-bank.svg";
import GovernmentSavingsBankIcon from "../img/banktransfer/alipay/Thailand/gsb.svg";

import MOL from "../img/boku.png";
import Klap from "../img/klap.png";
import Pc1 from "../img/pc1.jpg";
import Pc2 from "../img/pc2.jpg";
import Pc3 from "../img/pc3.jpg";
import Pc4 from "../img/pc4.jpg";
import Pc7 from "../img/pc7.jpg";
import Pc9 from "../img/pc9.jpg";
import Pc10 from "../img/pc10.jpg";
import Pc11 from "../img/pc11.jpg";

import PcMex1 from "../img/pcMEX1.jpg";
import PcMex2 from "../img/pcMEX2.jpg";

import PcMex4 from "../img/pcMEX4.jpg";
import PcMex5 from "../img/pcMEX5.jpg";
import PcMex6 from "../img/pcMEX6.jpg";
import PcMex7 from "../img/pcMEX7.jpg";
import PcMex8 from "../img/pcMEX8.jpg";
import PcMex9 from "../img/pcMEX9.jpg";
import PcMex10 from "../img/pcMEX10.jpg";
import PcMex11 from "../img/pcMEX11.jpg";
import PcMex12 from "../img/pcMEX12.jpg";
import PcMex13 from "../img/pcMEX13.jpg";
import PcMex14 from "../img/pcMEX14.jpg";

import Ahorro from "../img/ahorro.jpg";
import Alianza from "../img/alianza.jpg";
import Banbajio from "../img/banbajio.jpg";
import Banorte from "../img/banorte.jpg";
import Bogeda from "../img/bogeda.jpg";
import Hsbc from "../img/hsbc.jpg";
import Kiosko from "../img/kiosko.jpg";
import Lagunitas from "../img/lagunitas.jpg";
import Merza from "../img/merza.jpg";
import Morelia from "../img/morelia.jpg";
import Oblatos from "../img/oblatos.jpg";
import Smbrural from "../img/smbrural.jpg";
import Telecom from "../img/telecom.jpg";
import Via from "../img/Via.jpg";
import Yza from "../img/yza.jpg";
import Hey from "../img/hey.jpg";
import CruzAzul from "../img/cruz_azul.jpg";
import SamsClub from "../img/sams_club.jpg";
import Papara from "../img/papara.png";

import Ficohsa from "../img/ficohsa.jpg";
import InterBanco from "../img/inter_banco.jpg";

import AgenteCash from "../img/agente_cash.jpg";
import Comercio from "../img/comercio.jpg";
import Edypyme from "../img/edypyme.jpg";
import Megapunto from "../img/megapunto.jpg";
import Sullana from "../img/sullana.jpg";

import Azuayo from "../img/azuayo.jpg";
import BancoInternacional from "../img/banco_internacional.jpg";
import Coac29 from "../img/coac29.jpg";
import Coopmego from "../img/coopmego.jpg";
import Economicas from "../img/economicas.jpg";
import Facilito from "../img/facilito.jpg";
import Guayaquil from "../img/guayaquil.jpg";
import Hiper_market from "../img/hiper_market.jpg";
import Matualista from "../img/matualista.jpg";
import Muchuc from "../img/muchuc.jpg";
import Afirme from "../img/afirme.jpg";

import CriGec from "../img/cri_gec.jpg";
import CriLafise from "../img/cri_lafise.jpg";
import CriNacional from "../img/cri_nacional.jpg";
import CriPayser from "../img/cri_payser.jpg";
import CriTeledolar from "../img/cri_teledolar.jpg";

import GtmBam from "../img/gtm_bam.jpg";
import GtmBanrural from "../img/gtm_banrural.jpg";
import GtmBantrab from "../img/gtm_bantrab.jpg";
import GtmContinental from "../img/gtm_continental.jpg";

import PanMachetazo from "../img/pan_machetazo.jpg";
import PanXtra from "../img/pan_xtras.jpg";
import PanJuan from "../img/juan.png";
import Pharmacys from "../img/pharmacys.png";
import Servientrega from "../img/servientrega.png";
import Pronet from "../img/pronet.png";
import JumboMarket from "../img/jumbo-market.jpg";
import FarmaExpress from "../img/farma-express.jpg";
import AmPm from "../img/am-pm.jpg";

import PerAndes from "../img/pe_andes.jpg";
import PerCajadel from "../img/pe_cajadel.jpg";
import PerCajapalla from "../img/pe_cajapalla.jpg";
import PerFullcargo from "../img/pe_fullcargo.jpg";
import PerQapaq from "../img/pe_qapaq.jpg";
import PerRaiz from "../img/pe_raiz.jpg";
import PerTrujillo from "../img/pe_trujillo.jpg";
import Izipay from "../img/izipay.png";

import Btc from "../img/btc.jpg";
import BtcLight from "../img/btclight.png";
import Eth from "../img/ETH.svg";
import Usdc from "../img/usdc.png";
import UsdtErc from "../img/usdt_erc.png";
import UsdtTrc from "../img/usdt_trc.png";
import store from "../redux/store";
import SBP from "../img/sbp.png";
import Paymob from "../img/paymob.png";
import WechatHk from "../img/wechathk.svg";
import WechatPay from "../img/wechatpay.svg";

// in case success, fail, other these are not goint to redirect to /result
export const checkoutStatusExeptions = ["PSE-ACH"];

// method-channel pair for /checkout page
const checkoutConfig = () => {
  const { method, payChannel, tradeInfo } = store.getState();
  const region = tradeInfo?.app_info?.region;
  const providerId = getProviderId();
  const config = [
    "BankTransfer-Monnet-PER",
    "Cash-Monnet-PER",
    "BankTransfer-KASHIO",
    "Cash-KASHIO",
    "Cash-SafetyPay",
    "BankTransfer-SafetyPay",
    "SPEI-OpenPay",
    "SPEI-STP",
    "SPEI-Banwire",
    "SPEI-OPM",
    "SPEI-CHANNEL_GROUP_ROUTER",
    "Cash-Banwire",
    "Cash-OpenPay",
    "OXXO-Banwire",
    "OXXO-CardPay",
    "OXXO-Bamboo",
    "OXXO-Localpayment",
    "Wallet-Mach",
    "Wallet-Vita",
    "Wallet-AME",
    "Wallet-AlipayCN",
    "Wallet-AlipayHK",
    "Wallet-GCash",
    "Wallet-KakaoPayAlipay",
    "Wallet-TNG",
    "Wallet-TrueMoney",
    "Wallet-TPaga",
    "Wallet-RabbitLinePay",
    "Wallet-Fpay",
    "Wallet-ShopeePay",
    "Wallet-FawryPay",
    "Wallet-WechatPayHK",
    "Wallet-WechatPay",
    "Boleto-BS2",
    "Boleto-StarkBank",
    "Boleto-Mercadopago",
    "BoletoRapido-BS2",
    "Boleto-CHANNEL_GROUP_ROUTER",
    "PIX-BS2",
    "PIX-Bradesco",
    "PIX-Rendimento",
    "PIX-Nupay",
    "PIX-Genial",
    "PIX-StarkBank",
    "PIX-StarkBankTransferSmile",
    "PIX-CHANNEL_GROUP_ROUTER",
    "PIX-Mercadopago",
    "PIX-BTG",
    "PIX-Fastcash",
    "PIX-Delbank",
    "PIX-Celcoin",
    "PIX-StarkBankDynamic",
    "PIX-ZroBank",
    "DepositExpress-Levpay",
    "OTC-Xendit",
    "Cash-PayCash",
    "BankTransfer-PayCash",
    "Lottery-Caixa",
    "Lottery-Mercadopago",
    "OXXOPay-Conekta",
    "Baloto-PayValida",
    "Baloto-IGT",
    "SuRed-PayValida",
    "GANA-PayValida",
    "Bancolombia-PayValida",
    "BCR-PayValida",
    "BNCR-PayValida",
    "BNCR-PayCash",
    "KLAP-Multicaja",
    "Cash-Multicaja",
    "PlayersBankPIX-BS2",
    "BankTransfer-Localpayment",
    "PagoFacil-Localpayment",
    "PagoFacil-PagoFacil",
    "Servipag-Localpayment",
    "Rapipago-Localpayment",
    "Rapipago-Rapipago",
    "Transfiya-Movii",
    "Cash-Localpayment",
    "CoDi-STP",
    "Crypto-TripleA",
    "Wallet-Tupay",
    "Multibanco-PPRO",
    "Cash-FawryPay",
    "QRPH-GCash",
    "CreditCard-Papara-1168",
    "PSE-ACH",
    "Kiosk-Paymob",
    "SBP-Bank131",
  ];
  return (
    config.includes(`${method}-${payChannel}-${providerId}`) ||
    config.includes(`${method}-${payChannel}-${region}`) ||
    config.includes(`${method}-${payChannel}`)
  );
};

const channelConfig = (method, channel) => {
  let config = [
    "Cash-SafetyPay",
    "Cash-PagoEfectivo",
    "BankTransfer-PagoEfectivo",
    "BankTransfer-SafetyPay",
    "Khipu",
    "Wallet",
    "RecurringWallet",
    "DepositExpress",
    "DirectDebit",
    "PagoEfectivo",
    "Crypto",
    "BankTransfer-Tarspay",
    "Kiosk-Paymob",
  ]; // methods that have channels to be choosen
  return config.indexOf(method) >= 0 || config.indexOf(method + "-" + channel) >= 0;
};

const walletLogo = {
  MEX: { pc: Wallet, mobile: Wallet },
  BRA: { pc: Wallet, mobile: Wallet },
  CHL: { pc: Wallet, mobile: Wallet },
  HKG: { pc: AlipayhkLogo, mobile: AlipayhkLogo },
  PHL: { pc: Wallet, mobile: Wallet },
  KOR: { pc: Wallet, mobile: Wallet },
  IDN: { pc: Wallet, mobile: Wallet },
  MYS: { pc: Wallet, mobile: Wallet },
  THA: { pc: Wallet, mobile: Wallet },
  COL: { pc: Wallet, mobile: Wallet },
  CHN: { pc: AlipayLogo, mobile: AlipayLogo },
  SGP: { pc: Wallet, mobile: Wallet },
  SAU: { pc: Wallet, mobile: Wallet },
  KWT: { pc: Wallet, mobile: Wallet },
  RUS: { pc: Wallet, mobile: Wallet },
  EGY: { pc: Wallet, mobile: Wallet },
  PER: { pc: Wallet, mobile: Wallet },
  ARG: { pc: Wallet, mobile: Wallet },
  TWN: { pc: Wallet, mobile: Wallet },
  TUR: { pc: Wallet, mobile: Wallet },
  VNM: { pc: Wallet, mobile: Wallet },
};

export const getProviderId = () => {
  const { tradeInfo, method, payChannel } = store.getState();
  const channelList = tradeInfo?.channel_info_list;
  const providerId = channelList?.find((obj) => obj.channel === payChannel && obj.method === method)?.provider_id;
  return providerId;
};

const getWalletChannelLogo = (channel) => {
  const { channel_info_list: channelList = [] } = store.getState().tradeInfo || {};
  const providerId = channelList.find((obj) => obj.channel === channel)?.provider_id;
  const walletLogos = {
    Todito: ToditoCash,
    AstroPay: AstroPay,
    Paypal: Paypal,
    PaypalXCG: Paypal,
    AME: Ame,
    PicPay: Picpay,
    Eprepag: Eprepag,
    MOL: MOL,
    Paysafecard: Paysafecard,
    Skrill: Skrill,
    Mercadopago: MpWallet,
    Mach: Mach,
    Vita: Vita,
    Chek: Chek,
    Fpay: Fpay,
    AlipayHK: Alipayhk,
    GCash: { 1075: GcashAlipay, 1161: GcashAlipay, 1152: Gcash, 1167: GcashAlipay },
    GcashXendit: Gcash,
    Paymaya: Paymaya,
    BPI: BpiAc,
    KakaoPayAlipay: KakaoPayAlipay,
    TOSS: Toss,
    PAYCO: Payco,
    SamsungPay: SamsungPay,
    KakaoPay: KakaoPay,
    NaverPay: NaverPay,
    DANA: { 1076: DanaAlipay, 1139: DanaAlipay, 1151: Dana, 1217: Dana },
    OVO: OvoAlipay,
    TNG: Tng,
    BOOST: BoostAlipay,
    TrueMoney: Truemoney,
    RabbitLinePay: RabbitLineAc, // Alipay +
    LINEPay: Linepay, // HappyPay
    TPaga: Tpaga,
    Daviplata: Daviplata,
    Rappipay: Rapipay,
    Dale: Dale,
    Movii: Movii,
    Nequi: Nequi,
    ClaroPay: ClaroPay,
    AlipayCN: Alipay,
    GrabPay: { 1141: GrabAlipay, 1067: Grab },
    PayNow: Paynow,
    MADA: Mada,
    KNet: Knet,
    YooMoney: Yoomoney,
    QIWI: KiwiWallet,
    Fawry: Fawry,
    FawryPay: Fawry,
    Tupay: Tupay,
    Papara: Papara,
    ShopeePay: ShopeePay,
    Paymob: Paymob,
    WechatPayHK: WechatHk,
    WechatPay: WechatPay,
  };
  if (typeof walletLogos[channel] === "object") return walletLogos[channel]?.[providerId];
  // console.log(`channel: ${channel}, 1. ${walletLogos[channel]?.[providerId]} 2. ${walletLogos[channel]}`);
  return walletLogos[channel];
};

const walletChannels = {
  MEX: { Todito: ToditoCash, AstroPay: AstroPay, Paypal: Paypal },
  BRA: {
    Paypal: Paypal,
    PaypalXCG: Paypal,
    AME: Ame,
    PicPay: Picpay,
    Eprepag: Eprepag,
    MOL: MOL,
    Paysafecard: Paysafecard,
    Skrill: Skrill,
    AstroPay: AstroPay,
    Mercadopago: MpWallet,
  },
  CHL: { Mach: Mach, Vita: Vita, Chek: Chek, Fpay: Fpay },
  PER: { Fpay: Fpay },
  HKG: { AlipayHK: Alipayhk },
  PHL: {
    GCash: GcashAlipay,
    GcashXendit: Gcash,
    Paymaya: Paymaya,
    BPI: BpiAc,
  },
  KOR: {
    KakaoPayAlipay: KakaoPayAlipay,
    TOSS: Toss,
    PAYCO: Payco,
    SamsungPay: SamsungPay,
    KakaoPay: KakaoPay,
    NaverPay: NaverPay,
  },
  IDN: { DANA: Dana, OVO: OvoAlipay },
  MYS: { TNG: Tng, BOOST: BoostAlipay, GrabPay: GrabAlipay },
  THA: {
    TrueMoney: Truemoney,
    RabbitLinePay: RabbitLineAc,
  },
  COL: {
    TPaga: Tpaga,
    Daviplata: Daviplata,
    Rappipay: Rapipay,
    Dale: Dale,
    Movii: Movii,
    Nequi: Nequi,
    ClaroPay: ClaroPay,
  },
  ARG: { AstroPay: AstroPay },
  CHN: { AlipayCN: Alipay },
  SGP: { GrabPay: Grab, PayNow: Paynow },
  SAU: { MADA: Mada },
  KWT: { KNet: Knet },
  RUS: { YooMoney: Yoomoney, QIWI: KiwiWallet },
  EGY: { Fawry: Fawry },
};

const cryptoChannels = [
  {
    data: [{ logo: Btc, channel: "BTC" }],
    key: uuid(),
  },
  {
    data: [{ logo: Binance, channel: "BINANCE" }],
    key: uuid(),
  },
  {
    data: [{ logo: BtcLight, channel: "LNBC" }],
    key: uuid(),
  },
  {
    data: [{ logo: Eth, channel: "ETH" }],
    key: uuid(),
  },
  {
    data: [{ logo: Usdc, channel: "USDC" }],
    key: uuid(),
  },
  {
    data: [{ logo: UsdtErc, channel: "USDT" }],
    key: uuid(),
  },
  {
    data: [{ logo: UsdtTrc, channel: "USDT_TRC20" }],
    key: uuid(),
  },
];

const happyPayChannels = {
  BankTransfer: [
    {
      data: [{ bank_name: "中信實體ATM", bank_id: "PD-ATM-CTCB" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "華南實體ATM", bank_id: "PD-ATM-HNCB" }],
      key: uuid(),
    },
    // {
    //   data: [{ bank_name: "郵局實體ATM", bank_id: "PD-ATM-POST" }],
    //   key: uuid(),
    // },
    {
      data: [{ bank_name: "上海實體ATM", bank_id: "PD-ATM-SCSB" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "中國信託WEB-ATM", bank_id: "PD-WEBATM-CTCB" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "玉山銀行WEBATM", bank_id: "PD-WEBATM-ESUN" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "華南WEB-ATM", bank_id: "PD-WEBATM-HNCB" }],
      key: uuid(),
    },
    // {
    //   data: [{ bank_name: "郵局WEB-ATM", bank_id: "PD-WEBATM-POST" }],
    //   key: uuid(),
    // },
  ],
  OTC: [
    {
      data: [{ bank_name: "全家FAMIPORT", bank_id: "PD-STORE-FAMI" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "萊爾富Life-ET", bank_id: "PD-STORE-HILIFEET" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "ibon便利生活站", bank_id: "PD-STORE-IBON" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "超商行動掃碼(7-11、全家)", bank_id: "PD-STORE-MBC" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "超商行動掃碼(萊爾富Life-ET)", bank_id: "PD-STORE-MBC-HILIFEET" }],
      key: uuid(),
    },
    {
      data: [{ bank_name: "OK超商OK-go", bank_id: "PD-STORE-OKGO" }],
      key: uuid(),
    },
  ],
  Wallet: [
    {
      data: [{ bank_name: "LINEPAY", bank_id: "PD-EPOINT-LINEPAY" }],
      key: uuid(),
    },
  ],
};

const depositExpressChannels = [
  // {
  //   data: [{ logo: Itau, bank: "itau" }],
  //   key: uuid(),
  //   "ga-data": "7",
  // },
  // {
  //   data: [{ logo: Santander, bank: "santander" }],
  //   key: uuid(),
  //   "ga-data": "8",
  // },
  // {
  //   data: [{ logo: BancoDoBrasil, bank: "banco-do-brasil" }],
  //   key: uuid(),
  //   "ga-data": "9",
  // },
  {
    data: [{ logo: Bradesco, bank: "bradesco" }],
    key: uuid(),
    "ga-data": "10",
  },
  {
    data: [{ logo: Caixa, bank: "caixa" }],
    key: uuid(),
    "ga-data": "11",
  },
];

const directDebitChannels = [
  {
    data: [{ logo: Ubp, bank: "BA_UBP" }],
    key: uuid(),
    "ga-data": "223",
  },
  {
    data: [{ logo: Bpi, bank: "BA_BPI" }],
    key: uuid(),
    "ga-data": "222",
  },
];

export const tarspayChannels = [
  // {
  //   data: [{ logo: BCA, bank_name: "BANK BCA", bank_id: "BANK_BCA" }],
  //   key: uuid(),
  // },
  {
    data: [{ logo: BNI, bank_name: "BANK BNI", bank_id: "BANK_BNI" }],
    key: uuid(),
  },
  {
    data: [{ logo: BRI, bank_name: "BANK BRI", bank_id: "BANK_BRI" }],
    key: uuid(),
  },
  {
    data: [{ logo: CIMB, bank_name: "BANK CIMB", bank_id: "BANK_CIMB" }],
    key: uuid(),
  },
  // {
  //   data: [{ logo: Mandiri, bank_name: "BANK MANDIRI", bank_id: "BANK_MANDIRI" }],
  //   key: uuid(),
  // },
  // {
  //   data: [{ logo: Permata, bank_name: "BANK PERMATA", bank_id: "BANK_PERMATA" }],
  //   key: uuid(),
  // },
];

export const alipayBanktransferChannels = {
  THA: [
    {
      data: [{ logo: BangkokBankIcon, bank_name: "Bangkok Bank" }],
      key: uuid(),
    },
    {
      data: [{ logo: SiamCommercialBankIcon, bank_name: "Siam Commercial Bank" }],
      key: uuid(),
    },
    {
      data: [{ logo: BankofAyudhyaIcon, bank_name: "Bank of Ayudhya" }],
      key: uuid(),
    },
    {
      data: [{ logo: GovernmentSavingsBankIcon, bank_name: "Government Savings Bank" }],
      key: uuid(),
    },
    {
      data: [{ logo: KrungThaiBankIcon, bank_name: "KrungThai Bank" }],
      key: uuid(),
    },
    {
      data: [{ logo: KasikornBankIcon, bank_name: "Kasikornbank" }],
      key: uuid(),
    },
  ],
};

export const kioskChannels = [
  {
    data: [{ logo: Aman }, { logo: Masary }, { logo: Momken }, { logo: Sadad }],
    key: uuid(),
  },
];

export const pagoEfectivoChannels = [
  {
    data: [
      { logo: YapeWallet },
      { logo: PlinWallet },
      { logo: PeBanbifWallet },
      { logo: PeBbvaWallet },
      { logo: InterbankWallet },
      { logo: LigoWallet },
      { logo: ScotiabankWallet },
      { logo: TunkiWallet },
      { logo: PeScotia },
      { logo: PePambo },
      { logo: PeWu },
      { logo: PeBanbif },
      { logo: PeBbva },
      { logo: PeBcp },
      { logo: PeCaja },
      { logo: PeDisashop },
      { logo: PeInterbank },
      { logo: PeKasnet },
      { logo: PeCellPower },
      { logo: PeGanaste },
      { logo: PeMoneyGram },
      { logo: PeNiuBiz },
      { logo: PePichinca },
      { logo: PeRedDigit },
    ],
    key: uuid(),
  },
];

export const pagoEfectivoBankChannels = [
  {
    data: [
      { logo: PeBbva },
      { logo: PeBcp },
      { logo: PeInterbank },
      { logo: PeCaja },
      { logo: PeScotia },
      { logo: PeBanbif },
      { logo: PePichinca },
    ],
    key: uuid(),
  },
];

export const pagoEfectivoCashChannels = [
  {
    data: [
      { logo: PeBbva },
      { logo: PeBcp },
      { logo: PeInterbank },
      { logo: PeScotia },
      { logo: PeCaja },
      { logo: PePambo },
      { logo: PeWu },
      { logo: PeDisashop },
      { logo: PeKasnet },
      { logo: PeCellPower },
      { logo: PeGanaste },
      { logo: PeMoneyGram },
      { logo: PeNiuBiz },
      { logo: PeRedDigit },
    ],
    key: uuid(),
  },
];

export const hidePagsmileLogoForAppID = (appId) => {
  return ["16394102738278966", "16448623415994515"].indexOf(appId) >= 0;
};

const countryCode2TaxIdType = {
  BRA: ["CPF", "CNPJ"],
  MEX: ["RFC", "CURP"],
  CHL: ["RUT", "RUN"],
  COL: ["NIT", "CC", "CE"],
  PER: ["DNI", "RUC"],
  ECU: ["RUC", "CE", "CI"],
  PAN: ["CIP", "RUC", "PASS", "CE"],
  CRI: ["CDI"],
  SLV: ["DUI"],
  GTM: ["DPI"],
  NIC: ["CIP"],
  ARG: ["DNI", "CUIT", "CUIL", "CDI"],
  URY: ["CI", "RUT"],
  BOL: ["NIT", "CI", "CE"],
  PRY: ["RUC", "CI"],
};

export const europeCountries = [
  "AUT",
  "BEL",
  "BGR",
  "HRV",
  "CYP",
  "CZE",
  "DNK",
  "EST",
  "FIN",
  "FRA",
  "DEU",
  "GRC",
  "HUN",
  "ISL",
  "IRL",
  "ITA",
  "LVA",
  "LTU",
  "LUX",
  "MLT",
  "NLD",
  "NOR",
  "POL",
  "PRT",
  "ROU",
  "SVK",
  "SVN",
  "ESP",
  "SWE",
  "CHE",
  "GBR",
];

const latinCountries = [
  "MEX",
  "CHL",
  "COL",
  "PER",
  "ECU",
  "BRA",
  "PAN",
  "CRI",
  "SLV",
  "GTM",
  "NIC",
  "ARG",
  "URY",
  "BOL",
  "PRY",
];

const countryCode3to2 = {
  MEX: "MX",
  CHL: "CL",
  COL: "CO",
  PER: "PE",
  ECU: "EC",
  BRA: "BR",
  PAN: "PA",
  CRI: "CR",
  SLV: "SV",
  GTM: "GT",
  NIC: "NI",
  HKG: "HK",
  PHL: "PH",
  KOR: "KR",
  IDN: "ID",
  MYS: "MY",
  THA: "TH",
  CHN: "CN",
  KHM: "KH",
  SGP: "SG",
  SAU: "SA",
  ARE: "AE",
  KWT: "KW",
  QAT: "QA",
  OMN: "OM",
  IND: "IN",
  RUS: "RU",
  EGY: "EG",
  GBR: "GB",
  BHR: "BH",
  ARG: "AR",
  URY: "UY",
  BOL: "BO",
  PRY: "PY",
  AUS: "AU",
  POL: "PL",
  NZL: "NZ",
  CHE: "CH",
  CAN: "CA",
  USA: "US",
  JPN: "JP",
};

const formatCurrency = {
  BRL: "R$",
  MXN: "Mex$",
  RUB: "руб",
  USD: "US$",
  SGD: "SG$",
  CNY: "¥",
  EUR: "€",
  JPY: "JP¥",
  TRY: "₺",
};

////////////////////////////////////////////////////

const useMethodConfig = (props) => {
  const { t } = useTranslation();
  const tradeInfo = useSelector((state) => state.tradeInfo);
  const region = tradeInfo?.app_info?.region;
  const isOnlyCreditCard = tradeInfo?.channel_info_list?.some?.((item) => {
    return item.method === "CreditCard" && item.channel === "Localpayment";
  });
  const methodConfig = {
    Wallet: {
      info: {
        imageMobile: Wallet,
        image: Wallet,
        text: t("wallet"),
      },
    },
    RecurringWallet: {
      info: {
        imageMobile: Wallet,
        image: Wallet,
        text: "Recurring Wallets",
      },
    },
    // Brazil
    Boleto: {
      info: {
        imageMobile: Boleto,
        image: Boleto,
        text: "Boleto",
      },
    },
    BoletoRapido: {
      info: {
        imageMobile: BoletoRapidao,
        image: BoletoRapidao,
        text: "Boleto Rápido",
      },
    },
    DepositExpress: {
      info: {
        imageMobile: DepositExpress,
        image: DepositExpress,
        text: "Depósito Bancário",
      },
    },
    Lottery: {
      info: {
        imageMobile: Lottery,
        image: Lottery,
        text: "Pagamento na Lotérica",
      },
    },
    PIX: {
      info: {
        imageMobile: Pix,
        image: Pix,
        text: "PIX",
      },
      notice: t("pix_notice"),
    },
    // Mexico
    // Paynet: {
    //   info: {
    //     imageMobile: Paynet,
    //     image: Paynet,
    //     text: "Paynet",
    //   },
    // },
    OXXO: {
      info: {
        imageMobile: Oxxo,
        image: Oxxo,
        text: t("oxxo"),
      },
    },
    SPEI: {
      info: {
        imageMobile: Spei,
        image: Spei,
        text: "SPEI",
      },
    },
    CreditCard: {
      info: {
        imageMobile: CreditCard,
        image: CreditCard,
        text: isOnlyCreditCard ? t("credit_card") : t("credit_debit_card"),
      },
    },
    DebitCard: {
      info: {
        imageMobile: CreditCard,
        image: CreditCard,
        text: t("debit_card"),
      },
    },
    RecurringCreditCard: {
      info: {
        imageMobile: CreditCard,
        image: CreditCard,
        text: t("credit_debit_card"),
      },
    },
    Cash: {
      info: {
        imageMobile: CashPayments,
        image: CashPayments,
        text: t("cash_payments"),
      },
    },
    BankTransfer: {
      info: {
        imageMobile: OnlineBanking,
        image: OnlineBanking,
        text: t("bank_transfer"),
      },
    },
    PagoEfectivo: {
      info: {
        imageMobile: PagoEfectivo,
        image: PagoEfectivo,
        text: t("pago_method"),
      },
    },
    PSE: {
      info: {
        imageMobile: PseM,
        image: PSE,
        text: "PSE",
      },
    },
    Efecty: {
      info: {
        imageMobile: EfectyM,
        image: Efecty,
        text: "Efecty",
      },
      faq: [
        {
          q: t("efecty_faq1q"),
          a: t("efecty_faq1a"),
        },
        {
          q: t("efecty_faq2q"),
          a: t("efecty_faq2a"),
        },
        {
          q: t("efecty_faq3q"),
          a: t("efecty_faq3a"),
        },
      ],
      video: [
        // {
        //   title: "Como pagar com banco online",
        //   source: "https://www.youtube.com/embed/74a9n3y2ClU",
        // },
      ],
    },
    Khipu: {
      info: {
        imageMobile: KhipuM,
        image: Khipu,
        text: "Khipu",
      },
      faq: [
        {
          q: t("khipu_faq1q"),
          a: t("khipu_faq1a"),
        },
        {
          q: t("khipu_faq2q"),
          a: t("khipu_faq2a"),
        },
        {
          q: t("khipu_faq3q"),
          a: t("khipu_faq3a"),
        },
      ],
    },
    GCash: {
      info: {
        imageMobile: GcashLogo,
        image: GcashLogo,
        text: "GCash",
      },
    },
    KLAP: {
      info: {
        imageMobile: Klap,
        image: Klap,
        text: "Efectivo Klap",
      },
    },
    Pago46: {
      info: {
        imageMobile: Pago46,
        image: Pago46,
        text: "Pago46",
      },
    },
    DirectDebit: {
      info: {
        imageMobile: DebitPh,
        image: DebitPh,
        text: "Direct Debit",
      },
    },
    OTC: {
      // Seven11M for xendit
      info: {
        imageMobile: RetailStore,
        image: RetailStore,
        text: t("retail_outlet"),
      },
    },
    PiPay: {
      info: {
        imageMobile: Pipay,
        image: Pipay,
        text: "Pi Pay",
      },
    },
    PayGo: {
      info: {
        imageMobile: Paygo,
        image: Paygo,
        text: "PayGo",
      },
    },
    UPI: {
      info: {
        imageMobile: UPI,
        image: UPI,
        text: "UPI",
      },
    },
    NetBanking: {
      info: {
        imageMobile: Netbank,
        image: Netbank,
        text: "Net Banking",
      },
    },
    AlfaClick: {
      info: {
        imageMobile: AlfaClick,
        image: AlfaClick,
        text: "Alfa Click",
      },
    },
    Qiwi: {
      info: {
        imageMobile: Qiwi,
        image: Qiwi,
        text: "Qiwi",
      },
    },
    WebMoney: {
      info: {
        imageMobile: WebMoney,
        image: WebMoney,
        text: "Web Money",
      },
    },
    YandexMoney: {
      info: {
        imageMobile: YandexMoney,
        image: YandexMoney,
        text: "Yandex Money",
      },
    },
    Baloto: {
      info: {
        imageMobile: Baloto,
        image: Baloto,
        text: "Baloto",
      },
      faq: [
        {
          q: t("baloto_faq1q"),
          a: t("baloto_faq1a"),
        },
      ],
    },
    SuRed: {
      info: {
        imageMobile: Sured,
        image: Sured,
        text: "Su Red",
      },
      faq: [
        {
          q: t("sured_faq1q"),
          a: t("sured_faq1a"),
        },
      ],
    },
    GANA: {
      info: {
        imageMobile: Gana,
        image: Gana,
        text: "Gana",
      },
      faq: [
        {
          q: t("gana_faq1q"),
          a: t("gana_faq1a"),
        },
      ],
    },
    OXXOPay: {
      info: {
        imageMobile: OxxoPay,
        image: OxxoPay,
        text: "OXXO PAY",
      },
    },
    UnionPay: {
      info: {
        imageMobile: UnionPay,
        image: UnionPay,
        text: "Union Pay",
      },
    },
    CoDi: {
      info: {
        imageMobile: CodiM,
        image: Codi,
        text: "CoDi",
      },
    },
    AlipayPlus: {
      info: {
        imageMobile: AlipayPlusLogo,
        image: AlipayPlusLogo,
        text: "Alipay+",
      },
    },
    VirtualAccount: {
      info: {
        imageMobile: VirtualAccount,
        image: VirtualAccount,
        text: t("virtual_account"),
      },
    },
    PlayersBankPIX: {
      info: {
        imageMobile: PlayersBank,
        image: PlayersBank,
        text: t("pb_qr"),
      },
    },
    PlayersBankCard: {
      info: {
        imageMobile: PlayersBank,
        image: PlayersBank,
        text: t("pb_card"),
      },
    },
    PagoFacil: {
      info: {
        imageMobile: PagoFacil,
        image: PagoFacil,
        text: "Pago Facil",
      },
    },
    Rapipago: {
      info: {
        imageMobile: RapiPago,
        image: RapiPago,
        text: "Rapi Pago",
      },
    },
    Sofort: {
      info: {
        imageMobile: Sofort,
        image: Sofort,
        text: "Sofort",
      },
    },
    Neosurf: {
      info: {
        imageMobile: Neosurf,
        image: Neosurf,
        text: "Neosurf",
      },
    },
    Bancontact: {
      info: {
        imageMobile: Bancontact,
        image: Bancontact,
        text: "Bancontact",
      },
    },
    Giropay: {
      info: {
        imageMobile: Giropay,
        image: Giropay,
        text: "Giro Pay",
      },
      notice: t("trustly_notice"),
    },
    iDeal: {
      info: {
        imageMobile: Ideal,
        image: Ideal,
        text: "iDeal",
      },
    },
    MyBank: {
      info: {
        imageMobile: MyBank,
        image: MyBank,
        text: "MyBank",
      },
    },
    P24: {
      info: {
        imageMobile: Przelewy,
        image: Przelewy,
        text: "Przelewy24",
      },
    },
    Trustly: {
      info: {
        imageMobile: Trustly,
        image: Trustly,
        text: "Trustly",
      },
      notice: t("trustly_notice"),
    },
    EPS: {
      info: {
        imageMobile: EPS,
        image: EPS,
        text: "EPS",
      },
    },
    SberBank: {
      info: {
        imageMobile: SberPay,
        image: SberPay,
        text: "SberPay",
      },
    },
    Transfiya: {
      info: {
        imageMobile: Transfiya,
        image: Transfiya,
        text: "Transfiya",
      },
    },
    Bancolombia: {
      info: {
        imageMobile: Bancolombia,
        image: Bancolombia,
        text: "Bancolombia",
      },
    },
    BCR: {
      info: {
        imageMobile: BCR,
        image: BCR,
        text: "Banco de Costa Rica",
      },
    },
    BNCR: {
      info: {
        imageMobile: BNCR,
        image: BNCR,
        text: "Banco Nacional de Costa Rica",
      },
    },
    Servipag: {
      info: {
        imageMobile: Servipag,
        image: ServipagPc,
        text: "Servipag",
      },
    },
    WebPay: {
      info: {
        imageMobile: Webpay,
        image: Webpay,
        text: "Webpay",
      },
    },
    Crypto: {
      info: {
        imageMobile: Crypto,
        image: Crypto,
        text: t("crypto"),
      },
    },
    ToditoCash: {
      info: {
        imageMobile: ToditoCashLogo,
        image: ToditoCashLogo,
        text: "Todito Cash",
      },
    },
    OpenFinance: {
      info: {
        imageMobile: OpenFinance,
        image: OpenFinance,
        text: "Pagar com",
      },
    },
    Paysafecard: {
      info: {
        imageMobile: Paysafecard,
        image: Paysafecard,
        text: "Paysafecard",
      },
    },
    Payconiq: {
      info: {
        imageMobile: Payconiq,
        image: Payconiq,
        text: "Payconiq",
      },
    },
    Multibanco: {
      info: {
        imageMobile: Multibanco,
        image: Multibanco,
        text: "Multibanco",
      },
    },
    QRPH: {
      info: {
        imageMobile: QrPh,
        image: QrPh,
        text: "QRPh",
      },
    },
    SBP: {
      info: {
        imageMobile: SBP,
        image: SBP,
        text: t("sbp"),
      },
    },
    BLIK: {
      info: {
        imageMobile: Blik,
        image: Blik,
        text: "BLIK",
      },
    },
    Kiosk: {
      info: {
        imageMobile: CashPayments,
        image: CashPayments,
        text: t("kiosk"),
      },
    },
  };

  return methodConfig;
};

export {
  useMethodConfig,
  channelConfig,
  countryCode3to2,
  getWalletChannelLogo,
  depositExpressChannels,
  directDebitChannels,
  latinCountries,
  formatCurrency,
  checkoutConfig,
  countryCode2TaxIdType,
  cryptoChannels,
  happyPayChannels,
};
