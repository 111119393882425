import React from "react";
import "./CheckoutTitle.scss";
import { useTranslation, Trans } from "react-i18next";
import { FormatMoney } from "../../../utils/formats";
import { connect } from "react-redux";

function CheckoutTitle(props) {
  const { t } = useTranslation();
  const Amount = () => (
    <span className="checkoutTitle__amount">
      <FormatMoney type="total" />
    </span>
  );
  return (
    <>
      <p className="checkoutTitle">
        <Trans
          i18nKey="checkout_inst1.0"
          components={[props.tradeInfo?.user?.name ? props.tradeInfo?.user?.name : t("checkout_inst1.2"), <Amount />]}
        />
      </p>
    </>
  );
}

const mapStateToProps = (state, ownprops) => {
  return {
    tradeInfo: state.tradeInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutTitle);
