import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import Correct from "../../img/correct.jpg";
import { checkEmail } from "../../utils/validations";
import { useSelector } from "react-redux";
import InfoPromp from "../comp/InfoPromp";

function Email({ form, setForm, mobile, setFocusOnInput = () => {}, vita, size = "small" }) {
  const { t } = useTranslation();
  const [tradeInfo, method, channel] = useSelector((state) => [state.tradeInfo, state.method, state.payChannel]);
  useEffect(() => {
    if (!form.email.checked) {
      let email = tradeInfo?.user?.email ? tradeInfo?.user?.email : localStorage?.getItem("__frm_email");

      if (checkEmail({ data: email })) {
        setForm((prev) => {
          return {
            ...prev,
            email: { ...prev.email, value: email, checked: true, valid: true },
          };
        });
      }
    }
  }, []);

  useEffect(() => {
    if (form.email.checked) {
      checkEmail({ form, setForm });
    }
  }, [channel, method]);

  return (
    <>
      <TextField
        id="inputform-email"
        onFocus={() => {
          setFocusOnInput(true);
        }}
        onClick={(event) => {
          event.stopPropagation();
        }}
        size={size}
        inputProps={{ inputMode: "email", autocomplete: "new-password", "ga-data": "339" }}
        error={!form.email.valid}
        helperText={!form.email.valid && t("invalid_email")}
        className="homeMobile__filling__content__input"
        label={vita ? "Registered Email" : t("email")}
        variant="outlined"
        value={form.email.value}
        onChange={(event) => {
          let value = event.target.value;
          if (value?.length > form.email.max) {
            value = value.slice(0, form.email.max);
          }
          setForm((prev) => {
            return {
              ...prev,
              email: {
                ...prev.email,
                value: value.trim(),
              },
            };
          });
        }}
        onBlur={() => {
          checkEmail({ form: form, setForm: setForm });
        }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <img
                src={Correct}
                alt=""
                style={{
                  display: form.email.valid && form.email.checked ? "block" : "none",
                }}
              />
              {channel === "PPRO" && <InfoPromp promp={<p>Underscore ( _ ) is not allowed in email.</p>} />}
              {method === "Boleto" && <InfoPromp promp={<p>Plus sign ( + ) is not allowed in email.</p>} />}
            </InputAdornment>
          ),
        }}
      />
    </>
  );
}

export default Email;
