import React from "react";
import "./CheckoutKiosk.scss";
import CheckoutTitle from "./CheckoutTitle";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FormatMoney } from "../../../utils/formats";
import CopyButton from "../../comp/CopyButton";
import Aman from "../../../img/Kiosk/AMAN.png";
import Masary from "../../../img/Kiosk/Masary.png";
import Momken from "../../../img/Kiosk/Momken.png";
import Sadad from "../../../img/Kiosk/Sadad.png";

function CheckoutKiosk() {
  const { t } = useTranslation();
  const { payData } = useSelector((state) => state);
  return (
    <div className="checkoutKiosk">
      <CheckoutTitle />
      <table>
        <tr>
          <th>
            <div className="row">
              <span>{t("checkout.kiosk.service")}</span>
              <span>Kiosk</span>
            </div>
          </th>
        </tr>
        <tr>
          <th>
            <div className="row">
              <span>{t("checkout.kiosk.code")}</span>
              <div style={{ display: "flex", alignItems: "center" }}>
                <CopyButton text={payData.reference} onlyIcon height={15} minWidth={50} />
                <span>{payData.reference}</span>
              </div>
            </div>
          </th>
        </tr>
        <tr>
          <th>
            <div className="row">
              <span>{t("checkout.kiosk.due_date")}</span>
              <span>{payData.due_date}</span>
            </div>
          </th>
        </tr>
        <tr>
          <th>
            <div className="row">
              <span>{t("checkout.kiosk.amount")}</span>
              <FormatMoney type="total" />
            </div>
          </th>
        </tr>
      </table>
      <h4 className="title">{t("checkout.kiosk.title.1")}</h4>
      <div className="payment-points">
        <div className="point">
          <img src={Aman} alt="" />
          <span>Aman</span>
        </div>
        <div className="point">
          <img src={Masary} alt="" />
          <span>Masary</span>
        </div>
        <div className="point">
          <img src={Momken} alt="" />
          <span>Momken</span>
        </div>
        <div className="point">
          <img src={Sadad} alt="" />
          <span>Sadad</span>
        </div>
      </div>
      <h4 className="title">{t("checkout.kiosk.title.2")}</h4>
      <div className="how-to">
        <ol>
          <li>{t("checkout.kiosk.how_to.1", { reference: payData.reference })}</li>
          <li>{t("checkout.kiosk.how_to.2")}</li>
        </ol>
      </div>
    </div>
  );
}

export default CheckoutKiosk;
